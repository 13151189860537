import Slider from 'components/core/slider';
import Button from 'components/core/button';
import Note from 'components/core/note';
import React, { useState } from 'react';

function TypeRange({ currentQuestion, onSubmit }) {
	let defaultRangeValue = 0;
	if (currentQuestion && currentQuestion.data) {
		defaultRangeValue = currentQuestion.data.default !== null ? currentQuestion.data.default : currentQuestion.data.min;
	}
	const [rangeValue, setRangeValue] = useState(currentQuestion.answerId ? currentQuestion.answerId : defaultRangeValue);
	
	return (
		<div className="slider-container">
			{ currentQuestion.data.text &&
				<Note
					text={currentQuestion.data.text}
				/>
			}
			<Slider
				min={currentQuestion.data.min}
				max={currentQuestion.data.max}
				defaultValue={currentQuestion.answerId ? parseFloat(currentQuestion.answerId) : defaultRangeValue}
				unit={currentQuestion.data.unit}
				onChange={(value) => setRangeValue(value)}
				step={currentQuestion.data.step}
				class="item"
				usLogic={currentQuestion.identifier === 'DiscountMargin'}
				conversionFactor={currentQuestion.data.conversionFactorFeet ? currentQuestion.data.conversionFactorFeet : ''}
			/>
			<div className="submit-button">
				<Button
					text="Configuration_SaveChanges"
					class="btn-secondary"
					onClick={() => onSubmit(rangeValue)}
				/>
			</div>
		</div>
	);
}

TypeRange.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeRange;