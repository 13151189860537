import { default as ReactSelect, components } from 'react-select';
import SimpleBar from 'simplebar-react';
import { Field, ErrorMessage } from 'formik';
import RadioButton from 'components/forms/radio-button';
import './index.scss';

const renderScroll = props => {
	return (
		<SimpleBar style={{ maxHeight: 193, width: '100%' }}>{props.children}</SimpleBar>
	);
};

const Option = props => {
	return (
		<div>
			<components.Option {...props}>
				<RadioButton
					checked={props.isSelected}
					onChange={() => null}
					useInForm={false}
					headline={props.data.label}
					translate={false}
				/>
			</components.Option>
		</div>
	);
};

const ValueContainer = ({ children, ...props }) => {
	return (
		<components.ValueContainer {...props}>
			<div className="placeholder-container">{props.selectProps.placeholderText}</div>
			{ children }
		</components.ValueContainer>
	);
};

const SingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>{children}</components.SingleValue>
);

const NoOptionsMessage = props => {
	return (
		<components.NoOptionsMessage {...props}>
			<div className="no-options-text">{ props.selectProps.noOptionsText }</div>
		</components.NoOptionsMessage>
	);
};

function Select(props) {
	return (
		<Field name={props.name}>
			{({
				form,
				field,
				meta,
			}) => (
				<div className={'select' + (props.class ? ' ' + props.class : '') + ( meta.error ? ' error' : '') } title={props.hoverTitle}>
					<ReactSelect
						name={field.name}
						components={{
							ValueContainer,
							Option,
							SingleValue,
							MenuList: renderScroll,
							NoOptionsMessage,
						}}
						placeholder=""
						placeholderText={props.placeholder}
						noOptionsText={props.noOptionsText}
						options={props.options}
						className="react-select-container"
						classNamePrefix="react-select"
						isClearable={false}
						isSearchable={props.searchable}
						defaultValue={props.defaultValue}
						value={props.options && field.value ? props.options.find((option) => option.value === field.value.value) : ''}
						onChange={(selected, event) => {
							form.setFieldValue(field.name, selected);
							props.onValueChange(selected);
						}}
						onBlur={field.onBlur}
					/>
					{meta.touched && meta.error && (
						<div className="error-text"><ErrorMessage name={props.name} /></div>
					)}
				</div>
			)}
		</Field>
	);
}

Select.defaultProps = {
	name: '',
	options: {},
	placeholder: '',
	defaultValue: '',
	noOptionsText: '',
	hoverTitle: '',
	searchable: false,
	onValueChange: () => void(0),
};

export default Select;
