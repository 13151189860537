import { createContext, useContext, useState } from 'react';
import { defaultConfig } from './config';

export const ConfigLoadingStatus = {
	Unknown: 0,
	Loading: 1,
	Loaded: 2,
	Error: 3,
};

const configContext = createContext({
	config: defaultConfig,
	setConfig: () => {},
	loadingState: ConfigLoadingStatus.Unknown,
	setLoadingState: () => {},
});

export function ConfigProvider({ children }) {
	const providerValue = useConfigProvider();
	return <configContext.Provider value={providerValue}>{children}</configContext.Provider>;
}

export const useConfig = () => {
	return useContext(configContext);
};

function useConfigProvider() {
	const [configState, setConfigState] = useState(defaultConfig);
	const [loadingState, setLoadingState] = useState(ConfigLoadingStatus.Unknown);

	return {
		config: configState,
		setConfig: setConfigState,
		loadingState: loadingState,
		setLoadingState: setLoadingState,
	};
}