import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import './index.scss';

function Input(props) {
	const [focused, setFocused] = useState(false);
	return (
		<Field name={props.name}>
			{({ field, form, meta }) => (
				<div className={'field type-' + props.type + (focused || field.value !== '' ? ' has-value' : '') + ( meta.error ? ' error' : '') + `${props.class ? ' ' + props.class : ''}`}>
					<label htmlFor={props.name}><TranslationTag tag={props.text} /></label>
					<RenderInput {...props} setFocused={setFocused} field={field} form={form} />
					{ meta.error &&
						<div>
							<div className="error-icon">
								<Icon
									icon="alert"
									width="24"
									height="20"
									color="#FF451C"
								/>
							</div>
							<div className="error-text"><ErrorMessage name={props.name} /></div>
						</div>
					}
				</div>
			)}
		</Field>
	);
}

const RenderInput = ({ type, ...rest }) => {
	if (type === 'file') {
		return <FileInput
			type={type}
			{...rest}
		/>;
	}

	return <DefaultInput
		type={type}
		{...rest}
	/>;
};

const DefaultInput = ({ type, value, setFocused, field, form, submitOnChange }) => {
	return (
		<input
			type={type}
			value={value}
			{...field}
			onFocus={(e) => setFocused(true)}
			onBlur={(e) => {
				setFocused(false);
				field.onBlur(e);
			}}
			onChange={(e) => {
				if (submitOnChange) {
					form.submitForm();
				}
				field.onChange(e);
			}}
		/>
	);
};

const FileInput = ({ type, name, accepted, multiple, form, submitOnChange }) => {
	return (
		<input
			type={type}
			name={name}
			accepted={accepted}
			multiple={multiple}
			onChange={(e) => {
				form.setFieldValue(name, e.target.files[0]);
				if (submitOnChange) {
					form.submitForm();
				}
			}}
		/>
	);
};

Input.defaultProps = {
	name: '',
	text: '',
	class: '',
	value: '',
	type: 'text',
	submitOnChange: false,
};

export default Input;
