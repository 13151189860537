import Card from 'components/core/card';
import Icon from 'components/core/icon';
import Link from 'components/core/link';
import Tabs from 'components/navigation/tabs';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import parse from 'html-react-parser';
import { createRef, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationTag from 'utils/translations';
import './style.scss';
import TypeCheckbox from './types/checkbox';
import TypeColors from './types/colors';
import TypeDefaults from './types/defaults';
import TypeInfoText from './types/infotext';
import TypeInput from './types/input';
import TypeRange from './types/range';
import TypeStandAlone from './types/stand-alone';
import TypeTable from './types/table';
import TypeTags from './types/tags';
import TypeTextarea from './types/textarea';

function Question({ currentQuestion, active = true, editable = true }) {
	const { answer, state } = useConfiguratorApi();
	const { t } = useTranslation('translations');
	
	if (!currentQuestion) {
		return null;
	}
	
	if (currentQuestion.type === 'subElements') {
		return (
			<SubElements
				currentQuestion={currentQuestion}
				active={active}
				editable={editable}
				key={currentQuestion.id}
			/>
		);
	}
	
	if (!active) {
		return <InactiveQuestion key={currentQuestion.id} question={currentQuestion} editable={editable} />;
	}
	
	const showTooltip = currentQuestion.type !== 'InfoText' && currentQuestion.data.tooltip;
	
	return (
		<div className="question">
			{ (!['project', 'summary'].includes(state.currentPage) && currentQuestion.type !== 'InfoText')  &&
				<div className="intro">
					<div className="headline t2">{ currentQuestion.data.headline !== '' ? currentQuestion.data.headline : currentQuestion.data.text }</div>
					{ showTooltip &&
						<div className="tooltip-container">
							<div className="info-icon">
								<Icon
									icon="info"
									width="4"
									height="12"
									color="#FFFFFF"
								/>
							</div>
							<div className="line"></div>
							<div className="info-content">
								<TranslationTag tag={currentQuestion.data.tooltip} />
							</div>
						</div>
					}
				</div>
			}
			{(currentQuestion.data.headline !== '' && currentQuestion.data.text !== '' && currentQuestion.type === 'ImageRadio') &&
				<div className="info-text-container">
					{parse(currentQuestion.data.text)}
				</div>
			}
			<div className={'items' + (currentQuestion.type === 'ImageRadio' ? ' imageRadios' : '')}>
				{ currentQuestion.type === 'RadioDeviceColor' ? (
					<TypeColors
						currentQuestion={currentQuestion}
						onSubmit={(value, text, colorCode, colorImage) => {
							if (colorCode) {
								answer(currentQuestion.identifier, value, text, colorCode, '');
							} else if (colorImage) {
								answer(currentQuestion.identifier, value, text, '', colorImage);
							} else {
								answer(currentQuestion.identifier, value, text);
							}
						}}
					/>
				) : (
					<Fragment>
						{ (currentQuestion.data.answers && currentQuestion.type !== 'ImageRadioDefault') &&
							currentQuestion.data.answers.map((answerObject) => (
								<Answer key={answerObject.id} answerObject={answerObject} currentQuestion={currentQuestion} />
							))
						}
					</Fragment>
				)}
				{ currentQuestion.type === 'Range' &&
					<TypeRange
						currentQuestion={currentQuestion}
						onSubmit={(rangeValue) => answer(currentQuestion.identifier, rangeValue, rangeValue + ' ' + currentQuestion.data.unit)}
					/>
				}
				{ currentQuestion.type === 'Table' &&
					<TypeTable
						currentQuestion={currentQuestion}
						onSubmit={(values) => {
							const value = values.isCustomValue ? values.customValue : values.tableRadio;
							return answer(currentQuestion.identifier, value, value + currentQuestion.data.unit);
						}}
					/>
				}
				{ currentQuestion.type === 'ImageRadioDefault' &&
					<TypeDefaults
						currentQuestion={currentQuestion}
					/>
				}
				{ currentQuestion.type === 'InfoText' &&
					<TypeInfoText
						currentQuestion={currentQuestion}
						onSubmit={() => answer(currentQuestion.identifier, '', '')}
					/>
				}
				{ (currentQuestion.type === 'Input' || currentQuestion.type === 'ImageInput') &&
					<TypeInput
						currentQuestion={currentQuestion}
						onSubmit={(values) => answer(currentQuestion.identifier, values.number, values.number)}
					/>
				}
				{ currentQuestion.type === 'Textarea' &&
					<TypeTextarea
						currentQuestion={currentQuestion}
						onSubmit={(values) => answer(currentQuestion.identifier, values.comment, values.comment)}
					/>
				}
				{ currentQuestion.type === 'Tags' &&
					<TypeTags
						currentQuestion={currentQuestion}
						onSubmit={(values) => answer(currentQuestion.identifier, values, values)}
					/>
				}
				{ currentQuestion.type === 'CheckBox' &&
					<TypeCheckbox
						currentQuestion={currentQuestion}
						onSubmit={(values) => answer(currentQuestion.identifier, values.requirements, values.requirements)}
					/>
				}
				{ currentQuestion.type === 'StandAlone' &&
					<TypeStandAlone
						currentQuestion={currentQuestion}
						onSubmit={(values) => answer(currentQuestion.identifier, { 'Start': values.start.value, 'End': values.end.value, 'Remaining': values.remaining }, values.remaining + '% | ' + values.start.value + ' - ' + values.end.value + ' ' + t('Configuration_Time'))}
					/>
				}
			</div>
			
		</div>
	);
}

function SubElements ({ currentQuestion, active }) {
	const { state, changeCurrentQuestion } = useConfiguratorApi();
	const scrollContainerRef = createRef();

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			const activeElement = scrollContainer.querySelector('.button-wrapper.active');
			if (activeElement) {
				scrollContainer.scrollLeft = activeElement.offsetLeft - (scrollContainer.offsetWidth - activeElement.offsetWidth) / 2;
			}
		}
	}, [currentQuestion.subElements, scrollContainerRef]);

	const goToSubElement = (subElement) => {
		if (subElement.active || !subElement.questions || !subElement.questions.length) {
			return;
		}

		const question = subElement.questions[0];

		if (question.type === 'subElements') {
			goToSubElement(question.subElements.find((sub) => sub.questions.length));
			return;
		}
		changeCurrentQuestion(question.identifier);
	};

	if (currentQuestion.flattenedQuestions.length) {
		return (
			<>
				{
					currentQuestion.flattenedQuestions.map((question) => (
						<Question
							key={question.identifier}
							currentQuestion={question}
							active={question.active || question.identifier === state.currentQuestionId}
						/>
					))
				}
			</>
		);
	}

	const lastSubElementIndex = currentQuestion.subElements.reduce((lastIndex, subElement, index) => subElement.questions.length ? index : lastIndex, 0);

	return (
		<div className="question with-tabs">
			<Tabs class="v2">
				<div className="inner">
					<div ref={scrollContainerRef} className="scroll">
						<div className="buttons-wrapper">
							<div className="buttons t2">
								{
									currentQuestion.subElements.map((subElement, i) => (
										<div
											className={'button-wrapper' + (subElement.active || (!active && i === lastSubElementIndex) ? ' active' : '') + (subElement.questions.length === 0 ? ' disabled' : '')}
											key={subElement.elementPos}
										>
											<Link
												class={subElement.active || (!active && i === lastSubElementIndex) ? 'active' : ''}
												text={subElement.headline}
												onClick={() => goToSubElement(subElement)}
											/>
										</div>
									))
								}
							</div>
						</div>
					</div>
				</div>
				{
					active && currentQuestion.subElements.map((subElement) => {
						if (!subElement.active) {
							return null;
						}

						return <SubElement key={subElement.elementPos} subElement={subElement} />;
					})
				}
				{
					!active && <SubElement key={currentQuestion.id} subElement={currentQuestion.subElements[lastSubElementIndex]} />
				}
			</Tabs>
		</div>
	);
}

const SubElement = ({ subElement }) => {
	const { state } = useConfiguratorApi();

	if (subElement.flattenedQuestions.length) {
		return (
			<div className="content" key={subElement.elementPos}>
				{
					subElement.flattenedQuestions.map((question) => (
						<Question
							key={question.identifier}
							currentQuestion={question}
							active={question.active || question.identifier === state.currentQuestionId}
						/>
					))
				}
			</div>
		);
	}
	return (
		<div className="content" key={subElement.elementPos}>
			{
				subElement.questions.map((question) => (
					<Question
						key={question.identifier}
						currentQuestion={question}
						active={question.active || question.identifier === state.currentQuestionId}
					/>
				))
			}
		</div>
	);
};

function Answer ({ currentQuestion, answerObject }) {
	const { answer } = useConfiguratorApi();
	switch(currentQuestion.type) {
		case 'Radio':
			return <Card
				class={'item' + (currentQuestion.answerId === answerObject.id ? ' active' : '')}
				key={answerObject.id}
				version={1}
				headline={answerObject.text}
				text={answerObject.infoText}
				tooltipText={answerObject.tooltip}
				onClick={() => answer(currentQuestion.identifier, answerObject.id, answerObject.text)}
			/>;
		case 'ImageRadio':
			return <Card
				class={'item' + (currentQuestion.answerId === answerObject.id ? ' active' : '')}
				key={answerObject.id}
				version={2}
				headline={answerObject.text}
				text={answerObject.infoText}
				tooltipText={answerObject.tooltip}
				image={answerObject.image}
				onClick={() => answer(currentQuestion.identifier, answerObject.id, answerObject.text)}
			/>;
		case 'RadioSmall':
			return <Card
				class={'item' + (currentQuestion.answerId === answerObject.id ? ' active' : '')}
				key={answerObject.id}
				version={3}
				headline={answerObject.text}
				text={answerObject.infoText}
				tooltipText={answerObject.tooltip}
				onClick={() => answer(currentQuestion.identifier, answerObject.id, answerObject.text)}
			/>;
		default:
			return <div key={answerObject.id}>Other Type { currentQuestion.type }</div>;
	}
}

function InactiveQuestion ({ question, editable }) {
	const { changeCurrentQuestion } = useConfiguratorApi();
	return (
		<div className="question inactive d-flex">
			<div className="intro">
				<div className="headline t2 d-flex">
					{question.data.headlineShort}{ question.type === 'InfoText' || question.type === 'CheckBox' || (!question.answerText && question.answerText !== 0) ? '' : ': '}{((question.answerText || question.answerText === 0)  && question.type !== 'CheckBox' && !question.data.conversionFactorFeet) ? parse(String(question.answerText).replace('||', ',')) : ''}
					{ question.data.conversionFactorFeet &&
						(question.answerId * question.data.conversionFactorFeet).toFixed(2).toLocaleString() + ' ' + question.data.unit
					}
					{ question.answerColorCode &&
						<span className="color" style={{ backgroundColor: question.answerColorCode }}></span>
					}
					{ question.answerColorImage &&
						<span className="color" style={{ backgroundImage: `url('${question.answerColorImage}')` }}></span>
					}
				</div>
				{ editable &&
					<div className="edit">
						<a onClick={() => changeCurrentQuestion(question.identifier)} href="#edit">
							<TranslationTag tag="Configuration_Edit" />
						</a>
					</div>
				}
			</div>
		</div>
	);
}

export default Question;