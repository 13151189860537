import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Separator from 'components/forms/separator';
import RadioButton from 'components/forms/radio-button';
import { Routes } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'configuration/useConfig';
import { useState } from 'react';
import SpinnerLoader from 'components/system/spinner-loader';
import './style.scss';

function Ipea() {
	const { state, download } = useConfiguratorApi();
	const [isDownloading, setIsDownloading] = useState(false);
	const history = useHistory();
	const { config } = useConfig();
	
	const isArchive = (history.location.state && history.location.state.project);
	const projectId = isArchive ? history.location.state.project.projectId : state.projectId;
	
	const handleSubmit = async (values) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = 'IPEA';
		formatArray['type'] = [values.ipea];
		fileFormats.push(formatArray);
		
		if (fileFormats) {
			setIsDownloading(true);
			const response = await download(fileFormats, '', true, projectId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
		if (isArchive) {
			history.push(Routes.profile.archive.index);
		} else {
			history.push(Routes.configuration.summary);
		}
	};
	
	if (!projectId) {
		if (isArchive) {
			history.push(Routes.profile.archive.index);
		} else {
			history.push(Routes.configuration.index);
		}
	}
	
	return (
		<div className="customer-pdf">
			<Overlay
				headline="IPEA_Headline"
				text="IPEA_Text"
				backLink={isArchive ? Routes.profile.archive.index : Routes.configuration.summary}
			>
				<SpinnerLoader
					show={isDownloading}
				/>
				<Form
					initialValues={{
						ipea: '0',
					}}
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<Separator
						text="IPEA_TabHeadline"
					/>
					<RadioButton
						name="ipea"
						headline="IPEA_Option1"
						value="0"
						id="0"
					/>
					<RadioButton
						name="ipea"
						headline="IPEA_Option2"
						value="1"
						id="1"
					/>
					<RadioButton
						name="ipea"
						headline="IPEA_Option3"
						value="2"
						id="2"
					/>
					<RadioButton
						name="ipea"
						headline="IPEA_Option4"
						value="3"
						id="3"
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="IPEA_Download"
					/>
					<Button
						class="btn-secondary"
						text="IPEA_Abort"
						onClick={() => history.push(isArchive ? Routes.profile.archive.index : Routes.configuration.summary)}
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default Ipea;