import Icon from 'components/core/icon';
import Button from 'components/core/button';
import Checkbox from 'components/forms/checkbox';
import TranslationTag from 'utils/translations';
import React, { Fragment, useState } from 'react';
import './index.scss';

export const Downloads = ( props ) => {
	return (
		<div className="download-list">
			<div className="groups">
				{ props.children }
			</div>
			{ props.multiDownload &&
				<Button
					class={'btn-primary' + (props.formats.length > 0 ? '' : ' disabled')}
					onClick={() => props.onClick()}
					text="Documents_Download"
				/>
			}
		</div>
	);
};

Downloads.defaultProps = {
	onClick: () => void(0),
	multiDownload: false,
	formats: [],
};

export const DownloadGroup = ( props ) => {
	const [open, setOpen] = useState(props.open);
	return (
		<div className={'group' + (open ? ' open' : '')}>
			 { props.headline && 
				<div className="headline-container d-flex align-items-center">
					{ props.accordion &&
						<Fragment>
							<Checkbox
								text=""
								useInForm={false}
								value=""
								onChange={() => props.onChange(props.formats)}
							/>
							<h3 onClick={() => setOpen(!open)} className="pointer"><TranslationTag tag={props.headline} /></h3>
							<Icon
								onClick={() => setOpen(!open)}
								icon="listarrow"
								width="14"
								height="14"
							/>
						</Fragment>
					}
					{ !props.accordion &&
						<Fragment>
							<h3><TranslationTag tag={props.headline} /></h3>
						</Fragment>
					}
				</div>
			}
			<div className="download-items">
				{ props.children }
			</div>
		</div>
	);
};

DownloadGroup.defaultProps = {
	open: false,
};

export const DownloadItem = ( props ) => {
	return (
		<div className="item" onClick={() => props.onClick() }>
			<div className="icon">
				<Icon
					icon="download"
					width="28"
					height="34"
				/>
			</div>
			<div className="text">
				<h3>{ props.title }</h3>
				<div>{ props.format }</div>
				{ props.new &&
					<div className="new t4"><TranslationTag tag="Documents_New" /></div>
				}
			</div>
		</div>
	);
};

export default Downloads;
