import Overlay from 'components/core/overlay';
import Product from 'components/core/product';
import { Routes } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import './style.scss';

function EditConfigurations() {
	const history = useHistory();
	const { state, getOrderInfo, loadConfig } = useConfiguratorApi();
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	if (!state.projectId) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="edit-configuration">
			<Overlay
				headline="EditConfiguration_Headline"
				text="EditConfiguration_Text"
				backLink={Routes.configuration.summary}
			>
				<div className="edit-content">
					<div className="products">
						{ infos.configurations.length > 0 &&
							infos.configurations.map((configuration) => (
								<Product
									key={configuration.pcpSaveId}
									name={configuration.family}
									image={configuration.image}
									summary={configuration.summary}
									configurationId={configuration.pcpSaveId}
									onClick={() => loadConfig(configuration.pcpSaveId)}
									editLayout={true}
								/>
							))
						}
					</div>
				</div>
			</Overlay>
		</div>
	);
}

export default EditConfigurations;