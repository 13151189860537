import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ConfigWrapper from 'config-wrapper';
import GlobalLoader from 'components/system/global-loader';
import './styles/default.scss';
import App from './layout';
import { AppContextProvider } from 'utils/appContextProvider';
import { ConfigProvider } from 'configuration/useConfig';

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<GlobalLoader show={true} />}>
			<Router>
				<ConfigProvider>
					<ConfigWrapper>
						<AppContextProvider>
							<App />
						</AppContextProvider>
					</ConfigWrapper>
				</ConfigProvider>
			</Router>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root'),
);