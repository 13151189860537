import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router';
import { useHistory, Link, NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import BackgroundImage from 'images/login.jpg';
import Logo from 'images/logo.svg';
import Tabs from 'components/navigation/tabs';
import Button from 'components/core/button';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Password from 'components/forms/password';
import Checkbox from 'components/forms/checkbox';
import { Errors, Routes, StaticUrls } from 'utils/constants';
import TranslationTag from 'utils/translations';
import { useAuth } from 'hooks/useAuth';
import { languages } from 'i18n';
import { getLanguageFromLanguageCode, getLanguageShortNameFromLanguageEnum } from 'utils/language';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useCallback, useEffect } from 'react';
import './style.scss';

function Login() {
	const history = useHistory();
	const { t, i18n } = useTranslation('translations');
	const { user, login, setLanguage } = useAuth();
	const { reset } = useConfiguratorApi();
	const queryParams = new URLSearchParams(window.location.search);
	const doubleOptIn = queryParams.get('doubleOptIn');
	
	const startConfiguration = useCallback(() => {
		reset();
		history.push(Routes.configuration.index);
	}, [history, reset]);

	useEffect(() => {
		if (user) {
			startConfiguration();
		}
	}, [user, startConfiguration]);
	
	const validationSchema = Yup.object({
		email: Yup.string().email(t(Errors.EMail)).required(t(Errors.Required)),
		password: Yup.string().required(t(Errors.Required)),
		privacy1: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
		privacy2: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
	});
	
	const handleSubmit = async (values) => {
		const user = await login(values.email, values.password, values.rememberMe);
		if (user === null) {
			// unauthorized
			// show errors or similar
			return;
		}
		// startConfiguration(); not needed, because of useEffect earlier
	};
	
	const handleLanguageChange = async (language) => {
		setLanguage(language);
	};
	
	return (
		<div className="login">
			<div className="bg">
				<img src={BackgroundImage} alt="" />
			</div>
			<div className="languages inverse">
				{
					languages.map((language, key) => {
						const languageEnum = getLanguageFromLanguageCode(language);
						const currentLanguage = getLanguageFromLanguageCode(i18n.language);
						return (
							<div
								className={'language text-uppercase' + (languageEnum === currentLanguage ? ' active' : '')}
								onClick={() => handleLanguageChange(languageEnum)}
								key={key}
							>
								{getLanguageShortNameFromLanguageEnum(key)}
							</div>
						);
					})
				}
			</div>
			<div className="scroll-container">
				<div className="form-container">
					<div className="inner">
						<img src={Logo} alt="" />
						<div className="content-container">
							<Tabs class="v2">
								<div className="buttons t2">
									<NavLink exact to={Routes.login.index}>
										<TranslationTag tag="Login_Tab1" />
									</NavLink>
									<NavLink to={Routes.login.guest}>
										<TranslationTag tag="Login_Tab2" />
									</NavLink>
								</div>
								<div className="content">
									<Switch>
										<Route exact path={Routes.login.index}>
											<Form
												initialValues={{
													email: '',
													password: '',
													rememberMe: false,
													privacy1: false,
													privacy2: false,
												}}
												validationSchema={validationSchema}
												onSubmit={handleSubmit}
											>
												{ doubleOptIn &&
													<div className="double-opt-in-text t2">
														<TranslationTag tag="Login_DoubleOptInText" />
													</div>
												}
												<Input
													name="email"
													text="Form_EmailLong"
													type="text"
												/>
												<Password
													name="password"
													text="Form_Password"
												/>
												<div className="password-reset">
													<Link to={Routes.login.passwordReset.index}>
														<TranslationTag tag="Login_Passwordlost" />
													</Link>
												</div>
												<Checkbox
													name="rememberMe"
													id="rememberMe"
													text="Form_RememberMe"
												/>
												<Checkbox
													name="privacy1"
													id="privacy1"
													text="Form_Privacy1"
													textLink={StaticUrls('privacy1', '', i18n.language)}
												/>
												<Checkbox
													name="privacy2"
													id="privacy2"
													text="Form_Privacy2"
													textLink={StaticUrls('privacy2', '', i18n.language)}
												/>
												<Button
													class="btn-primary"
													type="submit"
													text="Login_Login"
												/>
											</Form>
											<div className="register-link t3">
												<TranslationTag tag="Login_Registration1" /> <Link to={Routes.registration.index}><TranslationTag tag="Login_Registration2" /></Link>
											</div>
										</Route>
										<Route path={Routes.login.guest}>
											<Button
												text="Login_Startconfiguration"
												class="btn-primary"
												onClick={() => startConfiguration()}
											/>
										</Route>
									</Switch>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;