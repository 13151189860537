import Note from 'components/core/note';
import Table from 'components/core/table';

function TypeTable({ currentQuestion, onSubmit }) {
	return (
		<div className="note-container">
			<Note
				text={currentQuestion.data.text}
			/>
			<Table
				headings={currentQuestion.data.tableColumns}
				rows={currentQuestion.data.tableRows}
				unit={currentQuestion.data.unit}
				min={currentQuestion.data.min}
				max ={currentQuestion.data.max}
				step={currentQuestion.data.step}
				default={currentQuestion.data.default}
				onSubmit={(values) => { onSubmit(values); }}
				answerId={currentQuestion.answerId}
				customInput={currentQuestion.data.customInput}
			/>
		</div>
	);
}

TypeTable.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeTable;