import Product from 'components/core/product';
import Overlay from 'components/core/overlay';
import Downloads, { DownloadGroup, DownloadItem } from 'components/forms/downloads';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes, FileExtensions, DownloadExtensions, DownloadTranslations } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useConfig } from 'configuration/useConfig';
import { useTranslation } from 'react-i18next';
import './style.scss';

function ProductDownloads() {
	const { state, getOrderInfo } = useConfiguratorApi();
	const history = useHistory();
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	if (!state.projectId) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="product-downloads">
			<Overlay
				headline="Configuration_DownloadsProductsHeadline"
				text="Configuration_DownloadsProductsText"
				backLink={Routes.configuration.summary}
			>
				<div className="configurations">
					{ infos.configurations.length > 0 &&
						infos.configurations.map((configuration, index) => (
							<Configuration configuration={configuration} key={index} />
						))
					}
				</div>
			</Overlay>
		</div>
	);
}

function Configuration ({ configuration }) {
	const [fileFormats, setFileFormats] = useState([]);
	const [isDownloading, setIsDownloading] = useState(false);
	const { configurationDownload } = useConfiguratorApi();
	const { config } = useConfig();
	const { t } = useTranslation('translations');
	
	const updateFileFormats = (formats) => {
		let tempDownloadFiles = [...fileFormats];
		formats.forEach((format) => {
			let foundIndex = -1;
			tempDownloadFiles.forEach((type, index) => {
				if (type.format === format.format) {
					foundIndex = index;
				}
			});
			if (foundIndex > -1) {
				tempDownloadFiles.splice(foundIndex, 1);
			} else {
				tempDownloadFiles.push(format);
			}
		});
		setFileFormats(tempDownloadFiles);
	};
	
	const handleSingleDownload = async (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = format;
		formatArray['type'] = types;
		fileFormats.push(formatArray);
		if (fileFormats) {
			setIsDownloading(true);
			const response = await configurationDownload(fileFormats, configuration.configurationId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const handleDownload = async () => {
		if (fileFormats) {
			setIsDownloading(true);
			const response = await configurationDownload(fileFormats, configuration.configurationId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const fsfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'FSF';
		if (configuration.downloadForecast.FSF.filter(element => element === 'Document').length > 0) {
			typeArray.push('Document');
		}
		if (configuration.downloadForecast.FSF.filter(element => element === 'Image').length > 0) {
			typeArray.push('Image');
		}
		if (configuration.downloadForecast.FSF.filter(element => element === 'Other').length > 0) {
			typeArray.push('Other');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);
		return fileArray;
	};
	
	const getFileArray = (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		if (configuration.downloadForecast[format]) {
			formatArray['format'] = format;
			formatArray['type'] = types;
			fileFormats.push(formatArray);
		}
		return fileFormats;
	};
	
	return (
		<div className="configuration-download">
			<Product
				name={configuration.family}
				image={configuration.image}
				summary={configuration.summary}
				downloadLayout={true}
			/>
			{ configuration.downloadForecast &&
				<Downloads
					onClick={() => handleDownload()}
					multiDownload={true}
					formats={fileFormats}
				>
					<SpinnerLoader
						show={isDownloading}
					/>
					<DownloadGroup
						headline="Documents_PhotometricData"
						accordion={true}
						open={false}
						onChange={() => updateFileFormats([...getFileArray('LDT', ['']), ...getFileArray('IES', ['']), ...getFileArray('PLOT', [''])])}
					>
						{ configuration.downloadForecast.LDT &&
							<DownloadItem
								title={t('Documents_LDT')}
								format={'(' + FileExtensions.ldt + ')'}
								onClick={() => handleSingleDownload('LDT', [''])}
							/>
						}
						{ configuration.downloadForecast.IES &&
							<DownloadItem
								title={t('Documents_IES')}
								format={'(' + FileExtensions.ies + ')'}
								onClick={() => handleSingleDownload('IES', [''])}
							/>
						}
						{ configuration.downloadForecast.PLOT &&
							<DownloadItem
								title={t('Documents_PLOT')}
								format={'(' + FileExtensions.plot + ')'}
								onClick={() => handleSingleDownload('PLOT', [''])}
							/>
						}
					</DownloadGroup>
					{ configuration.downloadForecast.FSF &&
						<DownloadGroup
							headline="Documents_Datasheets"
							accordion={true}
							onChange={() => updateFileFormats([...fsfFiles()])}
						>
							{ configuration.downloadForecast.FSF.map((item, index) => (
								configuration.downloadForecast.FSF.filter(element => element === item).length > 0 ? (
									<DownloadItem
										key={index}
										title={t(DownloadTranslations(item))}
										format={'(' + DownloadExtensions(item) + ')'}
										onClick={() => handleSingleDownload('FSF', [item])}
									/>
								)
									:
									null
							))}
						</DownloadGroup>
					}
				</Downloads>
			}
		</div>
	);
}

export default ProductDownloads;