import Button from 'components/core/button';
import Form from 'components/forms/form';
import Checkbox from 'components/forms/checkbox';
import { Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function TypeCheckbox({ currentQuestion, onSubmit }) {
	const { t } = useTranslation('translations');
	const validationSchema = Yup.object({
		requirements: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Required)),
	});
	return (
		<div className="checkbox-outercontainer">
			<Form
				initialValues={{ requirements: false }}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>
				<Checkbox
					name="requirements"
					text={currentQuestion.data.labelText}
					textBefore={currentQuestion.data.text}
					id="requirements"
				/>
				<div className="submit-button">
					<Button
						text="Configuration_SaveChanges"
						class="btn-secondary"
						type="submit"
					/>
				</div>
			</Form>
		</div>
	);
}

TypeCheckbox.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeCheckbox;