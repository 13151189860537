import Icon from 'components/core/icon';
import './index.scss';

function SpinnerLoader({ show }) {
	if (!show) {
		return null;
	}
	return (
		<div className="spinner-loader">
			<Icon
				icon="spinner"
			/>
		</div>
	);
}

export default SpinnerLoader;
