import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function RegistrationSuccess() {
	const history = useHistory();
	
	return (
		<div className="registration-success">
			<Overlay
				headline="RegistrationSuccess_Headline"
				text="RegistrationSuccess_Text"
			>
				<Button
					class="btn-primary"
					text={'RegistrationSuccess_Login'}
					onClick={() => history.push(Routes.login.index)}
				/>
			</Overlay>
		</div>
	);
}

export default RegistrationSuccess;