import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { Routes } from 'utils/constants';

const LoadingState = {
	Undefined: 0,
	Loading: 1,
	Loaded: 2,
};

const Share = () => {
	const { sharedAuthToken } = useParams();
	const { loadSharedConfig } = useConfiguratorApi();
	const [loadingState, setLoadingState] = useState(LoadingState.Undefined);
	
	useEffect(() => {
		const load = async () => {
			await loadSharedConfig(sharedAuthToken);
			setLoadingState(LoadingState.Loaded);
		};
		
		if (loadingState === LoadingState.Undefined) {
			setLoadingState(LoadingState.Loading);
			load();
		}
	}, [loadingState, loadSharedConfig, sharedAuthToken]);

	if (loadingState !== LoadingState.Loaded) {
		return null;
	}

	return <Redirect to={Routes.configuration.index} />;
};

export default Share;