import Button from 'components/core/button';
import TranslationTag from 'utils/translations';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Note from 'components/core/note';
import Select from 'components/forms/select';
import ProjectOverlay from 'components/custom/project-overlay';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';

function ProjectDetails() {
	const { user } = useAuth();
	const { goTo, state } = useConfiguratorApi();
	const { loadArchiveFolders, assignProjectToFolder } = useArchiveApi();
	const [loadingFolders, setLoadingFolders] = useState(false);
	const [folders, setFolders] = useState(null);
	const [folderOptions, setFolderOptions] = useState([]);
	const [createdFolderId, setCreatedFolderId] = useState(0);
	const [projectOverlayVisible, setProjectOverlayVisible] = useState(false);
	
	let formikApi = null;
	
	const { t } = useTranslation('translations');
	
	useEffect(() => {
		if (!user) {
			goTo('summary');
		}
	}, [user, goTo]);
	
	useEffect(() => {
		if (user) {
			const load = async () => {
				setFolders(await loadArchiveFolders({ pageSize: 0 }));
				setLoadingFolders(false);
			};
			if (folders === null && loadingFolders === false) {
				setLoadingFolders(true);
				load();
			}
		}
	}, [user, folders, loadArchiveFolders, loadingFolders, setFolders, setLoadingFolders]);
	
	useEffect(() => {
		if (folders !== null) {
			setFolderOptions(folders.map((folder) => ({
				value: folder.folderId,
				label: folder.folderName,
			})));
		}
	}, [folders]);
	
	useEffect(() => {
		const folderOption = folderOptions.find((option) => option.value === createdFolderId);
		if (folderOption && formikApi) {
			formikApi.setFieldValue('folder', folderOption);
		}
	}, [folderOptions, createdFolderId, formikApi]);
	
	const validationSchema = Yup.object().shape({
		folder: Yup.object().required(t(Errors.Required)),
	});
	
	const goToSummary = () => goTo('summary');
	
	const handleSubmit = async (values) => {
		await assignProjectToFolder(state.projectId, values.folder.value, values.configurationName);
		goToSummary();
	};
	
	const closeProjectOverlay = async (folderId) => {
		if (folderId > 0) {
			setCreatedFolderId(folderId);
			setFolders(await loadArchiveFolders());
		}
		setProjectOverlayVisible(false);
	};
	
	return (
		<div className="type-project-details">
			<ProjectOverlay
				isOpen={projectOverlayVisible}
				onClose={(folderId) => closeProjectOverlay(folderId)}
			/>
			<h2><TranslationTag tag="Configuration_ProjectDetailsHeadline" /></h2>
			<Form
				initialValues={{
					folder: '',
					configurationNumber: state.projectId,
				}}
				validationSchema={validationSchema}
				validateOnChange={true}
				registerAPI={(api) => { formikApi = api; }}
				onSubmit={handleSubmit}
			>
				<div className="fields">
					<Input
						name="configurationNumber"
						text="Configuration_ConfigurationNumber"
						class="disabled"
					/>
					<Select
						name="folder"
						placeholder={t('Configuration_SelectFolder')}
						options={folderOptions}
						noOptionsText={t('Configuration_NoFolders')}
					/>
					<Note
						headline="Configuration_CreateFolderHeadline"
						htmlText={t('Configuration_CreateFolderText')}
						linkText={t('Configuration_CreateFolderLinktext')}
						onClickLinkText={() => setProjectOverlayVisible(true)}
					/>
				</div>
				<div className="order-button">
					<Button
						class="btn-primary"
						text="Configuration_SaveProject"
						type="submit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => goToSummary()}
						text="Configuration_ShowSummary"
					/>
				</div>
			</Form>
		</div>
	);
}

export default ProjectDetails;