import { Document, Font, Image, PDFDownloadLink, Page, Path, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import Button from 'components/core/button';
import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Separator from 'components/forms/separator';
import SpinnerLoader from 'components/system/spinner-loader';
import { makeSelectAllConfigurations, useArchiveApi } from 'hooks/useArchiveApi';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Html from 'react-pdf-html';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function ProjectPDF() {
	const { user } = useAuth();
	const history = useHistory();
	const [showPrices, setShowPrices] = useState(true);
	const { t } = useTranslation('translations');
	const { state: archiveState, loadProjectById } = useArchiveApi();
	const selectAllConfigurations = useMemo(() => makeSelectAllConfigurations(), []);
	const configurations = selectAllConfigurations(archiveState, history.location.state.project.projectId || null);
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [projectsLoaded, setProjectsLoaded] = useState(false);
	
	if (!history.location.state || !history.location.state.project) {
		history.push(Routes.profile.archive.index);
	}
	
	useEffect(() => {
		const load = async () => {
			await loadProjectById(history.location.state.project.projectId);
			setProjectsLoaded(true);
			setLoadingProjects(false);
		};
		if (!projectsLoaded && !loadingProjects) {
			setLoadingProjects(true);
			load();
		}
	}, [projectsLoaded, loadProjectById, loadingProjects, history.location.state.project.projectId]);
	
	return (
		<div className="project-pdf">
			<Overlay
				headline="Archive_PdfProjectHeadline"
				text="Archive_PdfProjectText"
				backLink={Routes.profile.archive.index}
			>
				<SpinnerLoader
					show={!projectsLoaded}
				/>
				<Form
					initialValues=""
					validationSchema=""
				>
					<Separator
						text="Archive_PdfShowPrices"
					/>
					<div className="list-price-buttons d-flex justify-content-between">
						<Button
							class={'btn-secondary' + (showPrices ? ' active' : '')}
							onClick={() => setShowPrices(true)}
							text="Archive_ShowPricesYes"
						/>
						<Button
							class={'btn-secondary' + (!showPrices ? ' active' : '')}
							onClick={() => setShowPrices(false)}
							text="Archive_ShowPricesNo"
						/>
					</div>
					<div className="btn-container">
						<PDFDownloadLink document={<PDFTemplate showPrices={showPrices} project={history.location.state.project} configurations={configurations} t={t} user={user}/>} className="btn btn-primary" fileName={'project-' + history.location.state.project.projectId + '.pdf'}>
							{({ blob, url, loading, error }) =>
								t('Archive_PdfProjectSubmit')
							}
						</PDFDownloadLink>
					</div>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_PdfProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
		
	);
}

Font.register({
	family: 'RiformaLight',
	fonts: [
		{
			src: '/fonts/RiformaLLLight.ttf',
			fontWeight: 300,
		},
	],
});

const styles = StyleSheet.create({
	page: {
		fontFamily: 'RiformaLight',
		fontSize: '8px',
		lineHeight: '1.2',
		fontWeight: 300,
		paddingLeft: '25px',
		paddingRight: '25px',
		paddingTop: '20px',
		paddingBottom: '70px',
	},
	configuration: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingBottom: '20px',
	},
	rowLeft: {
		width: '48%',
	},
	rowRight: {
		width: '48%',
	},
	image: {
		width: '180px',
	},
	articleHeadline: {
		fontSize: '10px',
		paddingBottom: '2px',
	},
	articleKey: {
		paddingBottom: '2px',
	},
	article: {
		paddingBottom: '20px',
	},
	articleLast: {
		paddingBottom: '0px',
	},
	headline: {
		fontSize: '10px',
		paddingBottom: '2px',
	},
	infos: {
		paddingTop: '35px',
	},
	info: {
		paddingBottom: '10px',
	},
	infoHeadline: {
		fontSize: '10px',
	},
	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingBottom: '30px',
	},
	logo: {
		width: '125px',
		height: '36px',
	},
	footer: {
		width: '100%',
		position: 'absolute',
		bottom: 0,
		left: 0,
		paddingLeft: '25px',
		paddingRight: '25px',
		paddingBottom: '20px',
		fontSize: '6px',
	},
	project: {
		width: '100%',
		padding: '10px',
		backgroundColor: '#EFEFF0',
		marginBottom: '20px',
		fontSize: '10px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	projectDate: {
		fontSize: '8px',
	},
});

const PDFTemplate = ({ showPrices, project, configurations, t, user }) => (
	<Document>
		<Page style={styles.page}>
			<View style={styles.header} fixed>
				<Svg style={{ width: 125, height: 36 }} viewBox="0 0 174.443 50">
					<Path d="M-470.129,135.057c-13.065,0-22.548,10.344-22.548,24.6s9.483,24.6,22.548,24.6,22.547-10.345,22.547-24.6-9.483-24.6-22.547-24.6m0,49.6c-13.292,0-22.939-10.514-22.939-25s9.648-25,22.939-25,22.938,10.514,22.938,25-9.647,25-22.938,25" transform="translate(621.634 -134.654)" fill="#161d1b"/>
					<Path d="M-732.156,140.694l-17.108,47.118h-.367l-16-46.448-16,46.448H-782L-799.1,140.694h.417l16.867,46.459,16-46.459h.369l16,46.459,16.868-46.459Z" transform="translate(851.934 -139.252)" fill="#161d1b"/>
					<Path d="M-987.569,135.058c13.89,0,21.635,10.186,21.708,24.4h-43.35c.082-14.209,8.749-24.4,21.642-24.4m20.4,36.15c-2.915,7.747-10.891,13.044-19.942,13.044-12.912,0-22.013-10.186-22.1-24.4h43.742v-.2c0-14.583-7.98-25-22.1-25-13.067,0-21.952,10.321-22.034,24.8h0v.4h0c.086,14.352,9.281,24.8,22.49,24.8,9.234,0,17.443-5.462,20.383-13.447Z" transform="translate(1009.604 -134.655)" fill="#161d1b"/>
				</Svg>
				<Text render={({ pageNumber, totalPages }) => (
					`${t('Archive_PdfPage')} ${pageNumber} / ${totalPages}`
				)} />
			</View>
			<View style={styles.project}>
				<Text>{t('Archive_ProjectNumber') + ': ' + project.projectId}</Text>
				<Text style={styles.projectDate}>{t('Archive_CreationDate') + ': ' + project.configurationDate}</Text>
			</View>
			{configurations && configurations.map((configuration, key) => {
				return (
					<>
						{key > 0 &&
							<View break></View>
						}
						<View style={styles.configuration} key={configuration.configurationId}>
							<View style={styles.rowLeft}>
								<Text style={styles.headline}>{t('Archive_Configuration')} – { configuration.family }</Text>
								<Text><Html style={{ fontSize: '8px' }}>{configuration.summary.replaceAll('&#160;', ' ')}</Html></Text>
								<Image style={styles.image} src={{ uri: configuration.image, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }} />
								<View style={styles.infos}>
									{ configuration.luminaireSuperkey?.length > 0 &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_LuminaireSuperkey')}</Text>
											<Text style={styles.infoText}><Html style={{ fontSize: '8px' }}>{configuration.luminaireSuperkey}</Html></Text>
										</View>
									}
									{ configuration.poleSuperkey?.length > 0 &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_PoleSuperkey')}</Text>
											<Text style={styles.infoText}>{configuration.poleSuperkey}</Text>
										</View>
									}
									{ configuration.numberOfProducts > 0 &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_Amount')}</Text>
											<Text style={styles.infoText}>{configuration.numberOfProducts}</Text>
										</View>
									}
									{ configuration.singlePrice?.length > 0 && showPrices &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_SinglePrice')}</Text>
											<Text style={styles.infoText}>{configuration.singlePrice}</Text>
										</View>
									}
									{ configuration.accessoriesPrice?.length > 0 && showPrices &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_AccessoriesPrice')}</Text>
											<Text style={styles.infoText}>{configuration.accessoriesPrice}</Text>
										</View>
									}
									{ configuration.totalPrice?.length > 0 && showPrices &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_TotalPrice')}</Text>
											<Text style={styles.infoText}>{configuration.totalPrice}</Text>
										</View>
									}
									{ configuration.discount?.length > 0 && user.discountAvailable && showPrices &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_Discount')}</Text>
											<Text style={styles.infoText}>{configuration.discount}</Text>
										</View>
									}
									{ configuration.discountMargin?.length > 0 && user.discountAvailable && showPrices &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_DiscountMargin')}</Text>
											<Text style={styles.infoText}>{configuration.discountMargin}</Text>
										</View>
									}
									{ configuration.tags?.length > 0 &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_Tags')}</Text>
											<Text style={styles.infoText}>{configuration.tags}</Text>
										</View>
									}
									{ configuration.comment?.length > 0 &&
										<View style={styles.info}>
											<Text style={styles.infoHeadline}>{t('Archive_Comment')}</Text>
											<Text style={styles.infoText}>{configuration.comment}</Text>
										</View>
									}
								</View>
							</View>
							<View style={styles.rowRight}>
								{ configuration.articles.map((article, index) => (
									<View key={article.articleKey} wrap={false} style={[styles.article, index === (configuration.articles.length - 1) ? styles.articleLast : '']}>
										<Text style={styles.articleHeadline}>{article.numberOf + 'x ' + article.headline}</Text>
										<Text style={styles.articleKey}>{article.articleKey}</Text>
										<Text><Html style={{ fontSize: '8px' }}>{article.text.replaceAll('&#160;', ' ')}</Html></Text>
									</View>
								))}
							</View>
						</View>
					</>
				);
			})}
			<View style={styles.footer} fixed>
				<Text>{t('Archive_PdfFooter1')}</Text>
				<Text>{t('Archive_PdfFooter2')}</Text>
				<Text>{t('Archive_PdfFooter3')}</Text>
				<Text>{t('Archive_PdfFooter4')}</Text>
				<Text>{t('Archive_PdfFooter5')}</Text>
			</View>
		</Page>
	</Document>
);

export default ProjectPDF;