import TranslationTag from 'utils/translations';
import Icon from '../icon';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import './index.scss';

function Product (props) {
	const { t } = useTranslation('translations');
	
	return (
		<div className={'product-container d-flex' + ((!props.orderLayout && !props.summaryLayout) ? ' summary' : '') + (props.tooltipText ? ' with-tooltip' : '') + (props.disabled ? ' disabled' : '') + (!props.image ? ' without-image' : '') + (props.active ? ' active' : '') + (props.favoriteLayout ? ' favorite-layout' : '') + (props.editLayout ? ' edit-layout' : '') + (props.favoriteArchiveLayout ? ' favorite-archive-layout' : '') + (props.downloadLayout ? ' download-layout' : '') + (props.defaultLayout ? ' default-layout' : '') + (props.summaryLayout ? ' summary-layout' : '')} onClick={() => props.onClick(props.configurationId, props.pcpSaveId)} title={props.hoverTitle}>
			{ props.image &&
				<div className="image">
					<img src={props.image} alt="" />
				</div>
			}
			<div className="text df ac">
				<h2>{ props.name }</h2>
				{ props.summary &&
					<div className="summary">
						{ parse(props.summary) }
					</div>
				}
				{ props.orderLayout &&
					<div className="prices">
						<div className="quantity">
							<TranslationTag tag="Order_Quantity"/>: { props.quantity }
						</div>
						<div className="total-price">
							<TranslationTag tag="Order_TotalAmount"/>: { props.totalPrice }
						</div>
						<div className="unit-price">
							<TranslationTag tag="Order_UnitPrice"/>: { props.unitPrice }
						</div>
					</div>
				}
				{ props.favoriteLayout &&
					<div className="favorite-icon">
						<Icon
							icon="favorite"
							width="23"
							height="21"
							color="#CACACD"
						/>
						<Icon
							icon="favorite-filled"
							width="23"
							height="21"
						/>
					</div>
				}
				{ props.editLayout &&
					<div className="edit-icon">
						<Icon
							icon="edit"
							width="23"
							height="21"
						/>
					</div>
				}
				{ (props.defaultLayout && props.onDelete) &&
					<div className="default-icon" onClick={() => props.onDelete(props.configurationId)}>
						<Icon
							icon="delete"
							width="17"
							height="20"
							color="#161D1B"
						/>
					</div>
				}
				{ props.favoriteArchiveLayout &&
					<>
						<div className="edit-text">
							<button onClick={() => props.onEdit(props.configurationId)}>
								<Icon icon="edit"/>
								{t('Favorites_Edit')}
							</button>
						</div>
						<div className="default-icon" onClick={() => props.onDelete(props.configurationId)}>
							<Icon
								icon="favorite-filled"
								width="23"
								height="21"
								color="#161D1B"
							/>
						</div>
					</>
				}
				{ props.summaryLayout &&
					<div className="default-icon" onClick={() => props.onDelete(props.configurationId)}>
						<Icon
							icon="delete"
							width="17"
							height="20"
							color="#161D1B"
						/>
					</div>
				}
				{ props.draggable &&
					<div className="drag-icon">
						<Icon
							icon="drag"
							width="9"
							height="17"
							color="#161D1B"
						/>
					</div>
				}
			</div>
			{props.tooltipText &&
				<div className="tooltip-container">
					<div className="info-icon">
						<Icon
							icon="info"
							width="4"
							height="12"
							color="#FFFFFF"
						/>
					</div>
					<div className="line"></div>
					<div className="info-content">
						<TranslationTag tag={props.tooltipText} />
					</div>
				</div>
			}
		</div>
	);
}

Product.defaultProps = {
	image: '',
	name: '',
	quantity: 0,
	totalPrice: 0,
	unitPrice: 0,
	summary: '',
	tooltipText: '',
	configurationId: 0,
	pcpSaveId: 0,
	onClick: () => void(0),
	onDelete: () => void(0),
	onEdit: () => void(0),
	active: false,
	favoriteLayout: false,
	editLayout: false,
	defaultLayout: false,
	summaryLayout: false,
	orderLayout: false,
	downloadLayout: false,
	favoriteArchiveLayout: false,
	draggable: false,
	disabled: false,
};

export default Product;
