import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import './index.scss';

function Card(props) {
	return (
		<div className={`card version-${props.version}${props.class ? ' ' + props.class : ''}`} onClick={props.onClick}>
			<div className="inner d-flex align-items-center">
				{ props.image &&
					<div className="image">
						<img src={props.image} alt={props.headline} />
					</div>
				}
				{ props.iconBefore &&
					<div className="icon-container-before">
						<Icon
							icon={props.iconBefore}
							width={props.iconBeforeWidth}
							height={props.iconBeforeHeight}
							color={props.iconBeforeColor}
						/>
					</div>
				}
				<div className="text-container">
					<div className="headline t2"><TranslationTag tag={props.headline} /></div>
					{props.text &&
						<div className="text"><TranslationTag tag={props.text} /></div>
					}
				</div>
			</div>
			{ props.icon &&
				<div className="icon-container">
					<Icon
						icon={props.icon}
						width={props.iconWidth}
						height={props.iconHeight}
						color={props.iconColor}
					/>
				</div>
			}
			{props.tooltipText &&
				<div className="tooltip-container">
					<div className="info-icon">
						<Icon
							icon="info"
							width="4"
							height="12"
							color="#FFFFFF"
						/>
					</div>
					<div className="line"></div>
					<div className="info-content">
						<TranslationTag tag={props.tooltipText} />
					</div>
				</div>
			}
		</div>
	);
}

Card.defaultProps = {
	headline: '',
	text: '',
	version: 1,
	tooltipText: '',
	image: '',
	icon: '',
	iconWidth: 20,
	iconHeight: '20',
	iconColor: '',
	class: '',
	iconBefore: '',
	iconBeforeWidth: '',
	iconBeforeHeight: '',
	iconBeforeColor: '',
	onClick: () => void(0),
};

export default Card;
