import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { Routes } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import './style.scss';

function EditConfiguration() {
	const history = useHistory();
	const { loadConfig } = useConfiguratorApi();
	
	const loadConfiguration = async () => {
		await loadConfig(history.location.state.saveId);
		history.push(Routes.configuration.index);
	};
	
	return (
		<div className="archive-edit-configuration">
			<Overlay
				headline="ArchiveEditConfiguration_Headline"
				text="ArchiveEditConfiguration_Text"
				backLink={Routes.profile.archive.index}
			>
				<div className="edit-content">
					<Button
						class="btn-primary"
						text="ArchiveEditConfiguration_Edit"
						onClick={() => loadConfiguration()}
					/>
					<Button
						class="btn-secondary"
						text="ArchiveEditConfiguration_Close"
						onClick={() => history.push(Routes.profile.archive.index)}
					/>
				</div>
			</Overlay>
		</div>
	);
}

export default EditConfiguration;