import TranslationTag from 'utils/translations';
import Icon from '../icon';
import './index.scss';

function ColorCard(props) {
	return (
		<div className={`color-card${props.active ? ' active' : ''}${props.class ? ' ' + props.class : ''}`} onClick={props.onClick}>
			<div className="inner d-flex align-items-center">
				{ props.colorImage &&
					<div className="image">
						<img src={props.colorImage} alt={props.name} />
					</div>
				}
				{ props.colorCode &&
					<div className="image">
						<div className="color-code" style={{ backgroundColor: props.colorCode }}></div>
					</div>
				}
				<div className="text-container">
					<div className="headline t2">{props.name}</div>
					{props.text &&
						<div className="text">{props.text}</div>
					}
				</div>
			</div>
			{props.tooltipText &&
				<div className="tooltip-container">
					<div className="info-icon">
						<Icon
							icon="info"
							width="4"
							height="12"
							color="#FFFFFF"
						/>
					</div>
					<div className="line"></div>
					<div className="info-content">
						<TranslationTag tag={props.tooltipText} />
					</div>
				</div>
			}
		</div>
	);
}

ColorCard.defaultProps = {
	name: '',
	text: '',
	colorCode: '',
	colorImage: '',
	tooltipText: '',
	active: false,
	onClick: () => void(0),
};

export default ColorCard;
