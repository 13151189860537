import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Select from 'components/forms/select';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes, Errors } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';
import * as Yup from 'yup';
import './style.scss';

function ProjectChangeFolder() {
	const { loadArchiveFolders, assignProjectToFolder } = useArchiveApi();
	const [loadingFolders, setLoadingFolders] = useState(false);
	const [folders, setFolders] = useState(null);
	const [folderOptions, setFolderOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation('translations');
	const history = useHistory();
	const validationSchema = Yup.object({
		folder: Yup.object().required(t(Errors.Required)),
	});
	
	const handleSubmit = async (values) => {
		setIsLoading(true);
		await assignProjectToFolder(history.location.state.projectId, values.folder.value);
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	useEffect(() => {
		const load = async () => {
			setFolders(await loadArchiveFolders({ pageSize: 0 }));
			setLoadingFolders(false);
		};
		if (folders === null && loadingFolders === false) {
			setLoadingFolders(true);
			load();
		}
	}, [folders, loadArchiveFolders, loadingFolders, setFolders, setLoadingFolders]);
	
	useEffect(() => {
		if (folders !== null) {
			let userFolders = history.location.state.folderId ? folders.filter((folder) => folder.folderId !== history.location.state.folderId) : folders;
			setFolderOptions(userFolders.map((folder) => ({
				value: folder.folderId,
				label: folder.folderName,
			})));
		}
	}, [folders, history.location.state.folderId]);
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-change-folder">
			<Overlay
				headline="Archive_ChangeFolderProjectHeadline"
				text="Archive_ChangeFolderProjectText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={{
						folder: '',
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<Select
						name="folder"
						placeholder={t('Archive_ChangeFolderProjectAssign')}
						options={folderOptions}
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_ChangeFolderProjectSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_ChangeFolderProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectChangeFolder;