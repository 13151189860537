import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-GB';
import { getLanguageFromLanguageCode } from 'utils/language';
import { Language } from 'utils/constants';

export const DatePickerField = ({ ...props }) => {
	const [focused, setFocused] = useState(false);
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props);
	const { i18n } = useTranslation('translations');
	
	let locale = en;
	switch (getLanguageFromLanguageCode(i18n.language)) {
		case Language.Italian:
			locale = it;
			break;
		case Language.French:
			locale = fr;
			break;
		case Language.German:
			locale = de;
			break;
		default:
			locale = en;
			break;
	}
	
	return (
		<div className={'field calendar type-calendar' + (focused || (field.value !== '' && field.value !== null) ? ' has-value' : '') + ( false ? ' error' : '') }>
			<label htmlFor={props.name}><TranslationTag tag={props.text} /></label>
			<DatePicker
				{...field}
				{...props}
				autoComplete="off"
				dateFormat="dd/MM/yyyy"
				calendarStartDay={1}
				selected={(field.value && new Date(field.value)) || null}
				onFocus={(e) => setFocused(true)}
				locale={locale}
				minDate={moment().add(1, 'days').toDate()}
				onBlur={(e) => {
					setFocused(false);
					field.onBlur(e);
				}}
				onChange={val => {
					setFieldValue(field.name, val);
				}}
			/>
			<div className="icon-container">
				<Icon
					icon="calendar"
					width="20"
					height="22"
				/>
			</div>
		</div>
	);
};

export default DatePickerField;