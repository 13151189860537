import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import RadioButton from 'components/forms/radio-button';
import Separator from 'components/forms/separator';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { languages } from 'i18n';
import { getLanguageFromLanguageCode } from 'utils/language';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import './style.scss';

function ProjectChangeLanguage() {
	const { i18n } = useTranslation('translations');
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	
	const handleSubmit = async (values) => {
		setIsLoading(true);
		//await changeArchiveProjectLanguage(history.location.state.projectId, values.language);
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-change-language">
			<Overlay
				headline="Archive_ChangeLanguageProjectHeadline"
				text="Archive_ChangeLanguageProjectText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={{
						language: i18n.language ? (getLanguageFromLanguageCode(i18n.language) + '') : '3',
					}}
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<Separator
						text="Archive_ChangeLanguageProjectChooseLanguage"
					/>
					{
						languages.map((language, key) => {
							const languageEnum = getLanguageFromLanguageCode(language);
							return (<RadioButton
								key={key}
								name="language"
								headline={'Archive_ChangeLanguageProject' + language.toUpperCase()}
								value={languageEnum}
								id={languageEnum}
							/>);
						})
					}
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_ChangeLanguageProjectSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_ChangeLanguageProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectChangeLanguage;