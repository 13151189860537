import { Field } from 'formik';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import parse from 'html-react-parser';
import './index.scss';

const CheckboxWrapper = ({ text, useInForm = true, onChange, value, ...props }) => {
	if (typeof value !== 'undefined' && value !== null) {
		value += ''; // cast to string
	}
	if (!useInForm) {
		return (
			<Checkbox
				{...props}
				extraLabel={text}
				value={value}
				onChange={onChange}
			/>
		);
	}
	return (
		<Field name={props.name} type="checkbox" value={value}>
			{({ field, form, meta }) => {
				return (
					<Checkbox
						{...props}
						extraLabel={text}
						field={field}
						form={form}
						meta={meta}
						onChange={(e) => {
							if (onChange && onChange(e, field, form, meta) === false) {
								return;
							}
							field.onChange(e);
						}}
					/>
				);
			}}
		</Field>
	);
};

const Checkbox = ({ className, style, disabled, extraLabel, textBefore, textLink, onChange, field = {}, form = {}, meta = {}, ...props }) => {
	return (
		<div style={style} className={'checkbox' + (className !== '' ? ' ' + className : '') + ( meta.error ? ' error' : '') }>
			{ textBefore &&
				<div className="text-before">
					{ parse(textBefore) }
				</div>
			}
			<div className="d-flex">
				<label className={`label${disabled ? ' disabled' : ''}`}>
					<input
						{...props}
						{...field}
						type="checkbox"
						onChange={onChange}
					/>
					<span className="icon-container">
						<Icon
							icon="check"
							width="12"
							height="12"
						/>
					</span>
				</label>
				<span className="text t3">
					{ textLink ? (
						<a href={textLink} target="_blank" rel="noreferrer"><TranslationTag tag={extraLabel} /></a>
					) : (
						<TranslationTag tag={extraLabel} />
					)}
				</span>
			</div>
		</div>
	);
};

CheckboxWrapper.defaultProps = {
	className: '',
	text: '',
	id: '',
	name: '',
	textLink: '',
};

export default CheckboxWrapper;