import RadioButton from 'components/forms/radio-button';
import Button from 'components/core/button';
import Form from 'components/forms/form';
import Checkbox from 'components/forms/checkbox';
import Number from 'components/forms/number';
import TranslationTag from 'utils/translations';
import React, { createRef, useEffect, useState } from 'react';
import { Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import './index.scss';

function Table({ answerId, rows, headings, unit, min, max, step, onSubmit, customInput = true }) {
	const [isCustomValue, setIsCustomValue] = useState(answerId && rows.filter((row) => row[0] === parseInt(answerId)).length <= 0 ? true : false);
	const [checkButtons, setCheckButtons] = useState(true);
	const scrollContainerRef = createRef();
	const scrollLeftButtonRef = createRef();
	const scrollRightButtonRef = createRef();
	const { t } = useTranslation('translations');
	
	Yup.addMethod(Yup.number, 'stepValue', function (errorMessage) {
		return this.test('test-steps', errorMessage, function (value) {
			const { path, createError } = this;
			return (
				((value / step) === (Math.round(value / step))) ||
			createError({ path, message: errorMessage })
			);
		});
	});
	
	const validationSchema = Yup.object().shape({
		isCustomValue: Yup.boolean(),
		customValue: Yup
			.number(t(Errors.Number)).typeError(t(Errors.Number))
			.when('isCustomValue', {
				is: true,
				then: Yup.number(t(Errors.Number)).typeError(t(Errors.Number)).min(min, t(Errors.MinNumber).replace('{0}', min)).max(max, t(Errors.MaxNumber).replace('{0}', max)).stepValue(t(Errors.Steps)).required(t(Errors.Required)),
			}),
	});
	
	const showElement = (element) => {
		element.classList.remove('d-none');
	};
	
	const hideElement = (element) => {
		element.classList.add('d-none');
	};
	
	const scroll = (delta) => {
		const scrollContainer = scrollContainerRef.current;
		const scrollLeftButton = scrollLeftButtonRef.current;
		const scrollRightButton = scrollRightButtonRef.current;
		if (!scrollContainer) {
			return;
		}
		const scrollLeft = scrollContainer.scrollLeft + delta;
		scrollContainer.scrollLeft = scrollLeft;
		if (scrollLeftButton) {
			if (scrollLeft > 0) {
				showElement(scrollLeftButton);
			} else {
				hideElement(scrollLeftButton);
			}
		}
		if (scrollRightButton) {
			if (scrollLeft < scrollContainer.scrollWidth - scrollContainer.clientWidth) {
				showElement(scrollRightButton);
			} else {
				hideElement(scrollRightButton);
			}
		}
	};
	
	useEffect(() => {
		const scrollRightButton = scrollRightButtonRef.current;
		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer && scrollRightButton && checkButtons) {
			if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
				showElement(scrollRightButton);
			}
			setCheckButtons(false);
		}
	}, [checkButtons, scrollContainerRef, scrollRightButtonRef]);
	
	return (
		<div className="table-container d-flex flex-column t3">
			<Form
				validationSchema={validationSchema}
				initialValues={{
					tableRadio: answerId && rows.filter((row) => row[0] === parseInt(answerId)).length > 0 ? answerId : '',
					customValue: answerId && isCustomValue ? answerId : min,
					isCustomValue: isCustomValue,
				}}
				enableReinitialize={true}
				onSubmit={(values) => { onSubmit(values); }}
			>
				<div className="scroll-container">
					<div className="scrollable-container" ref={scrollContainerRef}>
						{ headings &&
							<div className="line d-inline-flex heading">
								{ headings.map((heading) => (
									<div key={heading} className="value">
										{ heading ? <TranslationTag tag={heading} /> : <span>&nbsp;</span>}
									</div>
								))}
							</div>
						}
						{ rows &&
							rows.map((row, index) => (
								<div key={index} className="line d-inline-flex align-items-center">
									{row &&
										row.map((value, innerIndex) => (
											<div key={innerIndex} className="value d-flex align-items-center">
												{ innerIndex === 0 ? (
													<RadioButton
														name="tableRadio"
														headline={value + ' ' + unit}
														value={value}
														id={value}
														submitOnChange={true}
														disabled={isCustomValue}
													/>
												) : (
													value ? value : <div>&nbsp;</div>
												)}
											</div>
										))
									}
								</div>
							))
						}
					</div>
					<div className="scroll-buttons">
						<Button
							icon="arrow2"
							width="14"
							height="14"
							class="arrow left d-none"
							onClick={() => scroll(-45)}
							ref={scrollLeftButtonRef}
						/>
						<Button
							icon="arrow2"
							width="14"
							height="14"
							class="arrow right d-none"
							onClick={() => scroll(45)}
							ref={scrollRightButtonRef}
						/>
					</div>
				</div>
				{ customInput &&
					<div className="custom-value">
						<Checkbox
							name="isCustomValue"
							text="Configuration_DefineOtherValue"
							onChange={() => setIsCustomValue(!isCustomValue)}
						/>
						{ isCustomValue &&
							<div className="value-container">
								<Number
									text={unit}
									name="customValue"
									min={min}
									max={max}
									steps={step}
									adjustMin={false}
									adjustMax={false}
								/>
							</div>
						}
					</div>
				}
			</Form>
		</div>
	);
}

Table.defaultProps = {
	headings: [],
	rows: [],
	unit: '',
	min: 0,
	max: 0,
	onSubmit: () => void(0),
};

export default Table;
