import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function ShareSuccess() {
	const history = useHistory();
	
	return (
		<div className="share-success">
			<Overlay
				headline="Share_ThanksHeadline"
				text="Share_ThanksText"
				backLink={Routes.configuration.summary}
			>
				<Button
					class="btn-primary"
					text={'Share_BackToConfiguration'}
					onClick={() => history.push(Routes.configuration.summary)}
				/>
			</Overlay>
		</div>
	);
}

export default ShareSuccess;