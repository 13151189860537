import { LoadingProvider } from 'hooks/useLoading';
import { StoreProvider } from 'store/useStore';
import { combineComponents } from './combineComponents';

const providers = [
	{
		component: LoadingProvider,
		initialLoadingState: true,
	},
	{
		component: StoreProvider,
	},
];

export const AppContextProvider = combineComponents(...providers);