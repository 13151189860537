import Button from 'components/core/button';
import Form from 'components/forms/form';
import Textarea from 'components/forms/textarea';

function TypeTextarea({ currentQuestion, onSubmit }) {
	return (
		<div className="textarea-outercontainer">
			<Form
				initialValues={{ comment: currentQuestion.answerId ? currentQuestion.answerId : '' }}
				onSubmit={(values) => onSubmit(values)}
			>
				<Textarea
					name="comment"
					placeholder={currentQuestion.data.text}
				/>
				<div className="submit-button">
					<Button
						text="Configuration_SaveAndContinue"
						class="btn-primary"
						type="submit"
					/>
				</div>
			</Form>
		</div>
	);
}

TypeTextarea.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeTextarea;