import Icon from 'components/core/icon';
import Button from 'components/core/button';
import TranslationTag from 'utils/translations';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import './index.scss';

export const Number = ({ onValueChange, adjustMin, adjustMax, ...props }) => {
	const { setFieldValue } = useFormikContext();
	
	return (
		<Field name={props.name}>
			{({ field, meta }) => (
				<div className={'field number-container type-number has-value' + ( meta.error ? ' error' : '') + `${props.class ? ' ' + props.class : ''}`}>
					<div className="input-container">
						<label htmlFor={props.name}><TranslationTag tag={props.text} /></label>
						<input
							{...field}
							{...props}
							onBlur={(e) => {
								field.onBlur(e);
								onValueChange(field.value);
								if(((parseFloat(field.value) < props.min) && adjustMin)){
									setFieldValue(field.name, props.min);
									onValueChange(props.min);
								}
								if((parseFloat(field.value) > props.max) && adjustMax){
									setFieldValue(field.name, props.max);
									onValueChange(props.max);
								}
							}}
							type="text"
						/>
						<div className="controls">
							<div className="more button" onClick={() => {
								if ((parseFloat(field.value) + props.steps) <= props.max) {
									setFieldValue(field.name, parseFloat(field.value) + props.steps);
									onValueChange(parseFloat(field.value) + props.steps);
								}
							}}>
								<Icon
									icon="more"
									width="16"
									height="8"
								/>
							</div>
							<div className="less button" onClick={() => {
								if ((parseFloat(field.value) - props.steps) >= props.min) {
									setFieldValue(field.name, parseFloat(field.value) - props.steps);
									onValueChange(parseFloat(field.value) - props.steps);
								}
							}}>
								<Icon
									icon="less"
									width="16"
									height="8"
								/>
							</div>
						</div>
					</div>
					{ meta.error &&
						<div>
							<div className="error-text"><ErrorMessage name={props.name} /></div>
						</div>
					}
					<div className="submit-button">
						<Button
							text="Configuration_SaveChanges"
							type="submit"
							class="btn-primary"
						/>
					</div>
				</div>
			)}
		</Field>
	);
};

Number.defaultProps = {
	text: '',
	name: '',
	steps: 1,
	min: 1,
	max: 100000,
	default: 0,
	adjustMin: true,
	adjustMax: true,
	onValueChange: () => void(0),
};

export default Number;