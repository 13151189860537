import { createContext, useCallback, useContext, useState } from 'react';

const context = createContext();

export function LoadingProvider({ initialLoadingState, children }) {
	const providerValue = useLoadingProvider(initialLoadingState);
	return <context.Provider value={providerValue}>{children}</context.Provider>;
}

export const useLoading = () => {
	const ctx = useContext(context);
	if (ctx === undefined) {
	  throw new Error('useLoading must be used within a LoadingProvider');
	}
	return ctx;
};

function useLoadingProvider(initialLoadingState = false) {
	const [isLoading, setIsLoading] = useState(initialLoadingState);

	const startLoading = useCallback(() => {
		setIsLoading(true);
	}, []);

	const stopLoading = useCallback(() => {
		setIsLoading(false);
	}, []);

	return {
		isLoading,
		startLoading,
		stopLoading,
	};
}
