import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function PasswordResetSuccess() {
	const history = useHistory();
	
	return (
		<div className="password-reset-success">
			<Overlay
				headline="PasswordResetSuccess_Headline"
				text="PasswordResetSuccess_Text"
			>
				<Button
					class="btn-primary"
					text={'PasswordResetSuccess_Again'}
					onClick={() => history.push(Routes.login.passwordReset.index)}
				/>
			</Overlay>
		</div>
	);
}

export default PasswordResetSuccess;