import { Formik, Form } from 'formik';
import './index.scss';

function FormWrapper(props) {
	return (
		<Formik
			initialValues={props.initialValues}
			validationSchema={props.validationSchema}
			enableReinitialize={props.enableReinitialize}
			validateOnChange={props.validateOnChange}
			validateOnBlur={props.validateOnBlur}
			onSubmit={props.onSubmit}
		>
			{(formik) => {
				if (props.registerAPI) {
					props.registerAPI(formik);
				}
				return (
					<Form>
						{props.children}
					</Form>
				);
			}}
		</Formik>
	);
}

FormWrapper.defaultProps = {
	validationSchema: '',
	enableReinitialize: true,
	validateOnChange: false,
	validateOnBlur: false,
	onSubmit: () => void(0),
	onChange: () => void(0),
};

export default FormWrapper;
