import Logo from 'images/logo.svg';
import './index.scss';

function GlobalLoader({ show }) {
	if (!show) {
		return null;
	}
	return (
		<div className="global-loader">
			<img src={Logo} alt="" />
		</div>
	);
}

export default GlobalLoader;
