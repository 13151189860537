import Icon from '../icon';
import DotMenuItem from 'components/navigation/dot-menu/item';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'utils/constants';
import { Fragment, useState, useRef, useEffect } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import './index.scss';

function UserImage({ large }) {
	const history = useHistory();
	const myRef = useRef();
	const { user, logout } = useAuth();
	const location = useLocation();
	const { reset } = useConfiguratorApi();
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	
	const handleLogout = async () => {
		await logout();
		reset();
	};
	
	const handleClick = e => {
		if (myRef && myRef.current) {
			if (!myRef.current.contains(e.target)) {
				setSubMenuOpen(false);
			}
		}
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	});
	
	return (
		<Fragment>
			{ large ?
				<div className={'user-image t4' + (large ? ' large' : '')}>
					<NavLink to={Routes.profile.index}>
						{ user.userImage ?
							<img src={user.userImage} alt={user.firstName + ' ' + user.lastName}/>
							:
							<Fragment>
								{ (user.firstName ? user.firstName.charAt(0) : '') + (user.lastName ? user.lastName.charAt(0) : '') }
							</Fragment>
						}
					</NavLink>
				</div>
				:
				<div className={'user-image' + (subMenuOpen ? ' sub-menu' : '') + (!user ? ' guest' : ' user')} ref={myRef}>
					<div className="inner-image" onClick={() => setSubMenuOpen(!subMenuOpen)}>
						{ user ?
							user.userImage ?
								<img src={user.userImage} alt={user.firstName + ' ' + user.lastName}/>
								:
								<span>
									{ (user.firstName ? user.firstName.charAt(0) : '') + (user.lastName ? user.lastName.charAt(0) : '') }
								</span>
							:
							<Icon
								icon="user"
								width="18"
								height="19"
								color="#FFFFFF"
							/>
						}
					</div>
					{ subMenuOpen &&
						<div className="sub-menu">
							{ user ?
								<>
									{ location.pathname.indexOf(Routes.profile.data.index) < 0 &&
										<DotMenuItem
											icon="user"
											text="User_ShowProfile"
											width="18" 
											height="18"
											onClick={() => {
												history.push(Routes.profile.index);
												setSubMenuOpen(false);
											}}
										/>
									}
									{ location.pathname.indexOf(Routes.profile.archive.index) < 0 &&
										<DotMenuItem
											icon="archive"
											text="User_ShowArchive"
											width="19"
											height="20"
											onClick={() => {
												history.push(Routes.profile.archive.index);
												setSubMenuOpen(false);
											}}
										/>
									}
									<DotMenuItem
										icon="logout"
										text="User_Logout"
										width="19"
										height="20"
										onClick={() => handleLogout()}
									/>
								</>
								:
								<>
									<DotMenuItem
										icon="login"
										text="User_Login"
										width="20"
										height="19"
										onClick={() => history.push(Routes.configuration.login)}
									/>
									<DotMenuItem
										icon="register"
										text="User_RegisterNow"
										width="19"
										height="20"
										onClick={() => history.push(Routes.configuration.registrationOverlay)}
									/>
								</>
							}
						</div>
					}
				</div>
			}
		</Fragment>
	);
}

UserImage.defaultProps = {
	large: false,
};

export default UserImage;
