import {  useEffect } from 'react';
import axios from 'axios';
import { configUrl } from 'configuration/config';
import { ConfigLoadingStatus, useConfig } from 'configuration/useConfig';
import { createInstance } from 'services/api/client';
import { init as initI18n } from './i18n';

const ConfigWrapper = ({ children }) => {
	const { config, setConfig, loadingState, setLoadingState } = useConfig();

	useEffect(() => {
		const getConfig = async () => {
			let newConfig = config;
			try {
				const response = await axios.get(configUrl);
				newConfig = {
					...config,
					...response.data,
				};
				setConfig(newConfig);
				setLoadingState(ConfigLoadingStatus.Loaded);
			} catch (error) {
				// fallbacks to defaultConfig
				setLoadingState(ConfigLoadingStatus.Error);
			}
		};

		if (loadingState === ConfigLoadingStatus.Unknown) {
			getConfig();
		}
	}, [setConfig, config, setLoadingState, loadingState]);

	if (loadingState === ConfigLoadingStatus.Unknown || loadingState === ConfigLoadingStatus.Loading) {
		return null;
	}

	createInstance({
		baseURL: config.baseApiUrl,
	});
	initI18n({
		baseResourceUrl: config.baseApiUrl,
	});
	// if (configLoadingState === 'error') {
	// 	return <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config </p>;
	// }

	return children;
};

export default ConfigWrapper;
