import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import { Routes, Genders, Errors, StaticUrls } from 'utils/constants';
import Input from 'components/forms/input';
import Separator from 'components/forms/separator';
import { useTranslation } from 'react-i18next';
import Select from 'components/forms/select';
import { prepareCountriesForSelect } from 'utils/objects';
import { useCountryApi } from 'hooks/useCountryApi';
import Checkbox from 'components/forms/checkbox';
import TranslationTag from 'utils/translations';
import { useHistory } from 'react-router-dom';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useAuth } from 'hooks/useAuth';
import DatePickerField from 'components/forms/calendar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Product from 'components/core/product';
import * as Yup from 'yup';
import './style.scss';

function Order() {
	const { t, i18n } = useTranslation('translations');
	const { state: countryState } = useCountryApi();
	const { state, sendOrder, getOrderInfo } = useConfiguratorApi();
	const history = useHistory();
	const { user } = useAuth();
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	const isArchive = (history.location.state && history.location.state.project);
	const projectId = isArchive ? history.location.state.project.projectId : state.projectId;
	
	const validationSchema = Yup.object({
		composerFirstName: Yup.string().required(t(Errors.Required)),
		composerSecondName: Yup.string().required(t(Errors.Required)),
		composerEmail: Yup.string().required(t(Errors.Required)),
		conditions: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
		privacyPolicy: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
	});
	
	const handleSubmit = async (values) => {
		let properties = {
			...values,
			deliverySalutation: values.deliverySalutation ? values.deliverySalutation.value : '',
			deliveryCountry: values.deliveryCountry ? values.deliveryCountry.value : '',
			requestedDeliveryDate: values.requestedDeliveryDate ? moment(values.requestedDeliveryDate).format('DD.MM.Y') : '',
		};
		await sendOrder(properties, projectId);
		if (isArchive) {
			history.push(Routes.configuration.order.success, { projectId });
		} else {
			history.push(Routes.configuration.order.success);
		}
	};
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, projectId]);
	
	if (!projectId || !user) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="order">
			<Overlay
				headline="Order_Headline"
				text="Order_Text"
				backLink={isArchive ? Routes.profile.archive.index : Routes.configuration.summary}
			>
				<Form
					initialValues={{
						projectName: '',
						projectNumber: '',
						projectId: projectId,
						composerFirstName: user.firstName ? user.firstName : '',
						composerSecondName: user.lastName ? user.lastName : '',
						composerCompany: user.company ? user.company : '',
						composerEmail: user.eMail ? user.eMail : '',
						deliverySalutation: Genders(t, user.gender ? user.gender : ''),
						deliveryFirstName: user.firstName ? user.firstName : '',
						deliverySecondName: user.lastName ? user.lastName : '',
						deliveryCompany: user.company ? user.company : '',
						deliveryEmail: user.eMail ? user.eMail : '',
						deliveryPhone: user.phoneNumber ? user.phoneNumber : '',
						deliveryStreet: user.street ? user.street : '',
						deliveryHouseNumber: user.housenumber ? user.housenumber : '',
						deliveryCity: user.city ? user.city : '',
						deliveryZip: user.zipCode ? user.zipCode : '',
						deliveryCountry: user.country ? user.country : '',
						requestedDeliveryDate: '',
						conditions: false,
						privacyPolicy: false,
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<Separator
						text="Order_Headline1"
					/>
					<Input
						name="projectId"
						text="Form_ProjectNumberEwo"
						class="disabled"
					/>
					<Input
						name="projectName"
						text="Form_ProjectName"
					/>
					<Input
						name="projectNumber"
						text="Form_ProjectNumber"
					/>
					<Separator
						text="Order_Headline2"
					/>
					<Input
						name="composerFirstName"
						text="Form_Firstname"
						class="medium"
					/>
					<Input
						name="composerSecondName"
						text="Form_Lastname"
					/>
					<Input
						name="composerCompany"
						text="Form_Companyname"
					/>
					<Input
						name="composerEmail"
						text="Form_Email"
					/>
					<Separator
						text="Order_Headline3"
					/>
					<div className="products">
						{ infos.configurations.length > 0 &&
							infos.configurations.map((configuration, index) => (
								<Product
									key={index}
									name={configuration.family}
									image={configuration.image}
									quantity={configuration.numberOfProducts}
									totalPrice={configuration.totalPrice}
									unitPrice={configuration.singlePrice}
									orderLayout={true}
								/>
							))
						}
					</div>
					<div className="price-container">
						<div className="top">
							<div className="text">
								<h2><TranslationTag tag="Order_DiscountHeadline"/></h2>
								<div><TranslationTag tag="Order_DiscountText"/></div>
							</div>
							<div className="price">
								<div className="total-amount d-flex justify-content-between">
									<div><TranslationTag tag="Order_TotalAmount2"/></div>
									{ infos.totalPrice &&
										<div>{ infos.totalPrice }</div>
									}
								</div>
								<div className="discount d-flex justify-content-between">
									<div><TranslationTag tag="Order_DiscountOnline"/></div>
									{ infos.discountAmount &&
										<div>-{ infos.discountAmount }</div>
									}
								</div>
								<div className="spacer"></div>
								<div className="discount-amount d-flex justify-content-between">
									<h4><TranslationTag tag="Order_AmountToPay"/></h4>
									{ infos.finalPrice && 
										<h4>{ infos.finalPrice }</h4>
									}
								</div>
								<div className="infos-amount d-flex">
									<div><TranslationTag tag="Order_AmountConditions"/></div>
								</div>
							</div>
						</div>
						{ /* HiddenFeature: 
						<div className="bottom">
							<h2><TranslationTag tag="Order_CheckRequired"/></h2>
							<div><TranslationTag tag="Order_CheckRequiredText"/></div>
							<div>
								<Link
									text="Order_OpenConfigurationDetails"
									onClick={() => alert('To define')}
								/>
							</div>
						</div>
						*/}
					</div>
					<Separator
						text="Order_Headline4"
					/>
					<Select
						name="deliverySalutation"
						placeholder={t('Form_Salutation')}
						class="medium"
						defaultValue={Genders(t, user.gender ? user.gender : '')}
						options={Genders(t)}
					/>
					<div className="field"></div>
					<Input
						name="deliveryFirstName"
						text="Form_Firstname"
						class="medium"
					/>
					<Input
						name="deliverySecondName"
						text="Form_Lastname"
					/>
					<Input
						name="deliveryCompany"
						text="Form_Companyname"
					/>
					<Input
						name="deliveryEmail"
						text="Form_Email"
					/>
					<Input
						name="deliveryPhone"
						text="Form_Phone"
					/>
					<Input
						name="deliveryStreet"
						text="Form_Street"
						class="small"
					/>
					<Input
						name="deliveryHouseNumber"
						text="Form_StreetNr"
					/>
					<Input
						name="deliveryCity"
						text="Form_Place"
						class="small"
					/>
					<Input
						name="deliveryZip"
						text="Form_Cap"
					/>
					<Select
						name="deliveryCountry"
						placeholder={t('Form_Country')}
						options={prepareCountriesForSelect(countryState.countries)}
						searchable={true}
					/>
					<DatePickerField
						name="requestedDeliveryDate"
						text="Form_DeliveryDate"
					/>
					<Checkbox
						name="conditions"
						id="conditions"
						text="Form_Conditions"
						textLink={StaticUrls('agb', '', i18n.language)}
					/>
					<Checkbox
						name="privacyPolicy"
						id="privacyPolicy"
						text="Form_AcceptPrivacy"
						textLink={StaticUrls('privacy2', '', i18n.language)}
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Order_SendOrder"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default Order;