import Button from 'components/core/button';
import TranslationTag from 'utils/translations';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Separator from 'components/forms/separator';
import Textarea from 'components/forms/textarea';
import Select from 'components/forms/select';
import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import * as Yup from 'yup';
import { Errors, Genders } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useCountryApi } from 'hooks/useCountryApi';
import { prepareCountriesForSelect } from 'utils/objects';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function ProjectOverlay({ isOpen, onClose }) {
	const element = document.getElementById('resize-container');
	const { t } = useTranslation('translations');
	const { state: countryState } = useCountryApi();
	const { createArchiveFolder } = useArchiveApi();
	const validationSchema = Yup.object({
		folderName: Yup.string().required(t(Errors.Required)),
	});

	const classList = element ? element.classList : null;
	
	useEffect(() => {
		if (classList) {
			if (isOpen) {
				classList.add('project-overlay');
			} else {
				classList.remove('project-overlay');
			}
		}
	}, [isOpen, classList]);

	const createFolder = async (values) => {
		const response = await createArchiveFolder({
			...values,
			countryId: values.countryId.value ?? 0,
			customerCountryId: values.customerCountryId.value ?? 0,
			customerGender: values.customerGender.value ?? 0,
		});
		onClose(response.folderId);
	};
	
	const initialValues = {
		folderName: '',
		folderNumber: '',
		street: '',
		houseNumber: '',
		city: '',
		zipCode: '',
		countryId: '',
		comment: '',
		customerGender: '',
		customerFirstName: '',
		customerSecondName: '',
		customerCompany: '',
		customerEmail: '',
		customerPhone: '',
		customerStreet: '',
		customerHouseNumber: '',
		customerCity: '',
		customerZipCode: '',
		customerCountryId: '',
	};
	
	return (
		<div>
			{ isOpen &&
				<div className="project-overlay">
					<div className="bg"></div>
					<Form
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values) => createFolder(values)}
					>
						<div className="content">
							<div className="intro-container">
								<div className="text">
									<div className="t2 headline">
										<TranslationTag tag="Configuration_ProjectOverlayHeadline" />
									</div>
								</div>
								<div className="close-button">
									<Button
										onClick={() => onClose()}
										icon="close"
										width="14"
										height="14"
									/>
								</div>
							</div>
							<div className="content-container">
								<SimpleBar style={{ maxHeight: 'calc(100vh - 200px)' }} autoHide={false}>
									<div className="inner-content">
										<Separator
											text="CreateProject_Headline1"
										/>
										<Input
											name="folderName"
											text="Form_ProjectName"
										/>
										<Separator
											text="CreateProject_Headline2"
										/>
										<Input
											name="folderNumber"
											text="Form_ProjectNumber"
										/>
										<Input
											name="street"
											text="Form_Street"
											class="small"
										/>
										<Input
											name="houseNumber"
											text="Form_StreetNr"
										/>
										<Input
											name="city"
											text="Form_Place"
											class="medium"
										/>
										<Input
											name="zipCode"
											text="Form_Cap"
										/>
										<Select
											name="countryId"
											placeholder={t('Form_Country')}
											options={prepareCountriesForSelect(countryState.countries)}
											searchable={true}
										/>
										<Textarea
											name="comment"
											placeholder={t('Form_Comment')}
										/>
										<Separator
											text="CreateProject_Headline3"
										/>
										<Select
											name="customerGender"
											placeholder={t('Form_Salutation')}
											options={Genders(t)}
										/>
										<Input
											name="customerFirstName"
											text="Form_Firstname"
											class="medium"
										/>
										<Input
											name="customerSecondName"
											text="Form_Lastname"
										/>
										<Input
											name="customerCompany"
											text="Form_Companyname"
										/>
										<Input
											name="customerEmail"
											text="Form_Email"
										/>
										<Input
											name="customerPhone"
											text="Form_Phone"
										/>
										<Input
											name="customerStreet"
											text="Form_Street"
											class="small"
										/>
										<Input
											name="customerHouseNumber"
											text="Form_StreetNr"
										/>
										<Input
											name="customerCity"
											text="Form_Place"
											class="medium"
										/>
										<Input
											name="customerZipCode"
											text="Form_Cap"
										/>
										<Select
											name="customerCountryId"
											placeholder={t('Form_Country')}
											options={prepareCountriesForSelect(countryState.countries)}
											searchable={true}
										/>
									</div>
								</SimpleBar>
							</div>
							<div className="buttons">
								<Button
									text="Configuration_CreateProject"
									class="btn-primary"
									type="submit"
								/>
							</div>
						</div>
					</Form>
				</div>
			}
		</div>
	);
}

ProjectOverlay.defaultProps = {
	isOpen: false,
	onClose: () => void(0),
};

export default ProjectOverlay;
