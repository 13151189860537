import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import './index.scss';

function DotMenuItem(props) {
	return (
		<div className="item d-flex align-items-center pointer" onClick={props.onClick}>
			<Icon
				icon={props.icon}
				width={props.width}
				height={props.height}
				color={props.color}
			/>
			<div className="text"><TranslationTag tag={props.text} /></div>
		</div>
	);
}

DotMenuItem.defaultProps = {
	icon: '',
	text: '',
	width: 19,
	height: 19,
	color: 'currentColor',
	onClick: () => void(0),
};

export default DotMenuItem;
