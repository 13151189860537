import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Input from 'components/forms/input';
import Separator from 'components/forms/separator';
import Textarea from 'components/forms/textarea';
import Select from 'components/forms/select';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes, Errors, Genders } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { prepareCountriesForSelect } from 'utils/objects';
import { useCountryApi } from 'hooks/useCountryApi';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useState } from 'react';
import * as Yup from 'yup';
import './style.scss';

function FolderCreate() {
	const history = useHistory();
	const { t } = useTranslation('translations');
	const { createArchiveFolder } = useArchiveApi();
	const { state: countryState } = useCountryApi();
	const [isLoading, setIsLoading] = useState(false);
	
	const validationSchema = Yup.object({
		folderName: Yup.string().required(t(Errors.Required)),
	});
	
	const initialValues = {
		folderName: '',
		folderNumber: '',
		street: '',
		houseNumber: '',
		city: '',
		zipCode: '',
		countryId: '',
		comment: '',
		customerGender: '',
		customerFirstName: '',
		customerSecondName: '',
		customerCompany: '',
		customerEmail: '',
		customerPhone: '',
		customerStreet: '',
		customerHouseNumber: '',
		customerCity: '',
		customerZipCode: '',
		customerCountryId: '',
	};
	
	const createFolder = async (values) => {
		setIsLoading(true);
		await createArchiveFolder({
			...values,
			countryId: values.countryId.value ?? 0,
			customerCountryId: values.customerCountryId.value ?? 0,
			customerGender: values.customerGender.value ?? 0,
		});
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	return (
		<div className="folder-create">
			<Overlay
				headline="Archive_CreateFolderHeadline"
				text="Archive_CreateFolderText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => createFolder(values)}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<div className="field-container">
						<Separator
							text="CreateProject_Headline1"
						/>
						<Input
							name="folderName"
							text="Form_ProjectName"
						/>
						<Separator
							text="CreateProject_Headline2"
						/>
						<Input
							name="folderNumber"
							text="Form_ProjectNumber"
						/>
						<Input
							name="street"
							text="Form_Street"
							class="small"
						/>
						<Input
							name="houseNumber"
							text="Form_StreetNr"
						/>
						<Input
							name="city"
							text="Form_Place"
							class="medium"
						/>
						<Input
							name="zipCode"
							text="Form_Cap"
						/>
						<Select
							name="countryId"
							placeholder={t('Form_Country')}
							options={prepareCountriesForSelect(countryState.countries)}
							searchable={true}
						/>
						<Textarea
							name="comment"
							placeholder={t('Form_Comment')}
						/>
						<Separator
							text="CreateProject_Headline3"
						/>
						<Select
							name="customerGender"
							placeholder={t('Form_Salutation')}
							options={Genders(t)}
						/>
						<Input
							name="customerFirstName"
							text="Form_Firstname"
							class="medium"
						/>
						<Input
							name="customerSecondName"
							text="Form_Lastname"
						/>
						<Input
							name="customerCompany"
							text="Form_Companyname"
						/>
						<Input
							name="customerEmail"
							text="Form_Email"
						/>
						<Input
							name="customerPhone"
							text="Form_Phone"
						/>
						<Input
							name="customerStreet"
							text="Form_Street"
							class="small"
						/>
						<Input
							name="customerHouseNumber"
							text="Form_StreetNr"
						/>
						<Input
							name="customerCity"
							text="Form_Place"
							class="medium"
						/>
						<Input
							name="customerZipCode"
							text="Form_Cap"
						/>
						<Select
							name="customerCountryId"
							placeholder={t('Form_Country')}
							options={prepareCountriesForSelect(countryState.countries)}
							searchable={true}
						/>
					</div>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_CreateFolderSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_CreateFolderAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default FolderCreate;