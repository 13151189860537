import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import './index.scss';

function Password(props) {
	const [focused, setFocused] = useState(false);
	const [type, setType] = useState('password');
	return (
		<Field name={props.name}>
			{({ field, meta }) => (
				<div className={'field password type-' + type + (focused || field.value !== '' ? ' has-value' : '') + ( meta.error ? ' error' : '') + `${props.class ? ' ' + props.class : ''}`}>
					<label htmlFor={props.name}><TranslationTag tag={props.text} /></label>
					<input
						type={type}
						value={props.value}
						{...field}
						onFocus={(e) => setFocused(true)}
						onBlur={(e) => {
							setFocused(false);
							field.onBlur(e);
						}}
					/>
					<div className="icon-container" onClick={() => setType(type === 'password' ? 'text' : 'password')}>
						<Icon
							icon={type === 'password' ? 'invisible' : 'visible'}
							width="24"
							height="22"
						/>
					</div>
					{ meta.error &&
						<div>
							<div className="error-text"><ErrorMessage name={props.name} /></div>
						</div>
					}
				</div>
			)}
		</Field>
	);
}

Password.defaultProps = {
	name: '',
	text: '',
	value: '',
};

export default Password;
