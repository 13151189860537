import { Field } from 'formik';
import TranslationTag from 'utils/translations';
import Icon from 'components/core/icon';
import { useTranslation } from 'react-i18next';
import './index.scss';

const RadioButtonWrapper = ({ headline, text, useInForm = true, onChange, submitOnChange= false, value, translate = true, ...props }) => {
	if (typeof value !== 'undefined' && value !== null) {
		value += ''; // cast to string
	}
	
	if (!useInForm) {
		return (
			<RadioButton
				{...props}
				headline={headline}
				value={value}
				onChange={onChange}
				translate={translate}
			/>
		);
	}

	return (
		<Field name={props.name} type="radio" value={value}>
			{({ field, form, meta }) => {
				return (
					<RadioButton
						{...props}
						headline={headline}
						text={text}
						field={field}
						form={form}
						meta={meta}
						translate={translate}
						onChange={(e) => {
							if (onChange && onChange(e, field, form, meta) === false) {
								return;
							}
							if (submitOnChange) {
								form.submitForm();
							}
							field.onChange(e);
						}}
					/>
				);
			}}
		</Field>
	);
};

const RadioButton = ({ className, style, disabled, headline, text, onChange, field = {}, form = {}, meta = {}, translate, tooltipText, ...props }) => {
	const { t } = useTranslation('translations');
	let translatedHeadline = headline;
	if (translate) {
		translatedHeadline = <TranslationTag tag={headline} />;
	}

	return (
		<div className={'radio-button' + (className ? ' '  + className : '') + ( meta.error ? ' error' : '')} style={style}>
			<label className={`label${disabled ? ' disabled' : ''}`}>
				<input
					{...props}
					{...field}
					type="radio"
					onChange={onChange}
				/>
				<span className="icon-container"></span>
				<span className="text">
					<span className="t2">{translatedHeadline}</span>
					{ text &&
						<TranslationTag tag={text} />
					}
				</span>
				{ t(tooltipText) &&
					<div className="tooltip-container">
						<div className="info-icon">
							<Icon
								icon="info"
								width="4"
								height="12"
								color="#FFFFFF"
							/>
						</div>
						<div className="line"></div>
						<div className="info-content">
							<TranslationTag tag={tooltipText} />
						</div>
					</div>
				}
			</label>
		</div>
	);
};

RadioButtonWrapper.defaultProps = {
	className: '',
	headline: '',
	text: '',
	id: '',
	name: '',
	submitOnChange: false,
	tooltipText: '',
};

export default RadioButtonWrapper;