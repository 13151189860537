import { useTranslation } from 'react-i18next';
import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Password from 'components/forms/password';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Errors, Routes } from 'utils/constants';
import './style.scss';

function PasswordRecovery() {
	const { recoverPassword } = useAuth();
	const { t } = useTranslation('translations');
	const history = useHistory();
	const authToken = window.location.pathname.split('/').pop();
	const validationSchema = Yup.object({
		password: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter)),
		passwordCheck: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter))
			.oneOf([Yup.ref('password'), null], t(Errors.PasswordMatch)),
	});
	
	const handleSubmit = async (values) => {
		await recoverPassword(authToken, values.password);
		history.push(Routes.login.index);
	};
	
	if (authToken === '' || authToken === 'passwordrecovery') {
		history.push(Routes.login.index);
	}
	
	return (
		<div className="password-recovery">
			<Overlay
				headline="PasswordRecovery_Headline"
				text="PasswordRecovery_Text"
			>
				<Form
					initialValues={{
						password: '',
						passwordCheck: '',
					}}
					validationSchema={validationSchema}
					onSubmit={(values) => handleSubmit(values)}
				>
					<Password
						name="password"
						text="Form_NewPassword"
					/>
					<Password
						name="passwordCheck"
						text="Form_PasswordRepeat"
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="PasswordRecovery_ChangePassword"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default PasswordRecovery;