import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

const LoadingState = {
	Undefined: 0,
	Loading: 1,
	Loaded: 2,
};

function DoubleOptIn() {
	const { signupAuthToken } = useParams();
	const history = useHistory();
	const { confirmSignUp } = useAuth();
	const [loadingState, setLoadingState] = useState(LoadingState.Undefined);
	
	useEffect(() => {
		const load = async () => {
			try {
				await confirmSignUp(signupAuthToken);
				setLoadingState(LoadingState.Loaded);
			} catch (error) {
				console.log('error');
				history.push(Routes.login.index);
			}
		};
		
		if (loadingState === LoadingState.Undefined) {
			setLoadingState(LoadingState.Loading);
			load();
		}
	}, [loadingState, confirmSignUp, signupAuthToken,history]);
	
	if (loadingState !== LoadingState.Loaded) {
		return null;
	} else {
		return <Redirect to={Routes.login.index + '?doubleOptIn=true'} />;
	}
}

export default DoubleOptIn;