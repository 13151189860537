import Button from 'components/core/button';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Checkbox from 'components/forms/checkbox';
import Separator from 'components/forms/separator';
import Textarea from 'components/forms/textarea';
import SimpleBar from 'simplebar-react';
import TranslationTag from 'utils/translations';
import { Errors, Routes, StaticUrls } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function ReportToolOverlay (props) {
	const { state, reportError } = useConfiguratorApi();
	const { i18n, t } = useTranslation('translations');
	const { user } = useAuth();
	const history = useHistory();
	let userAgent = navigator.userAgent;
	let browserName;
	if (userAgent.match(/chrome|chromium|crios/i)) {
		browserName = 'chrome';
	} else if(userAgent.match(/firefox|fxios/i)) {
		browserName = 'firefox';
	} else if(userAgent.match(/safari/i)) {
		browserName = 'safari';
	} else if (userAgent.match(/opr\//i)) {
		browserName = 'opera';
	} else if (userAgent.match(/edg/i)) {
		browserName = 'edge';
	} else {
		browserName = 'No browser detection';
	}
	
	const validationSchema = Yup.object({
		username: Yup.string().email(t(Errors.EMail)).required(t(Errors.Required)),
		message: Yup.string().required(t(Errors.Required)),
		privacy: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
	});
	
	const handleSubmit = async (values) => {
		let questions = state.questions.map(function(question) {
			return {
				id: question.identifier,
				answerText: question.answerText,
			};
		});
		
		let debugInfo = {
			Configuration: {
				page: state.currentPage,
				configId: state.configId,
				currentQuestionId: state.currentQuestionId,
				questions: questions,
				projectId: state.projectId,
			},
			User: {
				id: user ? user.id: null,
				firstName: user ? user.firstName: null,
				lastName: user ? user.lastName: null,
			},
			Browser: browserName,
			System: window.navigator.platform,
			Language: i18n.language,
			Url: window.location.href,
			Screen: window.innerWidth + 'x' + window.innerHeight,
		};
		let properties = {
			...values,
			clickstream: JSON.stringify(debugInfo),
		};
		await reportError(properties);
		props.onClose();
		history.push(Routes.reportErrorSuccess);
	};
	
	return (
		<>
			{ props.isOpen &&
				<div className="report-tool-overlay">
					<div className="bg"></div>
					<div className="content">
						<div className="intro-container">
							<div className="t2 title">
								<TranslationTag tag="ReportTool_Headline" />
								<div className="close-button">
									<Button
										onClick={() => props.onClose()}
										icon="close"
										width="14"
										height="14"
									/>
								</div>
							</div>
						</div>
						<div className="content-container">
							<SimpleBar style={{ maxHeight: 'calc(100vh - 325px)' }} autoHide={false}>
								<div className="inner-content">
									<Form
										initialValues={{
											username: user ? user.eMail : '',
											message: '',
											privacy: false,
										}}
										validationSchema={validationSchema}
										onSubmit={handleSubmit}
									>
										<Separator
											text="ReportTool_Headline2"
										/>
										<Input
											name="username"
											text="Form_EmailLong"
											type="text"
										/>
										<Textarea
											name="message"
											placeholder={t('Form_Comment')}
										/>
										<Checkbox
											name="privacy"
											id="privacy"
											text="ReportTool_PrivacyText"
											textLink={StaticUrls('privacy2', '', i18n.language)}
										/>
										<Button
											class="btn-primary"
											type="submit"
											text="ReportTool_Send"
										/>
									</Form>
								</div>
							</SimpleBar>
						</div>
					</div>
				</div>
			}
		</>
	);
}

export default ReportToolOverlay;
