import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import './style.scss';

function OrderSuccess() {
	const history = useHistory();
	const { state } = useConfiguratorApi();
	const isArchive = (history.location.state && history.location.state.projectId);
	const projectId = isArchive ? history.location.state.projectId : state.projectId;
	
	if (!projectId) {
		history.push(isArchive ? Routes.profile.archive.index : Routes.profile.data.index);
	}
	
	return (
		<div className="order-success">
			<Overlay
				headline="Order_ThanksHeadline"
				subHeadline="Order_ThanksReferenceNumber"
				subHeadlineAfter={projectId}
				text="Order_ThanksText"
				backLink={isArchive ? Routes.profile.archive.index : Routes.profile.data.index}
			>
				<Button
					class="btn-primary"
					text={isArchive ? 'Order_VisitArchive' : 'Order_VisitProfile'}
					onClick={() => history.push(isArchive ? Routes.profile.archive.index : Routes.profile.data.index)}
				/>
			</Overlay>
		</div>
	);
}

export default OrderSuccess;