import Icon from 'components/core/icon';
import { Fragment } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { SubElementViewWidths } from 'utils/constants';
import TranslationTag from 'utils/translations';
import CN from './images/CN.png';
import CO500 from './images/CO500.png';
import CO600 from './images/CO600.png';
import DA400 from './images/DA400.png';
import DA520 from './images/DA520.png';
import ECLIPSE from './images/ECLIPSE.png';
import ECLIPSEGlow from './images/ECLIPSE_glow.png';
import FSystemM from './images/F-M.png';
import FSystemS from './images/F-S.png';
import FSystemXS from './images/F-XS.png';
import FA1070 from './images/FA1070.png';
import FA410W from './images/FA410W.png';
import FA770 from './images/FA770.png';
import FN1000B from './images/FN1000-B.png';
import FN1000 from './images/FN1000.png';
import FO420 from './images/FO420.png';
import FO460 from './images/FO460.png';
import FO600 from './images/FO600.png';
import IR from './images/IR.png';
import Lense16 from './images/lenses/lense_16.png';
import Lense16Active from './images/lenses/lense_16_active.png';
import Lense24 from './images/lenses/lense_24.png';
import Lense24Active from './images/lenses/lense_24_active.png';
import Lense32 from './images/lenses/lense_32.png';
import Lense32Active from './images/lenses/lense_32_active.png';
import LenseEclipse from './images/lenses/lense_eclipse.png';
import LenseEclipseActive from './images/lenses/lense_eclipse_active.png';
import LenseEclipseHidden from './images/lenses/lense_eclipse_hidden.png';
import './index.scss';

function SubElement({ data : subElementData }) {
	const imageHandler = () => {
		switch (subElementData.familyName) {
			case 'FA':
				switch (subElementData.variant) {
					case 'FA1070':
						return FA1070;
					case 'FA410-W':
						return FA410W;
					default:
						return FA770;
				}
			case 'FN':
				switch (subElementData.variant) {
					case 'FN1000-B':
					case 'FN1300-B':
						return FN1000B;
					default:
						return FN1000;
				}
			case 'CO':
				switch (subElementData.variant) {
					case 'CO500':
						return CO500;
					default:
						return CO600;
				}
			case 'DA':
				switch (subElementData.variant) {
					case 'DA400':
						return DA400;
					default:
						return DA520;
				}
			case 'SM':
			case 'AM':
				return CO600;
			case 'CN':
				return CN;
			case 'ECLIPSE':
				if (subElementData.subElements[4].numberOfLed > 0) {
					return ECLIPSEGlow;
				} else {
					return ECLIPSE;
				}
			case 'F-System':
				switch (subElementData.variant) {
					case 'F-System XS':
						return FSystemXS;
					case 'F-System M':
						return FSystemM;
					default:
						return FSystemS;
				}
			case 'IR':
				return IR;
			default: // FO
				switch (subElementData.variant) {
					case 'FO460':
						return FO460;
					case 'FO600':
						return FO600;
					default:
						return FO420;
				}
		}
	};
	
	const lenseHandler = (subelement) => {
		switch (subElementData.familyName) {
			case 'CN':
				switch (subelement.numberOfLed) {
					case 24:
						return subelement.active ? Lense24Active : Lense24;
					case 32:
						return subelement.active ? Lense32Active : Lense32;
					default:
						return subelement.active ? Lense16Active : Lense16;
				}
			case 'ECLIPSE':
				return subelement.active ? LenseEclipseActive : LenseEclipse;
			default:
				return subelement.active ? Lense16Active : Lense16;
		}
	};
	
	const classHandler = () => {
		switch (subElementData.familyName) {
			case 'F-System':
				if (subElementData.variant === 'F-System M') {
					return ' f-system-m';
				} else {
					return '';
				}
			case 'AM':
			case 'SM':
			case 'DA':
			case 'CO':
				return ' co';
			case 'CN':
				if (subElementData.variant === 'CN600') {
					return ' cn cn600';
				} else {
					return ' cn';
				}
			case 'ECLIPSE':
				return ' eclipse';
			default:
				return '';
		}
	};
	
	const subElements = (logic = '') => {
		let data = subElementData.subElements.slice(0);
		if (subElementData.familyName === 'CN') {
			return data;
		}
		if (subElementData.familyName === 'ECLIPSE') {
			return data;
		}
		if (logic === 'bottom') {
			return data.slice(0, 1);
		} else if (logic === 'left') {
			return data.filter((value, index) => index % 2 === 1).reverse();
		} else if (logic === 'right') {
			return data.filter((value, index) => index % 2 !== 1).reverse();
		} else {
			return data.reverse();
		}
	};
	
	return (
		<div className={'sub-element-view' + classHandler()} style={{ width: (SubElementViewWidths(subElementData ? subElementData : '') - 30) }}>
			<SimpleBar style={{ maxHeight: 'calc(100vh - 233px)' }} autoHide={false}>
				<div className="intro">
					<Icon
						icon="arrows"
					/>
					<div className="headline t2">
						<TranslationTag tag="Configuration_SubElementHeadline"/>
					</div>
					<div className="text">
						<TranslationTag tag="Configuration_SubElementText"/>
					</div>
				</div>
				<div className="product-view">
					<div className="image">
						<div className="lense-wrapper">
							<div className="items">
								{ subElements(subElementData.variant === 'F-System M' ? 'left' : '').map((subElement, index) => (
									<Item
										key={index}
										subElement={subElement}
										staticRotation={subElementData.familyName === 'CN'}
										familyName={subElementData.familyName}
									/>
								))}	
							</div>
							{ subElementData.variant === 'F-System M' &&
								<div className="items-right">
									{ subElements('right').map((subElement, index) => (
										<Item
											key={index}
											subElement={subElement}
											staticRotation={subElementData.familyName === 'CN'}
										/>
									))}
								</div>
							}
							<img src={imageHandler()} alt="" />
							<div className={'lenses' + ((subElementData.familyName === 'ECLIPSE' && subElementData.subElements[4].numberOfLed > 0) ? ' glow' : '')}>
								{ subElements().map((subElement, index) => (
									<div className={'lense' + (((subElementData.familyName === 'CN' || subElementData.familyName === 'ECLIPSE') && subElement.numberOfLed > 0) ? ' visible': '')} key={index}>
										<div className="lense-outer">
											<img src={lenseHandler(subElement)} alt="" />
											{ (subElementData.familyName === 'CN' || subElementData.familyName === 'ECLIPSE') &&
												<div className="pos-text">
													<TranslationTag tag="Configuration_SubElementPosition"/> { index + 1 }
												</div>
											}
											{subElementData.familyName === 'ECLIPSE' && subElement.numberOfLed <= 0 &&
												<img className="hiddenLense" src={LenseEclipseHidden} alt="" />
											}
										</div>
									</div>
								))}
							</div>
							{ (subElementData.variant === 'FN1000-B' || subElementData.variant === 'FN1300-B') &&
								<Fragment>
									<div className="items-bottom">
										<TranslationTag tag="Configuration_FNBLightElement2" />
									</div>
									<div className="lenses-bottom">
										{ subElements('bottom').map((subElement, index) => (
											<div className="lense" key={index}>
												<div className="lense-outer">
													<img src={Lense16} alt="" />
												</div>
											</div>
										))}
									</div>
								</Fragment>
							}
						</div>
						<div className="text">
							<TranslationTag tag={(subElementData.familyName === 'CN' || subElementData.familyName === 'ECLIPSE') ? 'Configuration_SubElementTextBottomCN' : 'Configuration_SubElementTextBottom'}/>
						</div>
					</div>
				</div>
			</SimpleBar>
		</div>
	);
}

function Item ({ subElement, staticRotation = false, familyName = '' }) {
	const rotationHandler = () => {
		let rotation = subElement.rotation;
		if (staticRotation) {
			switch (subElement.elementPos) {
				case 2:
					rotation = 90;
					break;
				case 3:
					rotation = 180;
					break;
				case 4:
					rotation = 270;
					break;
				default:
					rotation = 0;
					break;
			}
		}
		return rotation + '°';
	};
	
	return (
		<div className={'item t4' + (((staticRotation || familyName === 'ECLIPSE') && subElement.numberOfLed > 0) ? ' visible': '')} key={subElement.elementPos}>
			<div className="state">
				{ subElement.elementSet &&
					<Icon
						icon="check"
						width="11"
						height="8"
					/>
				}
			</div>
			<div className="position">
				{ subElement.elementPos }
			</div>
			<div className="distribution" title={ subElement.distribution }>
				{ subElement.distribution }
			</div>
			{ subElement.rotation  &&
				<div className={'rotation rotation-' + subElement.rotation + (subElement.rotation > 0 ? ' rotated': ' notrotated') + (subElement.distribution ? ' withbg': '')}>
					{ subElement.distribution &&
						<div>
							<Icon
								width="13"
								height="7"
								icon="subelement-arrow"
							/>
							<Icon
								width="12"
								height="14"
								icon="rotation"
							/>
							<span>{ rotationHandler() }</span>
						</div>
					}
				</div>
			}
		</div>
	);
}

export default SubElement;