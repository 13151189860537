import Headline from 'components/core/headline';
import Button from 'components/core/button';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Checkbox from 'components/forms/checkbox';
import RadioButton from 'components/forms/radio-button';
import DotMenu from 'components/navigation/dot-menu';
import DotMenuItem from 'components/navigation/dot-menu/item';
import Tabs from 'components/navigation/tabs';
import TopNavigation from 'components/navigation/top-navigation';
import ConfigNavigation from 'components/navigation/config-navigation';
import Note from 'components/core/note';
import Password from 'components/forms/password';
import Search from 'components/forms/search';
import Calendar from 'components/forms/calendar';
import Select from 'components/forms/select';
import Alert from 'components/core/alert';
import Card from 'components/core/card';
import Tags from 'components/core/tags';
import Slider from 'components/core/slider';
import Number from 'components/forms/number';
import { NavLink } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import * as Yup from 'yup';
import './style.scss';

function StyleGuide() {
	const validationSchema = Yup.object({
		firstName: Yup.string().required('Required'),
	});
	const validationSchemaPassword = Yup.object({
		password: Yup.string().required('Required'),
	});
	const validationSchemaSearch = Yup.object({
		search: Yup.string().required('Required'),
	});
	const validationSchemaCalendar = Yup.object({
		calendar: Yup.date().required('Required'),
	});
	const validationSchemaNumber = Yup.object({
		number: Yup.number().required('Required'),
	});
	const validationSchemaDropdown = Yup.object({
		dropdown: Yup.object().nullable().required('Required'),
	});
	return (
		<div className="styleguide center-content">
			<div className="block">
				<h1>Fonts:</h1>
				<div className="content d-flex">
					<div className="component">
						<div className="classname">h1</div>
						<div className="code">
							<Headline
								onClick={() => { alert('clicked'); }}
								type="h1"
								class="headline"
								text="Lorem ipsum"
							/>
							<div className="hint">
                fs: 32px <br></br>
                lh: 40px <br></br>
				fw: normal
							</div> 
						</div>
					</div>
					<div className="component">
						<div className="classname">h2</div>
						<div className="code">
							<Headline
								onClick={() => { alert('clicked'); }}
								type="h2"
								class="headline"
								text="Lorem ipsum"
							/>
							<div className="hint">
                fs: 18px <br></br>
                lh: 24px <br></br>
				fw: normal
							</div> 
						</div>
					</div>
					<div className="component">
						<div className="classname">h3</div>
						<div className="code">
							<Headline
								onClick={() => { alert('clicked'); }}
								type="h3"
								class="headline"
								text="Lorem ipsum"
							/>
							<div className="hint">
                fs: 18px <br></br>
                lh: 24px <br></br>
				fw: 300
							</div> 
						</div>
					</div>
					<div className="component">
						<div className="classname">h4</div>
						<div className="code">
							<Headline
								onClick={() => { alert('clicked'); }}
								type="h4"
								class="headline"
								text="Lorem ipsum"
							/>
							<div className="hint">
                fs: 14px <br></br>
                lh: 20px <br></br>
				fw: normal
							</div>
						</div>
					</div>
					<div className="component">
						<div className="classname">default fs</div>
						<div className="code">
							<Headline
								onClick={() => { alert('clicked'); }}
								type="div"
								class="headline"
								text="Lorem ipsum"
							/>
							<div className="hint">
                fs: 14px <br></br>
                lh: 20px <br></br>
				fw: 300
							</div> 
						</div>
					</div>
				</div>
			</div>
			<div className="block">
				<h1>Buttons:</h1>
				<div className="content d-flex">
					<div className="component">
						<div className="classname">.btn-primary</div>
						<div className="code">
							<Button
								onClick={() => { alert('clicked'); }}
								class="btn-primary"
								text="Login"
							/>
							<Button
								class="btn-primary disabled"
								text="Login"
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">.btn-secondary</div>
						<div className="code">
							<Button
								onClick={() => { alert('clicked'); }}
								class="btn-secondary"
								text="Login"
							/>
							<Button
								class="btn-secondary disabled"
								text="Login"
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">Close Button | Forward Button | Back Button</div>
						<div className="code">
							<Button
								onClick={() => { alert('clicked'); }}
								icon="close"
								width="13"
								height="13"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="block large-components">
				<h1>Forms:</h1>
				<div className="content d-flex">
					<div className="component">
						<div className="classname">input</div>
						<div className="code">
							<Form
								initialValues={{ firstName: '' }}
								validationSchema={validationSchema}
								onSubmit={(values) => console.log(values)}
							>
								<Input
									name="firstName"
									text="Vorname"
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="Submit"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">password</div>
						<div className="code">
							<Form
								initialValues={{ password: '' }}
								validationSchema={validationSchemaPassword}
								onSubmit={(values) => console.log(values)}
							>
								<Password
									name="password"
									text="Password"
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="Submit"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">search</div>
						<div className="code">
							<Form
								initialValues={{ search: '' }}
								validationSchema={validationSchemaSearch}
								onSubmit={(values) => alert('trigger search for:' + values.search)}
							>
								<Search
									name="search"
									text="Search for..."
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="Submit"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">calendar</div>
						<div className="code">
							<Form
								initialValues={{ calendar: '' }}
								validationSchema={validationSchemaCalendar}
								onSubmit={(values) => console.log(values)}
							>
								<Calendar
									name="calendar"
									text="mm || dd || yyyy"
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="Submit"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">number</div>
						<div className="code">
							<Form
								initialValues={{ number: 1 }}
								validationSchema={validationSchemaNumber}
								onSubmit={(values) => console.log(values)}
							>
								<Number
									name="number"
									text="Stückzahl"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">selectbox</div>
						<div className="code">
							<Form
								initialValues={{ dropdown: '' }}
								validationSchema={validationSchemaDropdown}
								onSubmit={(values) => console.log(values)}
							>
								<Select
									name="dropdown"
									placeholder="Anrede"
									options={[
										{ value: 'herr', label: 'Herr' },
										{ value: 'frau', label: 'Frau' },
										{ value: '...', label: '...' },
									]}
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="Submit"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">checkbox</div>
						<div className="code">
							<Form
								initialValues={{ lorem1: '' }}
								onSubmit={(values) => console.log(values)}
							>
								<Checkbox
									name="lorem1"
									text="Lorem ipsum"
									id="checkbox1"
								/>
								<Checkbox
									name="lorem2"
									text="Lorem ipsum"
									id="checkbox2"
								/>
							</Form>
						</div>
					</div>
					<div className="component">
						<div className="classname">radiobutton</div>
						<div className="code">
							<Form
								initialValues={{ lorem2: '1' }}
								enableReinitialize={true}
								onSubmit={(values) => console.log(values)}
							>
								<RadioButton
									name="lorem2"
									headline="Lorem ipsum"
									value="1"
									id="radio1"
								/>
								<RadioButton
									name="lorem2"
									headline="Lorem ipsum 2"
									value="2"
									id="radio2"
								/>
							</Form>
						</div>
					</div>
				</div>
			</div>
			<div className="block large-components">
				<h1>Navigation:</h1>
				<div className="content d-flex">
					<div className="component">
						<div className="classname">dotmenu</div>
						<div className="code">
							<DotMenu>
								<DotMenuItem
									icon="edit"
									text="Bearbeiten"
									width="20"
									height="20"
									onClick={() => alert('Clicked')}
								/>
								<DotMenuItem
									icon="duplicate"
									text="Duplizieren"
									width="20"
									height="20"
									onClick={() => alert('Clicked')}
								/>
								<DotMenuItem
									icon="compare"
									text="Vergleichen"
									width="20"
									height="20"
									onClick={() => alert('Clicked')}
								/>
								<DotMenuItem
									icon="delete"
									text="Löschen"
									width="20"
									height="20"
									onClick={() => alert('Clicked')}
								/>
							</DotMenu>
						</div>
					</div>
					<div className="component">
						<div className="classname">top navigation</div>
						<div className="code">
							<TopNavigation>
								<NavLink to={'/documents'} title="Verfügbare Dokumente">
									Verfügbare Dokumente
								</NavLink>
								<NavLink to={'/config-details'} title="Konfigurationsdetails">
									Konfigurationsdetails
								</NavLink>
								<NavLink to={'/help'} title="Hilfe">
									Hilfe
								</NavLink>
							</TopNavigation>
						</div>
					</div>
					<div className="component">
						<div className="classname">tab navigation</div>
						<div className="code">
							<Tabs class="v1">
								<div className="buttons">
									<NavLink className="btn btn-secondary" exact to={'/'} title="Tab 1">
										3D-View
									</NavLink>
									<NavLink className="btn btn-secondary" to={'/tab2'} title="Tab 2" >
										Photometric Plot
									</NavLink>
								</div>
								<div className="content">
									<Switch>
										<Route exact path="/">
											Tab 1 Content
										</Route>
										<Route path={'/tab2'}>
											Tab 2 Content
										</Route>
									</Switch>
								</div>
							</Tabs>
						</div>
					</div>
					<div className="component">
						<div className="classname">tab navigation</div>
						<div className="code">
							<Tabs class="v2 t2">
								<div className="buttons">
									<NavLink to={'/tab3'} title="Tab 1">
										3D-View
									</NavLink>
									<NavLink to={'/tab4'} title="Tab 2" >
										Photometric Plot
									</NavLink>
								</div>
								<div className="content">
									<Switch>
										<Route path={'/tab3'}>
											Tab 1 Content
										</Route>
										<Route path={'/tab4'}>
											Tab 2 Content
										</Route>
									</Switch>
								</div>
							</Tabs>
						</div>
					</div>
					<div className="component fullwidth">
						<div className="classname">config navigation</div>
						<div className="code">
							<ConfigNavigation>
								<NavLink to={'/product'} title="Produkt">
									Produkt
								</NavLink>
								<NavLink to={'/light'} title="Licht">
									Licht
								</NavLink>
								<NavLink to={'/control'} title="Steuerung">
									Steuerung
								</NavLink>
								<NavLink to={'/pole'} title="Masten">
									Masten
								</NavLink>
								<NavLink to={'/more-details'} title="Weitere Angaben">
									Weitere Angaben
								</NavLink>
								<NavLink to={'/project-details'} title="Projektdetails">
									Projektdetails
								</NavLink>
								<NavLink to={'/summary'} title="Zusammenfassung">
									Zusammenfassung
								</NavLink>
							</ConfigNavigation>
						</div>
					</div>
				</div>
			</div>
			<div className="block large-components">
				<h1>Specials:</h1>
				<div className="content d-flex">
					<div className="component">
						<div className="classname">note</div>
						<div className="code">
							<Note
								headline="Hinweis"
								text="Minimale Höhe: 1,5 m | Maximale Höhe: 6,0 m"
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">alert</div>
						<div className="code">
							<Alert
								text="Updates in configuration"
								count="7"
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">card v1</div>
						<div className="code">
							<Card
								version={1}
								headline="Vordefinierte Konfiguration 3"
								text="EL | 3.000K CRI >70 | AP07 | 32 LED | 5.000 Lumen | 30 W | DALI logarithmisch"
								infoText="Referenzpunkt für die Breite und Höhe der Punkte in der Visualisierung ist deine konfigurierte Lichtpunkthöhe."
								onClick={() => alert('clicked!')}
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">card v3</div>
						<div className="code">
							<Card
								version={1}
								headline="Vordefinierte Konfiguration 3"
								text="EL | 3.000K CRI >70 | AP07 | 32 LED | 5.000 Lumen | 30 W | DALI logarithmisch"
								onClick={() => alert('clicked!')}
								icon="arrow2"
								iconWidth="9"
								iconHeight="16"
								iconColor="#161B1A"
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">tags</div>
						<div className="code">
							<Tags
								onChange={(tags) => console.log(tags)}
							/>
						</div>
					</div>
					<div className="component">
						<div className="classname">slider</div>
						<div className="code">
							<Slider
								min={1700}
								max={5000}
								defaultValue={2700}
								unit="K"
								onChange={(value) => console.log(value)}
								step={100}
							/>
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
			<br />
		</div>
	);
}

export default StyleGuide;
