import Button from 'components/core/button';
import TranslationTag from 'utils/translations';
import SimpleBar from 'simplebar-react';
import { ClientData } from 'utils/constants';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function HelpOverlay(props) {
	return (
		<div>
			{ props.isOpen &&
				<div className="help-overlay">
					<div className="bg"></div>
					<div className="content">
						<div className="intro-container">
							<div className="text">
								<div className="t2 headline">
									<TranslationTag tag="Configuration_HelpOverlayHeadline" />
								</div>
							</div>
							<div className="close-button">
								<Button
									onClick={() => props.onClose()}
									icon="close"
									width="14"
									height="14"
								/>
							</div>
						</div>
						<div className="content-container">
							<SimpleBar style={{ maxHeight: 'calc(100vh - 200px)' }} autoHide={false}>
								<div className="inner-content">
									<div className="line">
										<div className="headline t2">
											<TranslationTag tag="Configuration_HelpOverlayEmail" />
										</div>
										<div className="text">
											<a href={'mailto:' + ClientData.EMail}>{ ClientData.EMail }</a>
										</div>
									</div>
									<div className="line">
										<div className="headline t2">
											<TranslationTag tag="Configuration_HelpOverlayPhone" />
										</div>
										<div className="text">
											<a href={'tel:' + ClientData.Phone}>{ ClientData.Phone }</a>
										</div>
									</div>
								</div>
							</SimpleBar>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

HelpOverlay.defaultProps = {
	isOpen: false,
	onClose: () => void(0),
};

export default HelpOverlay;
