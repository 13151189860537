import Card from 'components/core/card';
import Button from 'components/core/button';
import Downloads, { DownloadGroup, DownloadItem } from 'components/forms/downloads';
import { FileExtensions, Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import { useMemo, Fragment } from 'react';
import { makeSelectDocumentsByCategory, useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useAuth } from 'hooks/useAuth';
import { useTranslation  } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useConfig } from 'configuration/useConfig';
import { useLoading } from 'hooks/useLoading';

function Summary() {
	const { state, reset, download } = useConfiguratorApi();
	const { t } = useTranslation('translations');
	const history = useHistory();
	const { user } = useAuth();
	const [fileFormats, setFileFormats] = useState([]);
	const { config } = useConfig();
	const { startLoading, stopLoading } = useLoading();
	
	const selectDocuments = useMemo(() => makeSelectDocumentsByCategory(), []);
	const other = selectDocuments(state, 'other');
	
	const updateFileFormats = (formats) => {
		let tempDownloadFiles = [...fileFormats];
		formats.forEach((format) => {
			let foundIndex = -1;
			tempDownloadFiles.forEach((type, index) => {
				if (type.format === format.format) {
					foundIndex = index;
				}
			});
			if (foundIndex > -1) {
				tempDownloadFiles.splice(foundIndex, 1);
			} else {
				tempDownloadFiles.push(format);
			}
		});
		setFileFormats(tempDownloadFiles);
	};
	
	const handleSingleDownload = async (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = format;
		formatArray['type'] = types;
		fileFormats.push(formatArray);
		
		if (fileFormats) {
			startLoading();
			const response = await download(fileFormats);
			stopLoading();
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const handleDownload = async () => {
		if (fileFormats) {
			startLoading();
			const response = await download(fileFormats);
			stopLoading();
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};

	const pdfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'PDF';
		if (state.availableDownloads.PDF.filter(element => element === 'Standard').length > 0) {
			typeArray.push('Standard');
		}
		if (state.availableDownloads.PDF.filter(element => element === 'Customer').length > 0) {
			typeArray.push('Customer');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);

		return fileArray;
	};

	const fsfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'FSF';
		if (state.availableDownloads.FSF.filter(element => element === 'Document').length > 0) {
			typeArray.push('Document');
		}
		if (state.availableDownloads.FSF.filter(element => element === 'Image').length > 0) {
			typeArray.push('Image');
		}
		if (state.availableDownloads.FSF.filter(element => element === 'Other').length > 0) {
			typeArray.push('Other');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);
		return fileArray;
	};

	const getFileArray = (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		if (state.availableDownloads[format]) {
			formatArray['format'] = format;
			formatArray['type'] = types;
			fileFormats.push(formatArray);
		}
		return fileFormats;
	};
	
	return (
		<div className="type-summary">
			<h2><TranslationTag tag="Documents_Headline" /></h2>
			{ state.availableDownloads &&
				<Downloads
					onClick={() => handleDownload()}
					multiDownload={true}
					formats={fileFormats}
				>
					<DownloadGroup
						headline="Documents_ConfigurationData"
						accordion={true}
						open={true}
						onChange={() => updateFileFormats([...pdfFiles(), ...getFileArray('CSV', [''])])}
					>
						{ state.availableDownloads.PDF?.filter(element => element === 'Standard').length > 0 &&
							<DownloadItem
								new={true}
								title={t('Documents_ConfigSummary')}
								format={'(' + FileExtensions.pdf + ')'}
								onClick={() => handleSingleDownload('PDF', ['Standard'])}
							/>
						}
						{ state.availableDownloads.CSV &&
							<DownloadItem
								new={true}
								title={t('Documents_ConfigSummary')}
								format={'(' + FileExtensions.csv + ')'}
								onClick={() => handleSingleDownload('CSV', [''])}
							/>
						}
						{ state.availableDownloads.PDF?.filter(element => element === 'Customer').length > 0 &&
							<DownloadItem
								new={true}
								title={t('Documents_ConfigSummaryClient')}
								format={'(' + FileExtensions.pdf + ' | ' + t('Configuration_ClientPdfInfo') + ')'}
								onClick={() => history.push(Routes.configuration.customerPdf.index)}
							/>
						}
					</DownloadGroup>
					<DownloadGroup
						headline="Documents_PhotometricData"
						accordion={true}
						open={false}
						onChange={() => updateFileFormats([...getFileArray('LDT', ['']), ...getFileArray('IES', ['']), ...getFileArray('PLOT', [''])])}
					>
						{ state.availableDownloads.LDT &&
							<DownloadItem
								new={true}
								title={t('Documents_LDT')}
								format={'(' + FileExtensions.ldt + ')'}
								onClick={() => handleSingleDownload('LDT', [''])}
							/>
						}
						{ state.availableDownloads.IES &&
							<DownloadItem
								new={true}
								title={t('Documents_IES')}
								format={'(' + FileExtensions.ies + ')'}
								onClick={() => handleSingleDownload('IES', [''])}
							/>
						}
						{ state.availableDownloads.PLOT &&
							<DownloadItem
								new={true}
								title={t('Documents_PLOT')}
								format={'(' + FileExtensions.plot + ')'}
								onClick={() => handleSingleDownload('PLOT', [''])}
							/>
						}
					</DownloadGroup>
					{ state.availableDownloads.FSF &&
						<DownloadGroup
							headline="Documents_Datasheets"
							accordion={true}
							onChange={() => updateFileFormats([...fsfFiles()])}
						>
							{ other.map((item, index) => (
								state.availableDownloads.FSF.filter(element => element === item.key).length > 0 ? (
									<DownloadItem
										key={index}
										new={false}
										title={t(item.title)}
										format={'(' + item.format + ')'}
										onClick={() => handleSingleDownload('FSF', [item.key])}
									/>
								)
									:
									null
							))}
							{ state.availableDownloads.IPEA &&
								<DownloadItem
									title={t('Documents_IPEA')}
									format={'(' + FileExtensions.pdf + ')'}
									onClick={() => history.push(Routes.configuration.ipea.index)}
								/>
							}
						</DownloadGroup>
					}
				</Downloads>
			}
			{ (state.allConfigurationsOfUser && state.allConfigurationsOfUser.length > 1) &&
				<div className="downloads-products">
					<Button
						class="btn-secondary"
						text="Configuration_DownloadsProductsButton"
						onClick={() => history.push(Routes.configuration.downloads)}
					/>
				</div>
			}
			<div className="actions">
				<h2><TranslationTag tag="Configuration_OtherActions" /></h2>
				<div className="buttons">
					<Card
						headline="Configuration_EditConfiguration"
						text="Configuration_EditConfigurationText"
						version={4}
						onClick={() => history.push(Routes.configuration.restoreConfigurations)}
						icon="arrow2"
						iconWidth="9"
						iconHeight="16"
						iconColor="#161B1A"
						iconBefore="edit"
						iconBeforeWidth="32"
						iconBeforeHeight="32"
					/>
					{ user &&
						<Fragment>
							<Card
								headline="Configuration_SaveAsFavorit"
								text="Configuration_SaveAsFavoritInfo"
								version={4}
								onClick={() => history.push(Routes.configuration.favorites)}
								icon="arrow2"
								iconWidth="9"
								iconHeight="16"
								iconColor="#161B1A"
								iconBefore="star"
								iconBeforeWidth="32"
								iconBeforeHeight="30"
							/>
							{ user.allowedAddDefaultConfiguration &&
								<Card
									headline="Configuration_SaveAsDefaultConfiguration"
									text="Configuration_SaveAsDefaultConfigurationInfo"
									version={4}
									onClick={() => history.push(Routes.configuration.defaultsSelectConfiguration)}
									icon="arrow2"
									iconWidth="9"
									iconHeight="16"
									iconColor="#161B1A"
									iconBefore="default-config"
									iconBeforeWidth="32"
									iconBeforeHeight="32"
								/>
							}
							<Card
								headline="Configuration_Share"
								text="Configuration_ShareText"
								version={4}
								onClick={() => history.push(Routes.configuration.share.index)}
								icon="arrow2"
								iconWidth="9"
								iconHeight="16"
								iconColor="#161B1A"
								iconBefore="share"
								iconBeforeWidth="30"
								iconBeforeHeight="30"
							/>
						</Fragment>
					}
					<Card
						headline="Configuration_Assistance"
						text="Configuration_AssistanceInfo"
						version={4}
						onClick={() => history.push(Routes.configuration.assistance.index)}
						icon="arrow2"
						iconWidth="9"
						iconHeight="16"
						iconColor="#161B1A"
						iconBefore="assistance"
						iconBeforeWidth="27"
						iconBeforeHeight="27"
					/>
					<Card
						headline="Configuration_StartNewConfiguration"
						text="Configuration_StartNewConfigurationInfo"
						version={4}
						onClick={() => reset()}
						icon="arrow2"
						iconWidth="9"
						iconHeight="16"
						iconColor="#161B1A"
						iconBefore="plus"
						iconBeforeWidth="23"
						iconBeforeHeight="23"
					/>
					{ user &&
						<Card
							headline="Configuration_ViewInArchive"
							text="Configuration_ViewInArchiveInfo"
							version={4}
							onClick={() => history.push(Routes.profile.archive.index)}
							icon="arrow2"
							iconWidth="9"
							iconHeight="16"
							iconColor="#161B1A"
							iconBefore="view"
							iconBeforeWidth="35"
							iconBeforeHeight="26"
						/>
					}
				</div>
			</div>
			{ user &&
				<div className="order-button">
					<Button
						class="btn-primary"
						onClick={() => history.push(Routes.configuration.order.index)}
						text="Configuration_SendConfiguration"
					/>
				</div>
			}
		</div>
	);
}

export default Summary;