import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import Password from 'components/forms/password';
import TranslationTag from 'utils/translations';
import Link from 'components/core/link';
import UserImage from 'components/core/user-image';
import { Routes } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useCountryApi } from 'hooks/useCountryApi';
import { prepareCountriesForSelect } from 'utils/objects';
import { useHistory } from 'react-router-dom';

function PersonalData() {
	const { state } = useCountryApi();
	const { user, updateImage } = useAuth();
	const { t } = useTranslation('translations');
	const history = useHistory();
	
	const handleSubmit = async (values) => {
		updateImage(values.image);
	};
	
	const handleImageSubmit = async (values) => {
		updateImage(values.image);
	};
	
	if (state.countries.length <= 0) {
		return null;
	}
	
	return (
		<div className="personal-data column-container">
			<div className="column">
				<div className="headline t2">
					<TranslationTag tag="Profile_PersonData" />
				</div>
				<div className="content">
					<Form
						initialValues={{
							firstName: user.firstName,
							lastName: user.lastName,
							email: user.eMail,
							job: user.job,
							companyName: user.company,
							country: { 'value': state.countries.filter(country => country.countryId === user.countryId)[0].countryId, 'label': state.countries.filter(country => country.countryId === user.countryId)[0].countryName },
							image: null,
						}}
						onSubmit={handleImageSubmit}
					>
						<Input
							name="firstName"
							text="Form_Firstname"
							type="text"
							class="disabled"
						/>
						<Input
							name="lastName"
							text="Form_Lastname"
							type="text"
							class="disabled"
						/>
						<Input
							name="email"
							text="Form_Email"
							type="text"
							class="disabled"
						/>
						<Input
							name="job"
							text="Form_Job"
							type="text"
							class="disabled"
						/>
						<Input
							name="companyName"
							text="Form_Companyname"
							type="text"
							class="disabled"
						/>
						<Select
							name="country"
							placeholder={t('Form_Country')}
							options={prepareCountriesForSelect(state.countries)}
							defaultValue={{
								'value': state.countries.filter(country => country.countryId === user.countryId)[0].countryId,
								'label': state.countries.filter(country => country.countryId === user.countryId)[0].countryName,
							}}
							class="disabled"
						/>
						<div className="field image d-flex align-items-center">
							<UserImage
								large={true}
							/>
							<Input
								name="image"
								text="Profile_ChangeProfileImage"
								type="file"
								accept="image/png,image/jpeg"
								submitOnChange={true}
							/>
						</div>
					</Form>
				</div>
			</div>
			<div className="column">
				<div className="headline t2">
					<TranslationTag tag="Profile_LoginData" />
				</div>
				<div className="content">
					<Form
						initialValues={{
							username: user.userName,
							password: t('Form_Password'),
						}}
						onSubmit={handleSubmit}
						validateOnBlur={true}
					>
						<Input
							name="username"
							text="Form_EmailLong"
							type="text"
							class="disabled"
						/>
						<Password
							name="password"
							text="Form_Password"
							class="disabled"
						/>
						<Link
							text="Profile_ChangePassword"
							onClick={() => history.push(Routes.profile.data.changePassword)}
						/>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default PersonalData;