
export const prepareCountriesForSelect = (objects) => {
	return objects.map(function(obj) {
		return {
			...obj,
			value: obj.countryId,
			label: obj.countryName,
		};
	});
};

export const prepareUsersForSelect = (objects) => {
	return objects.map(function(obj) {
		return {
			...obj,
			value: obj.id,
			label: (obj.firstName || obj.lastName) ? (obj.firstName + ' ' + obj.lastName + ' <' + obj.userName + '>') : '<' + obj.userName + '>',
		};
	});
};