import Overlay from 'components/core/overlay';
import Password from 'components/forms/password';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import { useAuth } from 'hooks/useAuth';
import { Errors, Routes } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import './style.scss';

function ChangePassword() {
	const { t } = useTranslation('translations');
	const { updatePassword } = useAuth();
	const history = useHistory();
	const validationSchema = Yup.object({
		password1: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter)),
		password2: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter))
			.oneOf([Yup.ref('password1'), null], t(Errors.PasswordMatch)),
	});
	
	const handleSubmit = async (values) => {
		await updatePassword(values.password1);
		history.push(Routes.profile.data.index);
	};
	
	return (
		<div className="change-password">
			<Overlay
				headline="ChangePassword_Headline"
				text="ChangePassword_Text"
				backLink={Routes.profile.data.index}
			>
				<Form
					initialValues={{ password1: '', password2: '' }}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<Password
						name="password1"
						text="Profile_Password1"
					/>
					<Password
						name="password2"
						text="Profile_Password2"
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Profile_ChangePassword"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ChangePassword;