import Button from 'components/core/button';
import Number from 'components/forms/number';
import Form from 'components/forms/form';
import Select from 'components/forms/select';
import Chart from 'react-apexcharts';
import { useState } from 'react';
import { Errors, Times } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function StandAlone({ currentQuestion, onSubmit }) {
	const [startTime, setStartTime] = useState(currentQuestion.data.start.default);
	const [endTime, setEndTime] = useState(currentQuestion.data.end.default);
	const [remaining, setRemaining] = useState(currentQuestion.data.remaining.default);
	const { t } = useTranslation('translations');
	const validationSchema = Yup.object({
		remaining: Yup.number(t(Errors.Number)).typeError(t(Errors.Number)).min(currentQuestion.data.remaining.min, t(Errors.MinNumber).replace('{0}', currentQuestion.data.remaining.min)).max(currentQuestion.data.remaining.max, t(Errors.MaxNumber).replace('{0}', currentQuestion.data.remaining.max)).required(t(Errors.Required)),
	});
	
	const options = {
		stroke: {
			curve: 'stepline',
			width: 4,
		},
		chart: {
			toolbar: {
				show: false,
				tools: {
					download: false,
					selection: false,
					pan: false,
					zoom: false,
				},
			},
			selection: {
				enabled: false,
			},
			fontFamily: 'RiformaLight',
			offsetX: -17,
		},
		colors: [
			'#000000',
		],
		grid: {
			strokeDashArray: 5,
			borderColor: '#000000',
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: false,
				},
			},
		},
		markers: {
			size: 0,
		},
		legend: { show: false },
		dataLabels: {
			enabled: false,
		},
		tooltip: {
			enabled: false,
		},
		yaxis: {
			type: 'numeric',
			axisBorder: {
				show: true,
				color: '#000000',
				offsetY: -1,
				width: 0.6,
			},
			forceNiceScale: false,
			max: 100,
			min: 0,
			tickAmount: 5,
			labels: {
				formatter: function (value) {
					if (value === 100) {
						return value.toFixed(0) +'%';
					} else {
						return value;
					}
				},
				style: {
					fontSize: '14px',
					fontWeight: 300,
				},
				offsetX: -1,
				offsetY: 5,
			},
			axisTicks: {
				show: false,
			},
		},
		xaxis: {
			labels: {
				formatter: function (value) {
					if (parseInt(value) === 33) {
						return startTime;
					} else if (parseInt(value) === 66) {
						return endTime;
					} else {
						return '';
					}
				},
				rotate: 0,
				style: {
					fontSize: '14px',
					fontWeight: 300,
				},
			},
			axisBorder: {
				show: true,
				color: '#000000',
			},
			axisTicks: {
				show: false,
			},
			tickAmount: 3,
		},
	};
	
	const series = [
		{
			data: [{
				x: 0,
				y: 100,
			},{
				x: 33.333,
				y: remaining,
			},{
				x: 66.667,
				y: 100,
			},{
				x: 100,
				y: 100,
			}],
		},
	];
	
	return (
		<div className="stand-alone">
			<Form
				initialValues={{
					remaining: currentQuestion.answerId ? currentQuestion.answerId.Remaining : currentQuestion.data.remaining.default,
					start: { 'value': currentQuestion.answerId ? currentQuestion.answerId.Start : currentQuestion.data.start.default, 'label': currentQuestion.answerId ? currentQuestion.answerId.Start : currentQuestion.data.start.default },
					end: { 'value': currentQuestion.answerId ? currentQuestion.answerId.End : currentQuestion.data.end.default, 'label': currentQuestion.answerId ? currentQuestion.answerId.End : currentQuestion.data.end.default },
				}}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>
				<Number
					name="remaining"
					min={currentQuestion.data.remaining.min}
					max={currentQuestion.data.remaining.max}
					text={currentQuestion.data.remaining.headline}
					onValueChange={(value) => setRemaining(value)}
					adjustMin={false}
					adjustMax={false}
				/>
				<div className="select-boxes">
					<Select
						name="start"
						options={Times()}
						placeholder={currentQuestion.data.start.headline}
						hoverTitle={currentQuestion.data.start.headline}
						onValueChange={(time) => setStartTime(time.value)}
						defaultValue={{ 'value': currentQuestion.answerId ? currentQuestion.answerId.Start : currentQuestion.data.start.default, 'label': currentQuestion.answerId ? currentQuestion.answerId.Start : currentQuestion.data.start.default }}
					/>
					<Select
						name="end"
						options={Times()}
						placeholder={currentQuestion.data.end.headline}
						hoverTitle={currentQuestion.data.end.headline}
						onValueChange={(time) => setEndTime(time.value)}
						defaultValue={{ 'value': currentQuestion.answerId ? currentQuestion.answerId.End : currentQuestion.data.end.default, 'label': currentQuestion.answerId ? currentQuestion.answerId.End : currentQuestion.data.end.default }}
					/>
				</div>
				<div className="chart-container">
					<div className="intro-container">
						{ currentQuestion.data.graphicHeadline &&
							<div className="t2 headline">{ currentQuestion.data.graphicHeadline }</div>
						}
						{ currentQuestion.data.graphicText &&
							<div className="subheadline">{ currentQuestion.data.graphicText }</div>
						}
					</div>
					<div className="chart">
						<Chart
							options={options}
							series={series}
							type="line"
							height={250}
						/>
						<div className="time-label">{ t('Configuration_TimeChart') }</div>
					</div>
				</div>
				<div className="buttons">
					<Button
						text="Configuration_SaveChanges"
						class="btn-secondary"
						type="submit"
					/>
				</div>
			</Form>
		</div>
	);
}

StandAlone.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default StandAlone;