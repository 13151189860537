import Button from 'components/core/button';
import Project from '../project';
import SpinnerLoader from 'components/system/spinner-loader';
import TranslationTag from 'utils/translations';
import { useMemo, useState } from 'react';
import { ArchiveEntityType, makeSelectAllProjects, useArchiveApi } from 'hooks/useArchiveApi';

const ProjectList = ({ folderId = null, showLoading = false, projectsLoaded = false, folderView = false, isSearching = false, isSearch = false }) => {
	const { state: archiveState, hasNextPage, nextPage } = useArchiveApi();
	const selectAllProjects = useMemo(() => makeSelectAllProjects(), []);
	const projects = selectAllProjects(archiveState, folderId);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	
	const loadMore = async () => {
		setIsLoadingMore(true);
		await nextPage(ArchiveEntityType.Project);
		setIsLoadingMore(false);
	};
	
	return (
		<div className="projects">
			{ (!showLoading && !isSearching && projects) &&
				projects.map((project) => (
					<Project
						key={project.projectId}
						project={project}
					/>
				))
			}
			{ (projects && !projects.length && projectsLoaded && !showLoading && !isSearching && !folderView) &&
				<div className="no-results t2">
					{ isSearch ? 
						<TranslationTag tag="Archive_NoResults" />
						:
						<TranslationTag tag="Archive_NoProjects" />
					}
				</div>
			}
			<SpinnerLoader
				show={showLoading || isSearching || isLoadingMore}
			/>
			{ (hasNextPage(ArchiveEntityType.Project) && !isSearching) &&
				<div className="load-more">
					<Button
						onClick={() => loadMore()}
						text="Archive_LoadMoreConfigurations"
						class="btn-secondary"
					/>
				</div>
			}
		</div>
	);
};

export default ProjectList;