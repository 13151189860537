import { useCallback } from 'react';
import { apiClient } from 'services/api/client';

const HTTP_METHOD = {
	UNDEFINED: 0,
	GET: 1,
	POST: 2,
	PUT: 3,
	PATCH: 4,
	DELETE: 5,
};

function addAccessTokenToConfig(config = {}, accessToken) {
	if (!accessToken) {
		return config;
	}

	const c = {
		...config,
	};

	if (!c.headers) {
		c.headers = {};
	}

	if (!c.headers.RequestVerificationToken) {
		c.headers.RequestVerificationToken = accessToken;
	}

	return c;
}

export const useApiClient = (auth = {}) => {
	const { accessToken, logout } = auth;
	
	const handleError = useCallback(async (error) => {
		if (error.response && error.response.status === 401 && logout) {
			await logout();
			return;
		}
		// todo: other form of error handling or just bubble?
		throw error;
	}, [logout]);

	const callMethod = useCallback(async (url, method, { data, config, addAccessToken }) => {
		if (addAccessToken) {
			config = addAccessTokenToConfig(config, accessToken);
		}

		try {
			switch (method) {
				case HTTP_METHOD.GET:
					return await apiClient.get(url, config);
				case HTTP_METHOD.POST:
					return await apiClient.post(url, data, config);
				case HTTP_METHOD.PATCH:
					return await apiClient.patch(url, data, config);
				case HTTP_METHOD.PUT:
					return await apiClient.put(url, data, config);
				case HTTP_METHOD.DELETE:
					return await apiClient.delete(url, config);
				default:
					throw new Error(`Method '${method}' not implemented!`);
			}
		} catch (error) {
			await handleError(error);
		}
	}, [accessToken, handleError]);

	const get = useCallback(async (url, config = {}, addAccessToken = true) => {
		return await callMethod(url, HTTP_METHOD.GET, {
			config,
			addAccessToken,
		});
	}, [callMethod]);

	const post = useCallback(async (url, data, config = {}, addAccessToken = true) => {
		return await callMethod(url, HTTP_METHOD.POST, {
			data,
			config,
			addAccessToken,
		});
	}, [callMethod]);

	const patch = useCallback(async (url, data, config = {}, addAccessToken = true) => {
		return await callMethod(url, HTTP_METHOD.PATCH, {
			data,
			config,
			addAccessToken,
		});
	}, [callMethod]);

	const put = useCallback(async (url, data, config = {}, addAccessToken = true) => {
		return await callMethod(url, HTTP_METHOD.PUT, {
			data,
			config,
			addAccessToken,
		});
	}, [callMethod]);

	const deleteMethod = useCallback(async (url, config = {}, addAccessToken = true) => {
		return await callMethod(url, HTTP_METHOD.DELETE, {
			config,
			addAccessToken,
		});
	}, [callMethod]);

	return {
		get,
		post,
		put,
		patch,
		delete: deleteMethod,
	};
};
