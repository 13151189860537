import { useCallback } from 'react';
import { useImmerReducer } from 'use-immer';
import { createSelector } from 'reselect';
import qs from 'qs';
import { useApiClient } from 'hooks/useApiClient';
import { useStore } from 'store/useStore';

export const useArchiveApi = () => {
	const store = useStore();
	return store.archiveApi;
};

export const ArchiveEntityType = {
	Folder: 1,
	Project: 2,
	Configuration: 3,
};

const initialState = {
	folders: {
		pageSize: 0, // page size (0 = no page limit)
		pageNumber: 1, // current page number
		entities: {}, // object key = folder id
		ids: [], // array of folder ids
		loadedIds: {},
		pagination: {}, // object key = page number, value = array of element ids
		totalCount: null,
	},
	projects:  {
		pageSize: 0, // page size (0 = no page limit)
		pageNumber: 1, // current page number
		query: null,
		folderId: null, // current open folder
		entities: {}, // object key = project id
		entitiesByParent: {}, // object key = folder id
		ids: [], // array of project ids
		loadedIds: {},
		pagination: {}, // object key = page number, value = array of element ids
		totalCount: null,
	},
	configurations:  {
		pageSize: 0, // page size (0 = no page limit)
		pageNumber: 1, // current page number
		projectId: null, // current open project
		entities: {}, // object key = configuration id
		entitiesByParent: {}, // object key = project id
		ids: [], // array of configuration ids
		loadedIds: {},
		pagination: {}, // object key = page number, value = array of element ids
		totalCount: null,
	},
};

const ActionType = {
	INIT: 'init',
	SET_PAGE_VALUES: 'set-page-values',
	RESET: 'reset',
	FOLDERS_LOADED: 'folders-loaded',
	FOLDER_UPDATED: 'folder-updated',
	FOLDER_LOADED: 'folder-loaded',
	FOLDER_DELETED: 'folder-deleted',
	PROJECT_LOADED: 'project-loaded',
};

function reducer(draft, action) {
	switch (action.type) {
		case ActionType.INIT: {
			const storeFolders = {
				...initialState.folders,
			};
			const storeProjects = {
				...initialState.projects,
			};
			const storeConfigurations = {
				...initialState.configurations,
			};

			const { folders, projects, configurations } = action.payload;
			if (folders) {
				const { pageSize = 0, pageNumber = 1 } = folders;
				storeFolders.pageSize = pageSize;
				storeFolders.pageNumber = pageNumber;
			}

			if (projects) {
				const { pageSize = 0, pageNumber = 1 } = projects;
				storeProjects.pageSize = pageSize;
				storeProjects.pageNumber = pageNumber;
			}

			if (configurations) {
				const { pageSize = 0, pageNumber = 1 } = configurations;
				storeConfigurations.pageSize = pageSize;
				storeConfigurations.pageNumber = pageNumber;
			}
			return {
				...initialState,
				folders: storeFolders,
				projects: storeProjects,
				configurations: storeConfigurations,
			};
		}
		case ActionType.SET_PAGE_VALUES: {
			const { type, pageSize = null, pageNumber } = action.payload;
			switch (type) {
				case ArchiveEntityType.Folder:
					if (pageNumber > 0) {
						draft.folders.pageNumber = pageNumber;
					}
					if (pageSize !== null) {
						draft.folders.pageSize = pageSize;
					}
					break;
	
				case ArchiveEntityType.Project:
					if (pageNumber > 0) {
						draft.projects.pageNumber = pageNumber;
					}
					if (pageSize !== null) {
						draft.projects.pageSize = pageSize;
					}
					break;
			
				case ArchiveEntityType.Configuration:
					if (pageNumber > 0) {
						draft.configurations.pageNumber = pageNumber;
					}
					if (pageSize !== null) {
						draft.configurations.pageSize = pageSize;
					}
					break;
	
				default:
					throw new Error(`ArchiveEntityTypes '${type}' unknown!`);
			}
			break;
		}
		case ActionType.FOLDERS_LOADED: {
			const { data = {}, pageSize, pageNumber } = action.payload;
			const { folders, rowCount } = data;
			const draftFolders = draft.folders;
			mapToStore(draftFolders, folders, 'folderId', null, null, pageSize, pageNumber, rowCount);

			break;
		}
		case ActionType.FOLDER_UPDATED: {
			const { data = {}, folderId } = action.payload;
			if (!folderId) {
				return;
			}

			const existingFolder = draft.folders.entities[folderId];
			if (existingFolder) {
				draft.folders.entities[folderId] = {
					...existingFolder,
					...data,
				};
			}
			break;
		}
		case ActionType.FOLDER_LOADED: {
			const { data = {}, pageSize, pageNumber, folderId = null, query = null } = action.payload;
			const { projects, rowCount, ...rest } = data;

			if (folderId !== null) {
				// merge existing data with "detail"-data
				const oldData = draft.folders.entities[folderId] || {};
				draft.folders.entities[folderId] = {
					...oldData,
					...rest,
				};
				draft.folders.loadedIds[folderId] = true;
			}
			const draftProjects = draft.projects;
			mapToStore(draftProjects, projects, 'projectId', folderId, 'folderId', pageSize, pageNumber, rowCount, query);

			break;
		}
		case ActionType.FOLDER_DELETED: {
			const { folderId } = action.payload;
			if (draft.folders.entities[folderId]) {
				const { [folderId]: _, ...rest } = draft.folders.entities[folderId];
				draft.folders.entities = rest;
			}
			draft.folders.ids = draft.folders.ids.filter((id) => id !== folderId);
			if (draft.projects.folderId === folderId) {
				draft.projects.folderId = null; // clear projects? probably not required
			}

			break;
		}
		case ActionType.PROJECT_LOADED: {
			const { data = {}, projectId } = action.payload;
			const { configurations, ...rest } = data;

			if (projectId !== null) {
				// merge existing data with "detail"-data
				const oldData = draft.projects.entities[projectId] || {};
				draft.projects.entities[projectId] = {
					...oldData,
					...rest,
					configurations: configurations,
				};
				draft.projects.loadedIds[projectId] = true;
			}
			const draftConfigurations = draft.configurations;
			mapToStore(draftConfigurations, configurations, 'configurationId', projectId, 'projectId');

			break;
		}
		case ActionType.RESET:
			return initialState;
		default:
			throw new Error(`Action '${action.type}' unknown!`);
	}
}

function mapToStore(store, elements, elementIdKey, parentId, parentIdKey, pageSize = 0, pageNumber = 1, totalCount = null, query = null) {
	// reset store if parentId has changed
	if ((parentIdKey !== null && store[parentIdKey] !== parentId) || store.query !== query) {
		store[parentIdKey] = parentId;
		store.entities = {};
		store.ids = [];
		store.pagination = {};
		store.totalCount = null;
		store.query = query;
	}

	if (totalCount !== null) {
		store.totalCount = totalCount;
	}

	const currentIds = [];
	if (elements) {
		const mappedElements = {};
		const elementsByParent = {};

		elements.forEach((element) => {
			const elementId = element[elementIdKey];
			const mappedElement = {
				...element,
			};
			mappedElements[elementId] = mappedElement;
			if (parentIdKey !== null) {
				mappedElement[parentIdKey] = parentId;
			}
			if (parentId !== null) {
				elementsByParent[parentId] = mappedElement;
			}
			currentIds.push(elementId);
			if (store.ids.indexOf(elementId) === -1) {
				store.ids.push(elementId);
			}
		});

		store.entities = {
			...store.entities,
			...mappedElements,
		};

		if (parentId !== null) {
			store.entitiesByParent = {
				...store.entitiesByParent,
				...elementsByParent,
			};
		}
	}

	if (pageSize > 0 && pageNumber > 0) {
		store.pagination[pageNumber] = currentIds;
		store.pageNumber = pageNumber;
		store.pageSize = pageSize; // actually shouldn't get changed with this !!
	}
}

export const useArchiveApiState = (auth) => {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const apiClient = useApiClient(auth);

	const init = useCallback((config) => {
		dispatch({ type: ActionType.RESET });
		dispatch({ type: ActionType.INIT, payload: config });
	}, [dispatch]);

	const createArchiveFolder = useCallback(async (folderProperties, projectId = 0) => {
		const data = qs.stringify(folderProperties);
		
		try {
			const response = await apiClient.post('/archive/folder/' + projectId, data);
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const duplicateArchiveFolder = useCallback(async (folderProperties, folderId) => {
		const data = qs.stringify(folderProperties);
		
		try {
			const response = await apiClient.post('/archive/folder/' + folderId + '/duplicate', data);
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);

	const updateArchiveFolder = useCallback(async (folderId, folderProperties) => {
		if (!folderId) {
			return;
		}

		const data = qs.stringify(folderProperties);
		
		try {
			const response = await apiClient.put('/archive/folder/' + folderId, data);
			dispatch({ type: ActionType.FOLDER_UPDATED, payload: {
				folderId,
				data: folderProperties,
			}});
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch]);

	const loadArchiveFolders = useCallback(async ({ pageSize = state.folders.pageSize, pageNumber = state.folders.pageNumber } = {}) => {
		const options = { params: {}};
		if (pageSize > 0) {
			options.params.limit = pageSize;
			options.params.skip = pageSize * (pageNumber - 1);
		}

		try {
			const response = await apiClient.get('/archive/folder', options, null, false);
			dispatch({ type: ActionType.FOLDERS_LOADED, payload: {
				data: response.data,
				pageSize,
				pageNumber,
			}});
			return response.data.folders;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch, state.folders.pageNumber, state.folders.pageSize]);

	const loadArchiveFolderById = useCallback(async (folderId, { pageSize = state.projects.pageSize, pageNumber = null } = {}) => {
		if (!folderId) {
			return null;
		}

		if (pageNumber === null) {
			if (folderId !== state.projects.folderId) {
				pageNumber = 1;
			} else {
				pageNumber = state.projects.pageNumber;
			}
		}

		const options = { params: {}};
		if (pageSize > 0) {
			options.params.limit = pageSize;
			options.params.skip = pageSize * (pageNumber - 1);
		}
		
		try {
			const response = await apiClient.get('/archive/folder/' + folderId, options, null, false);
			dispatch({ type: ActionType.FOLDER_LOADED, payload: {
				data: response.data,
				folderId,
				pageSize,
				pageNumber,
			}});
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch, state.projects.folderId, state.projects.pageNumber, state.projects.pageSize]);

	const loadProjectsWithoutFolder = useCallback(async ({ pageSize = state.projects.pageSize, pageNumber = null, query = null } = {}) => {
		if (query === '') {
			query = null;
		}

		if (pageNumber === null) {
			if (state.projects.folderId !== null) {
				pageNumber = 1;
			} else {
				pageNumber = state.projects.pageNumber;
			}

			if (query !== state.projects.query) {
				pageNumber = 1;
			}
		}

		const options = { params: {}};
		if (pageSize > 0) {
			options.params.limit = pageSize;
			options.params.skip = pageSize * (pageNumber - 1);
		}
		
		if (query) {
			options.params.query = query;
		}
		
		try {
			const response = await apiClient.get('/archive/project/', options, null, false);
			dispatch({ type: ActionType.FOLDER_LOADED, payload: {
				data: response.data,
				pageSize,
				pageNumber,
				query,
			}});
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch, state.projects.folderId, state.projects.pageNumber, state.projects.pageSize, state.projects.query]);
	
	const searchProjects = useCallback(async (query, params) => {
		if (query === '') {
			return null;
		}
		
		// reset loaded configurations
		dispatch({ type: ActionType.PROJECT_LOADED, payload: {
			projectId: null,
		}});
		
		const response = await loadProjectsWithoutFolder({
			...params,
			query,
		});

		return response;
	}, [dispatch, loadProjectsWithoutFolder]);

	const loadProjectById = useCallback(async (projectId) => {
		if (!projectId) {
			return null;
		}
		
		try {
			const response = await apiClient.get('/archive/project/' + projectId, null, false);
			dispatch({ type: ActionType.PROJECT_LOADED, payload: { data: response.data, projectId }});
			return response.data.configurations;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch]);
	
	const updateProject = useCallback(async (projectId) => {
		if (!projectId) {
			return null;
		}
		
		const data = qs.stringify({
			projectId,
		});
		
		try {
			const response = await apiClient.post('/archive/project/' + projectId + '/update', data);
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);

	const reload = useCallback(async (reloadFolders = true) => {
		const config = {
			folders: {
				pageNumber: state.folders.pageNumber,
				pageSize: state.folders.pageSize,
			},
			projects: {
				pageNumber: 1,
				pageSize: state.projects.pageSize,
			},
			configurations: {
				pageNumber: 1,
				pageSize: state.configurations.pageSize,
			},
		};
		dispatch({ type: ActionType.RESET });
		dispatch({ type: ActionType.INIT, payload: config });
		if (reloadFolders) {
			return await loadArchiveFolders(config.folders);
		}
		return await loadProjectsWithoutFolder(config.projects);
	}, [dispatch, loadArchiveFolders, loadProjectsWithoutFolder, state.configurations.pageSize, state.folders.pageNumber, state.folders.pageSize, state.projects.pageSize]);

	const assignProjectToFolder = useCallback(async (projectId, folderId) => {
		if (!projectId || !folderId) {
			return;
		}

		const data = qs.stringify({
			folderId,
		});
		
		try {
			const response = await apiClient.post('/archive/project/' + projectId, data);
			// dispatch({ type: ActionType.PROJECT_LOADED, payload: { data: response.data, projectId }});
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const removeArchiveProject = useCallback(async (projectId) => {
		if (!projectId) {
			return;
		}
		
		try {
			await apiClient.delete('/archive/project/' + projectId);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const updatePriceArchiveProject = useCallback(async (projectId, priceType = '', discount = '') => {
		if (!projectId) {
			return;
		}
		
		const data = qs.stringify({
			priceType,
			discount,
		});
		
		try {
			await apiClient.post('/archive/project/' + projectId + '/duplicate', data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const shareProject = useCallback(async (projectId, userId) => {
		if (!projectId || !userId) {
			return;
		}

		const data = qs.stringify({
			receivingUserId: userId,
		});
		
		try {
			const response = await apiClient.post(`/archive/project/${projectId}/share`, data);
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);

	const deleteArchiveFolder = useCallback(async (folderId) => {
		if (!folderId) {
			return;
		}

		try {
			await apiClient.delete('/archive/folder/' + folderId);
			dispatch({ type: ActionType.FOLDER_DELETED, payload: { folderId }});
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch]);

	// note: should be probably in separate hook (users hook for example)
	const loadAllUsers = useCallback(async () => {
		try {
			const response = await apiClient.get('/user/all');
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const loadProjectRevisions = useCallback(async (projectId) => {
		if (!projectId) {
			return;
		}
		
		try {
			const response = await apiClient.get('/archive/project/' + projectId + '/revision');
			return response.data.projects;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);

	const goToPage = useCallback(async (type, pageNumber) => {
		switch (type) {
			case ArchiveEntityType.Folder:
				const paginationKey = pageNumber;
				if (state.folders.pagination[paginationKey]) {
					dispatch({ type: ActionType.SET_PAGE_VALUES, payload: {
						type,
						pageNumber,
					}});
				} else {
					await loadArchiveFolders({
						pageNumber,
						pageSize: state.folders.pageSize,
					});
				}
				break;

			case ArchiveEntityType.Project: {
				const paginationKey = pageNumber;
				if (state.projects.pagination[paginationKey]) {
					dispatch({ type: ActionType.SET_PAGE_VALUES, payload: {
						type,
						pageNumber,
					}});
				} else {
					if (state.projects.folderId !== null) {
						await loadArchiveFolderById(state.projects.folderId, {
							pageNumber,
							pageSize: state.projects.pageSize,
						});
					} else {
						await loadProjectsWithoutFolder({
							pageNumber,
							pageSize: state.projects.pageSize,
							query: state.projects.query,
						});
					}
				}
				break;
			}
			case ArchiveEntityType.Configuration: {
				throw new Error(`Paging for ArchiveEntityTypes '${type}' not supported!`);
				// const paginationKey = pageNumber; // use this if we can use only sub elements of current folder/project -> clear on every parent element load
				// if (state.configurations.pagination[paginationKey]) {
				// 	dispatch({ type: ActionType.SET_PAGE_VALUES, payload: {
				// 		type,
				// 		pageNumber,
				// 	}});
				// } else {
				// 	// loadArchiveFolders({
				// 	// 	pageNumber,
				// 	// 	pageSize: state.folders.pageSize,
				// 	// });
				// }
				// break;
			}

			default:
				throw new Error(`ArchiveEntityTypes '${type}' unknown!`);
		}
	}, [dispatch, loadArchiveFolderById, loadArchiveFolders, loadProjectsWithoutFolder, state.folders.pageSize, state.folders.pagination, state.projects.folderId, state.projects.pageSize, state.projects.pagination, state.projects.query]);

	const prevNextPage = useCallback(async (type, diff) => {
		switch (type) {
			case ArchiveEntityType.Folder:
				await goToPage(type, state.folders.pageNumber + diff);
				break;

			case ArchiveEntityType.Project:
				await goToPage(type, state.projects.pageNumber + diff);
				break;
		
			case ArchiveEntityType.Configuration:
				await goToPage(type, state.configurations.pageNumber + diff);
				break;

			default:
				throw new Error(`ArchiveEntityTypes '${type}' unknown!`);
		}
	}, [goToPage, state.configurations.pageNumber, state.folders.pageNumber, state.projects.pageNumber]);

	const prevPage = useCallback(async (type) => {
		await prevNextPage(type, -1);
	}, [prevNextPage]);

	const nextPage = useCallback(async (type) => {
		await prevNextPage(type, 1);
	}, [prevNextPage]);

	const hasPrevPage = useCallback((type) => {
		let store;
		switch (type) {
			case ArchiveEntityType.Folder:
				store = state.folders;
				break;

			case ArchiveEntityType.Project:
				store = state.projects;
				break;
		
			case ArchiveEntityType.Configuration:
				return false;
				// store = state.configurations;
				// break;

			default:
				throw new Error(`ArchiveEntityTypes '${type}' unknown!`);
		}

		return store.pageNumber > 1;
	}, [state.folders, state.projects]);

	const hasNextPage = useCallback((type) => {
		let store;
		switch (type) {
			case ArchiveEntityType.Folder:
				store = state.folders;
				break;

			case ArchiveEntityType.Project:
				store = state.projects;
				break;
		
			case ArchiveEntityType.Configuration:
				return false;
				// store = state.configurations;
				// break;

			default:
				throw new Error(`ArchiveEntityTypes '${type}' unknown!`);
		}

		return store.totalCount > store.pageNumber * store.pageSize;
	}, [state.folders, state.projects]);

	return {
		state,
		init,
		reload,
		createArchiveFolder,
		duplicateArchiveFolder,
		updateArchiveFolder,
		loadArchiveFolders,
		loadArchiveFolderById,
		loadProjectsWithoutFolder,
		searchProjects,
		loadProjectById,
		updateProject,
		assignProjectToFolder,
		removeArchiveProject,
		updatePriceArchiveProject,
		shareProject,
		loadProjectRevisions,
		deleteArchiveFolder,
		loadAllUsers,
		prevPage,
		nextPage,
		hasPrevPage,
		hasNextPage,
		goToPage,
	};
};

export const makeSelectAllFolders = () => {
	return createSelector(
		[
			(state) => state.folders,
		],
		(store) => {
			return store.ids.filter((id) => !!store.entities[id]).map((id) => store.entities[id]);
		},
	);
};
export const selectAllFolders = makeSelectAllFolders();

export const makeSelectFoldersByCurrentPageNumber = () => {
	return createSelector(
		[
			(state) => state.folders,
			(state) => state.folders.pageNumber,
		],
		(store, pageNumber) => {
			const ids = store.pagination[pageNumber];
			if (!ids) {
				return [];
			}
			return ids.filter((id) => !!store.entities[id]).map((id) => store.entities[id]);
		},
	);
};
export const selectFoldersByCurrentPageNumber = makeSelectFoldersByCurrentPageNumber();

export const makeSelectAllProjects = () => {
	return createSelector(
		[
			(state) => state.projects,
			(_, folderId) => folderId,
		],
		(store, folderId) => {
			const list = store.ids.filter((id) => !!store.entities[id]).map((id) => store.entities[id]);
			if (folderId > 0) {
				return list.filter((project) => project.folderId === folderId);
			}

			return list;
		},
	);
};
export const selectAllProjects = makeSelectAllProjects();

export const makeSelectAllConfigurations = () => {
	return createSelector(
		[
			(state) => state.configurations,
			(_, projectId) => projectId,
		],
		(store, projectId) => {
			const list = store.ids.filter((id) => !!store.entities[id]).map((id) => store.entities[id]);
			if (projectId > 0) {
				return list.filter((config) => config.projectId === projectId);
			}

			return list;
		},
	);
};
export const selectAllConfigurations = makeSelectAllConfigurations();
