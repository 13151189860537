import React, { useEffect, useState } from 'react';
import { useConfig } from 'configuration/useConfig';

function LightRayData(props) {
	const [isLightRayLibraryLoaded, setIsLightRayLibraryLoaded] = useState(false);
	const { config } = useConfig();
	useEffect(() => {
		const script = document.createElement('script');
		script.src = config.baseApiUrl + '/lightray/lightray.js';
		script.async = false;
		script.onload = function () {
			window.lightray_init('lightray-main', config.baseApiUrl + '/lightray');
			setIsLightRayLibraryLoaded(true);
		};
		document.body.appendChild(script);
		
		const style = document.createElement('link');
		style.type = 'text/css';
		style.rel = 'stylesheet';
		style.href = config.baseApiUrl + '/lightray/lightray.css';
		style.async = false;
		document.body.appendChild(style);
		return () => {
			document.body.removeChild(script);
			document.body.removeChild(style);
		};
	}, [config.baseApiUrl]);
	
	useEffect(() => {
		if (isLightRayLibraryLoaded && props.state.lightRayData && props.state.lightRayData.element) {
			window.lightray_respawn(props.state.lightRayData);
		}
	}, [props.state.lightRayData, isLightRayLibraryLoaded]);
	
	return (
		<div id="lightray-main"></div>
	);
}

LightRayData.defaultProps = {
	state: null,
};

export default LightRayData;
