import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import './style.scss';

function RegistrationOverlay() {
	const history = useHistory();
	
	return (
		<div className="registration-overlay">
			<Overlay
				headline="User_RegisterOverlayHeadline"
				text="User_RegisterOverlayText"
				backLink={Routes.configuration.index}
			>
				<Button
					class="btn-primary"
					text="User_RegisterOverlayYes"
					onClick={() => history.push(Routes.registration.index)}
				/>
				<Button
					class="btn-secondary"
					text="User_RegisterOverlayNo"
					onClick={() => history.push(Routes.configuration.index)}
				/>
			</Overlay>
		</div>
	);
}

export default RegistrationOverlay;