import { useCallback, useState } from 'react';

export const useLocalStorage = (key, initialValue, storage = localStorage) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = storage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});
	
	const setValue = useCallback((value) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			storage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.log(error);
		}
	}, [key, storage, storedValue]);
	
	return [storedValue, setValue];
};
