import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import './index.scss';

export const Textarea = ({ ...props }) => {
	const [focused, setFocused] = useState(false);
	return (
		<Field name={props.name}>
			{({ field, meta }) => (
				<div className={'field type-textarea' + (focused || field.value !== '' ? ' has-value' : '') + ( meta.error ? ' error' : '') + `${props.class ? ' ' + props.class : ''}`}>
					<div className="input-container">
						<textarea
							{...field}
							onFocus={(e) => setFocused(true)}
							onBlur={(e) => {
								setFocused(false);
								field.onBlur(e);
							}}
							placeholder={props.placeholder}
						/>
						{ meta.error &&
							<div>
								<div className="error-text"><ErrorMessage name={props.name} /></div>
							</div>
						}
					</div>
				</div>
			)}
		</Field>
	);
};

Textarea.defaultProps = {
	name: '',
	placeholder: '',
};

export default Textarea;
