import Button from 'components/core/button';
import Tabs from 'components/navigation/tabs';
import Link from 'components/core/link';
import Product from 'components/core/product';
import Question from 'pages/configuration/question';
import TranslationTag from 'utils/translations';
import SimpleBar from 'simplebar-react';
import { makeSelectQuestionsGroupedByPage, useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { PageTranslationKeys } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function SummaryOverlay(props) {
	const { state, deleteConfiguration } = useConfiguratorApi();
	const selectGroupedQuestions = useMemo(() => makeSelectQuestionsGroupedByPage(), []);
	const groupedQuestions = selectGroupedQuestions(state);
	const { t } = useTranslation('translations');
	const allConfigs = state.allConfigurationsOfUser;
	const [currentTab, setCurrentTab] = useState('');
	const [isDeleting, setIsDeleting] = useState(false);
	let menuScroll = document.querySelector('.summary-overlay .buttons .inner-buttons');
	
	useEffect(() => {
		if (menuScroll) {
			let activeMenuPoint = menuScroll.querySelector('button.active');
			
			if (activeMenuPoint) {
				menuScroll.scrollLeft = activeMenuPoint.offsetLeft - (menuScroll.offsetWidth - activeMenuPoint.offsetWidth) / 2;
			}
		}
	}, [allConfigs, currentTab, groupedQuestions, menuScroll]);
	
	return (
		<div>
			{ props.isOpen &&
				<div className="summary-overlay">
					<div className="bg"></div>
					<div className="content">
						<div className="intro-container">
							<div className="text">
								<div className="t2 headline">
									<TranslationTag tag="Configuration_SummaryOverlayHeadline" />
								</div>
							</div>
							<div className="close-button">
								<Button
									onClick={() => {
										setCurrentTab('');
										props.onClose();
									}}
									icon="close"
									width="14"
									height="14"
								/>
							</div>
						</div>
						<div className="content-container">
							<Tabs class="v2">
								<div className="buttons t2">
									<div className="inner-buttons">
										<div className="scroll">
											{ allConfigs && allConfigs.map((config, index) => (
												config.id !== state.configId &&
												<Link
													class={currentTab === config.id ? 'active' : ''}
													htmlText={config.family}
													onClick={() => {
														setCurrentTab(config.id);
														setIsDeleting(false);
													}}
													key={index}
												/>
											))}
											<Link
												class={currentTab === '' ? 'active' : ''}
												text={'Configuration_SummaryOverlayCurrentConfiguration'}
												onClick={() => {
													setCurrentTab('');
													setIsDeleting(false);
												}}
											/>
										</div>
									</div>
								</div>
								<div className="tab-content">
									{ allConfigs && allConfigs.map((config, index) => (
										currentTab === config.id &&
											<SimpleBar style={{ maxHeight: 'calc(100vh - 200px)' }} autoHide={false} key={index}>
												<div className="inner-content">
													<Product
														image={config.image}
														name={config.family}
														summary={config.summary}
														summaryLayout={true}
														onDelete={() => setIsDeleting(true)}
													/>
													{ isDeleting &&
														<div className="delete-container">
															<div className="headline t2"><TranslationTag tag="Configuration_SummaryOverlayDeleteHeadline" /></div>
															<div className="text"><TranslationTag tag="Configuration_SummaryOverlayDeleteText" /></div>
															<div className="actions">
																<Link
																	text="Configuration_SummaryOverlayDeleteYes"
																	onClick={() => {
																		deleteConfiguration(config.id);
																		setCurrentTab('');
																	}}
																/>
																<Link
																	text="Configuration_SummaryOverlayDeleteNo"
																	onClick={() => setIsDeleting(false)}
																/>
															</div>
														</div>
													}
												</div>
											</SimpleBar>
									))}
									{ currentTab === '' &&
										<SimpleBar style={{ maxHeight: 'calc(100vh - 200px)' }} autoHide={false}>
											<div className="inner-content current">
												{ (groupedQuestions && groupedQuestions.length) ?
													<>
														{ groupedQuestions.map((group, index) => (
															<div className="question-group" key={index}>
																<div className="group-title t2">
																	{ t(PageTranslationKeys[group.page]) }
																</div>
																<div className="questions">
																	{
																		group.questions.map((question) => (
																			<Question
																				key={question.identifier}
																				currentQuestion={question}
																				active={false}
																				editable={false}
																			/>
																		))
																	}
																</div>
															</div>
														))}
													</>
													:
													<div className="empty">
														<TranslationTag tag="Configuration_SummaryOverlayEmptyConfig" />
													</div>
												}
											</div>
										</SimpleBar>
									}
								</div>
							</Tabs>
						</div>
						<div className="buttons-container">
							<Button
								onClick={() => {
									setCurrentTab('');
									props.onClose();
								}}
								text="Configuration_SummaryOverlayButtonAbort"
								class="btn-secondary"
							/>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

SummaryOverlay.defaultProps = {
	state: null,
	isOpen: false,
	onClose: () => void(0),
};

export default SummaryOverlay;
