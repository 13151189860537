import Downloads, { DownloadGroup, DownloadItem } from 'components/forms/downloads';
import DotMenu from 'components/navigation/dot-menu';
import DotMenuItem from 'components/navigation/dot-menu/item';
import SpinnerLoader from 'components/system/spinner-loader';
import { useConfig } from 'configuration/useConfig';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DownloadExtensions, DownloadTranslations, FileExtensions, Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import './index.scss';

function Configuration ({ configuration, revision, available }) {
	const { user } = useAuth();
	const history = useHistory();
	const [fileFormats, setFileFormats] = useState([]);
	const [isDownloading, setIsDownloading] = useState(false);
	const { configurationDownload } = useConfiguratorApi();
	const { config } = useConfig();
	const { t } = useTranslation('translations');
	
	const updateFileFormats = (formats) => {
		let tempDownloadFiles = [...fileFormats];
		formats.forEach((format) => {
			let foundIndex = -1;
			tempDownloadFiles.forEach((type, index) => {
				if (type.format === format.format) {
					foundIndex = index;
				}
			});
			if (foundIndex > -1) {
				tempDownloadFiles.splice(foundIndex, 1);
			} else {
				tempDownloadFiles.push(format);
			}
		});
		setFileFormats(tempDownloadFiles);
	};
	
	const handleSingleDownload = async (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = format;
		formatArray['type'] = types;
		fileFormats.push(formatArray);
		if (fileFormats) {
			setIsDownloading(true);
			const response = await configurationDownload(fileFormats, configuration.configurationId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const handleDownload = async () => {
		if (fileFormats) {
			setIsDownloading(true);
			const response = await configurationDownload(fileFormats, configuration.configurationId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const fsfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'FSF';
		if (configuration.downloadForecast.FSF.filter(element => element === 'Document').length > 0) {
			typeArray.push('Document');
		}
		if (configuration.downloadForecast.FSF.filter(element => element === 'Image').length > 0) {
			typeArray.push('Image');
		}
		if (configuration.downloadForecast.FSF.filter(element => element === 'Other').length > 0) {
			typeArray.push('Other');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);
		return fileArray;
	};
	
	const getFileArray = (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		if (configuration.downloadForecast[format]) {
			formatArray['format'] = format;
			formatArray['type'] = types;
			fileFormats.push(formatArray);
		}
		return fileFormats;
	};
	
	return (
		<div className="archive-configuration" config-id={configuration.configurationId}>
			<div className="details">
				<div className="intro-container">
					<div className="headline t2"><TranslationTag tag="Archive_Configuration" /> – { configuration.family }</div>
					{ configuration.summary &&
						<div className="summary">{ parse(configuration.summary) }</div>
					}
					{ configuration.pcpSaveId &&
						<DotMenu>
							<DotMenuItem
								icon="edit"
								text="Archive_EditConfiguration"
								width="20"
								height="14"
								onClick={() => history.push(Routes.configuration.restoreConfiguration, { saveId: configuration.pcpSaveId })}
							/>
						</DotMenu>
					}
				</div>
				{ configuration.image &&
					<div className="images">
						<div className="left">
							<div className="image"><img src={configuration.image} alt={configuration.family} /></div>
						</div>
					</div>
				}
				{ Object.keys(configuration.downloadForecast).length > 0 &&
					<div className="downloads">
						<div className="headline t2"><TranslationTag tag="Archive_ConfigurationDownloadHeadline" /></div>
						<Downloads
							onClick={() => handleDownload()}
							multiDownload={true}
							formats={fileFormats}
						>
							<SpinnerLoader
								show={isDownloading}
							/>
							<DownloadGroup
								headline="Documents_PhotometricData"
								accordion={true}
								open={false}
								onChange={() => updateFileFormats([...getFileArray('LDT', ['']), ...getFileArray('IES', ['']), ...getFileArray('PLOT', [''])])}
							>
								{ configuration.downloadForecast.LDT &&
									<DownloadItem
										title={t('Documents_LDT')}
										format={'(' + FileExtensions.ldt + ')'}
										onClick={() => handleSingleDownload('LDT', [''])}
									/>
								}
								{ configuration.downloadForecast.IES &&
									<DownloadItem
										title={t('Documents_IES')}
										format={'(' + FileExtensions.ies + ')'}
										onClick={() => handleSingleDownload('IES', [''])}
									/>
								}
								{ configuration.downloadForecast.PLOT &&
									<DownloadItem
										title={t('Documents_PLOT')}
										format={'(' + FileExtensions.plot + ')'}
										onClick={() => handleSingleDownload('PLOT', [''])}
									/>
								}
							</DownloadGroup>
							{ configuration.downloadForecast.FSF &&
								<DownloadGroup
									headline="Documents_Datasheets"
									accordion={true}
									onChange={() => updateFileFormats([...fsfFiles()])}
								>
									{ configuration.downloadForecast.FSF.map((item, index) => (
										configuration.downloadForecast.FSF.filter(element => element === item).length > 0 ? (
											<DownloadItem
												key={index}
												title={t(DownloadTranslations(item))}
												format={'(' + DownloadExtensions(item) + ')'}
												onClick={() => handleSingleDownload('FSF', [item])}
											/>
										)
											:
											null
									))}
								</DownloadGroup>
							}
						</Downloads>
					</div>
				}
				<div className="config-details">
					{ configuration.luminaireSuperkey &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_LuminaireSuperkey" /></div>
							<div className="text">{parse(configuration.luminaireSuperkey)}</div>
						</div>
					}
					{ configuration.poleSuperkey &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_PoleSuperkey" /></div>
							<div className="text">{configuration.poleSuperkey}</div>
						</div>
					}
					{ configuration.numberOfProducts &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_Amount" /></div>
							<div className="text">{configuration.numberOfProducts}</div>
						</div>
					}
					{ configuration.singlePrice &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_SinglePrice" /></div>
							<div className="text">{configuration.singlePrice}</div>
						</div>
					}
					{ configuration.accessoriesPrice &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_AccessoriesPrice" /></div>
							<div className="text">{configuration.accessoriesPrice}</div>
						</div>
					}
					{ configuration.totalPrice &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_TotalPrice" /></div>
							<div className="text">{configuration.totalPrice}</div>
						</div>
					}
					{ (configuration.discount && user.discountAvailable) &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_Discount" /></div>
							<div className="text">{configuration.discount}</div>
						</div>
					}
					{ (configuration.discountMargin && user.discountAvailable) &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_DiscountMargin" /></div>
							<div className="text">{configuration.discountMargin}</div>
						</div>
					}
					{ configuration.tags &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_Tags" /></div>
							<div className="text">{configuration.tags}</div>
						</div>
					}
					{ configuration.comment &&
						<div className="info">
							<div className="headline t2"><TranslationTag tag="Archive_Comment" /></div>
							<div className="text">{configuration.comment}</div>
						</div>
					}
				</div>
			</div>
			{ configuration.text &&
				<div className="text-summary">
					{ (configuration.configLogInfo && configuration.configLogInfo.length > 0) &&
						<div className="updates-text">
							<div className="text-container">
								<div className="title t4">
									{ revision ?
										<TranslationTag tag="Archive_ProjectVersionsHint" />
										:
										available ?
											<TranslationTag tag="Archive_ProjectUpdateHint" />
											:
											<TranslationTag tag="Archive_ProjectUpdateNotPossibleHint" />
									}
								</div>
							</div>
							<ul className="list">
								{ configuration.configLogInfo.map((update, key) => (
									<li className="update" key={key}>
										<span>{update.key} -</span> {update.message}
									</li>
								))}
							</ul>
						</div>
					}
					{ configuration.articles &&
						<div className="articles">
							{ configuration.articles.map((article) => (
								<div className="article">
									<div className="headline t2">{article.headline + (article.articleKey ? (' (' + article.articleKey + ')') : '')}</div>
									<div className="text" dangerouslySetInnerHTML={{ __html: article.text }}></div>
								</div>
							))}
						</div>
					}
				</div>
			}
		</div>
	);
}

export default Configuration;
