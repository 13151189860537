import Button from 'components/core/button';
import Note from 'components/core/note';
import Downloads, { DownloadGroup, DownloadItem } from 'components/forms/downloads';
import { makeSelectDocumentsByCategory, useConfiguratorApi } from 'hooks/useConfiguratorApi';
import TranslationTag from 'utils/translations';
import { StaticUrls } from 'utils/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfig } from 'configuration/useConfig';
import { useLoading } from 'hooks/useLoading';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function DocumentOverlay(props) {
	const { state, downloadFilesOnTheFly } = useConfiguratorApi();
	const { t, i18n } = useTranslation('translations');
	const { config } = useConfig();
	const { startLoading, stopLoading } = useLoading();
	
	const selectDocuments = useMemo(() => makeSelectDocumentsByCategory(), []);
	const other = selectDocuments(state, 'other');
	
	const handleSingleDownload = async (fileFormats) => {
		if (fileFormats) {
			startLoading();
			const response = await downloadFilesOnTheFly(fileFormats);
			stopLoading();
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	return (
		<div>
			{ props.isOpen &&
				<div className="document-overlay">
					<div className="bg"></div>
					<div className="content">
						<div className="intro-container">
							<div className="t2 title">
								<TranslationTag tag="Configuration_DocumentsOverlayHeadline" />
								<div className="close-button">
									<Button
										onClick={() => props.onClose()}
										icon="close"
										width="14"
										height="14"
									/>
								</div>
							</div>
						</div>
						<div className="content-container">
							<SimpleBar style={{ maxHeight: 'calc(100vh - 325px)' }} autoHide={false}>
								<div className="inner-content">
									<div className="group">
										<div className="headline t2">
											<TranslationTag tag="Documents_PhotometricData" />
										</div>
										<Note
											headline="Configuration_DocumentsOverlayHintHeadline"
											htmlText={t('Configuration_DocumentsOverlayPhotometricText') + ' <a href="' + StaticUrls('plannerMode', config) + '" target="_blank">' + t('Configuration_DocumentsOverlayPhotometricLinktext') + '</a>'}
										/>
									</div>
									<div className="group">
										<Downloads
											multiDownload={false}
										>
											<DownloadGroup
												headline="Documents_Datasheets"
												accordion={false}
												open={true}
											>
												{ other.map((item, index) => (
													<DownloadItem
														key={index}
														new={false}
														title={t(item.title)}
														format={'(' + item.format + ')'}
														onClick={() => handleSingleDownload([item.key])}
													/>
												))}
											</DownloadGroup>
										</Downloads>
									</div>
									{ (i18n.language === 'it' || i18n.language === 'it-IT') &&
										<div className="group">
											<Downloads
												multiDownload={false}
											>
												<DownloadGroup
													headline=""
													accordion={false}
													open={true}
												>
													<DownloadItem
														new={true}
														title="DATI PNRR"
														onClick={() => window.open('https://ewocorlight-my.sharepoint.com/:f:/g/personal/matteo_dalvit_ewo_com/Egn3cYVAct1KoKFw4xy45wEB8XCZleNm0Rkm76zeW9waPQ?e=ijI4f6', '_blank')}
													/>
												</DownloadGroup>
											</Downloads>
										</div>
									}
								</div>
							</SimpleBar>
						</div>
					</div>
				</div>
			}
		</div>
	);
}

DocumentOverlay.defaultProps = {
	state: null,
	isOpen: false,
	onClose: () => void(0),
};

export default DocumentOverlay;
