import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useState } from 'react';
import './style.scss';

function ProjectRemove() {
	const { removeArchiveProject } = useArchiveApi();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	
	const handleSubmit = async () => {
		setIsLoading(true);
		await removeArchiveProject(history.location.state.projectId);
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-remove">
			<Overlay
				headline="Archive_RemoveProjectHeadline"
				text="Archive_RemoveProjectText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues=""
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_RemoveProjectSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_RemoveProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectRemove;