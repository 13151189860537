import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function AssistanceSuccess() {
	const history = useHistory();
	
	return (
		<div className="assistance-success">
			<Overlay
				headline="Assistance_ThanksHeadline"
				text="Assistance_ThanksText"
				backLink={(history.location.state && history.location.state.project) ? Routes.profile.archive.index : Routes.configuration.summary}
			>
				<Button
					class="btn-primary"
					text={'Assistance_BackToConfiguration'}
					onClick={() => history.push((history.location.state && history.location.state.project) ? Routes.profile.archive.index : Routes.configuration.summary)}
				/>
			</Overlay>
		</div>
	);
}

export default AssistanceSuccess;