import { useTranslation } from 'react-i18next';
import Overlay from 'components/core/overlay';
import Input from 'components/forms/input';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Errors, Routes } from 'utils/constants';
import './style.scss';

function PasswordReset() {
	const { resetPassword } = useAuth();
	const { t } = useTranslation('translations');
	const history = useHistory();
	const validationSchema = Yup.object({
		email: Yup.string().required(t(Errors.Required)).email(t(Errors.EMail)),
	});
	
	const handleSubmit = async (values) => {
		await resetPassword(values.email);
		history.push(Routes.login.passwordReset.success);
	};
	
	return (
		<div className="password-reset">
			<Overlay
				headline="PasswordReset_Headline"
				text="PasswordReset_Text"
			>
				<Form
					initialValues={{ email: '' }}
					validationSchema={validationSchema}
					onSubmit={(values) => handleSubmit(values)}
				>
					<Input
						name="email"
						text="Form_Email"
						type="text"
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="PasswordReset_SendInstructions"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default PasswordReset;