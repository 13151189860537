import Overlay from 'components/core/overlay';
import Product from 'components/core/product';
import SpinnerLoader from 'components/system/spinner-loader';
import React, { useState, useEffect } from 'react';
import { Routes } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import './style.scss';

function DefaultsConfig() {
	const history = useHistory();
	const { user } = useAuth();
	const { state, getDefaults, createDefault, getOrderInfo } = useConfiguratorApi();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingDefaults, setLoadingDefaults] = useState(false);
	const [defaults, setDefaults] = useState(null);
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	
	const onSelectConfiguration = async (configuration) => {
		setIsLoading(true);
		history.push(Routes.configuration.defaults, { currentConfiguration: configuration });
		setIsLoading(false);
	};
	
	const isAlreadyDefault = (configuration) => {
		let defaultsArray = [];
		Object.keys(defaults).forEach(function(key) {
			if (defaults[key].length) {
				defaults[key].forEach(function(value) {
					defaultsArray.push(value);
				});
			}
		});
		return defaultsArray.filter((config) => config.id === configuration.pcpSaveId).length > 0;
	};
	
	useEffect(() => {
		const load = async () => {
			setDefaults(await getDefaults());
			setLoadingDefaults(false);
		};
		if (defaults === null && loadingDefaults === false) {
			setLoadingDefaults(true);
			load();
		}
	}, [defaults, getDefaults, createDefault, loadingDefaults, setDefaults, setLoadingDefaults]);
	
	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
			setIsLoading(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	if (!state.variantId || !state.projectId || !user.allowedAddDefaultConfiguration) {
		history.push(Routes.configuration.index);
	}
	
	return (
		<div className="defaults-config">
			<Overlay
				headline="DefaultsSelectConfiguration_Headline"
				text="DefaultsSelectConfiguration_Text"
				backLink={Routes.configuration.index}
			>
				<SpinnerLoader
					show={isLoading}
				/>
				<div className="configs">
					{ (infos && infos.configurations.length > 0) &&
						infos.configurations.map((configuration, index) => (
							<Product
								key={index}
								configurationId={configuration.id}
								name={configuration.family}
								image={configuration.image}
								summary={configuration.summary}
								onClick={() => onSelectConfiguration(configuration)}
								disabled={isAlreadyDefault(configuration)}
							/>
						))
					}
				</div>
			</Overlay>
		</div>
	);
}

export default DefaultsConfig;