import Button from 'components/core/button';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Password from 'components/forms/password';
import Checkbox from 'components/forms/checkbox';
import Separator from 'components/forms/separator';
import TranslationTag from 'utils/translations';
import SimpleBar from 'simplebar-react';
import { Routes, Errors, StaticUrls } from 'utils/constants';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import * as Yup from 'yup';
import 'simplebar/dist/simplebar.min.css';
import './style.scss';

function LoginOnPage() {
	const history = useHistory();
	const { login } = useAuth();
	const { t, i18n } = useTranslation('translations');
	const { reset } = useConfiguratorApi();
	
	const validationSchema = Yup.object({
		email: Yup.string().email(t(Errors.EMail)).required(t(Errors.Required)),
		password: Yup.string().required(t(Errors.Required)),
		privacy1: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
		privacy2: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
	});
	
	const handleSubmit = async (values) => {
		await login(values.email, values.password, values.rememberMe);
		reset();
		history.push(Routes.configuration.index);
	};
	
	return (
		<div>
			<div className="login-overlay">
				<div className="bg"></div>
				<div className="content">
					<SimpleBar style={{ maxHeight: 'calc(100vh - 50px)' }} autoHide={false}>
						<div className="intro-container">
							<div className="text">
								<div className="t2 headline">
									<TranslationTag tag="User_LoginOverlayHeadline" />
								</div>
							</div>
							<div className="close-button">
								<Button
									onClick={() => history.push(Routes.configuration.index)}
									icon="close"
									width="14"
									height="14"
								/>
							</div>
						</div>
						<div className="content-container">
							<Form
								initialValues={{
									email: '',
									password: '',
									rememberMe: false,
									privacy1: false,
									privacy2: false,
								}}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
							>
								<Separator
									text="User_LoginOverlayLoginNow"
								/>
								<Input
									name="email"
									text="Form_EmailLong"
									type="text"
								/>
								<Password
									name="password"
									text="Form_Password"
								/>
								<div className="password-reset">
									<Link to={Routes.login.passwordReset.index}>
										<TranslationTag tag="Login_Passwordlost" />
									</Link>
								</div>
								<Checkbox
									name="rememberMe"
									id="rememberMe"
									text="Form_RememberMe"
								/>
								<Checkbox
									name="privacy1"
									id="privacy1"
									text="Form_Privacy1"
									textLink={StaticUrls('privacy1', '', i18n.language)}
								/>
								<Checkbox
									name="privacy2"
									id="privacy2"
									text="Form_Privacy2"
									textLink={StaticUrls('privacy2', '', i18n.language)}
								/>
								<Button
									class="btn-primary"
									type="submit"
									text="User_LoginOverlayLoginAndContinue"
								/>
							</Form>
						</div>
						<div className="buttons-container">
							<Button
								onClick={() => history.push(Routes.configuration.index)}
								text="User_LoginOverlayAbort"
								class="btn-secondary"
							/>
						</div>
					</SimpleBar>
				</div>
			</div>
		</div>
	);
}

export default LoginOnPage;
