import Alert from 'components/core/alert';
import Button from 'components/core/button';
import Icon from 'components/core/icon';
import Link from 'components/core/link';
import UpdateBadge from 'components/core/update-badge';
import Downloads, { DownloadGroup, DownloadItem } from 'components/forms/downloads';
import DotMenu from 'components/navigation/dot-menu';
import DotMenuItem from 'components/navigation/dot-menu/item';
import SpinnerLoader from 'components/system/spinner-loader';
import { useConfig } from 'configuration/useConfig';
import { makeSelectAllConfigurations, useArchiveApi } from 'hooks/useArchiveApi';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DownloadExtensions, DownloadTranslations, FileExtensions, Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import Configuration from '../configuration';
import './index.scss';

function Project ({ project, revision = false }) {
	const history = useHistory();
	const { user } = useAuth();
	const { loadProjectById, state: archiveState, updateProject, reload } = useArchiveApi();
	const selectAllConfigurations = useMemo(() => makeSelectAllConfigurations(), []);
	const configurations = selectAllConfigurations(archiveState, project.projectId);
	const [isOpening, setIsOpening] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [isManuallyClosed, setIsManuallyClosed] = useState(false);
	const [isProjectActive, setIsProjectActive] = useState(false);
	const [isRevisionTooltipVisible, setIsRevisionTooltipVisible] = useState(false);
	const [fileFormats, setFileFormats] = useState([]);
	const { projectDownload } = useConfiguratorApi();
	const { config } = useConfig();
	const { t } = useTranslation('translations');
	const myRef = useRef();
	
	useEffect(() => {
		if (!isProjectActive && archiveState.configurations.projectId === project.projectId) {
			setIsProjectActive(true);
			setIsOpening(false);
			setIsManuallyClosed(false);
		}
		if (isProjectActive && archiveState.configurations.projectId !== project.projectId) {
			setIsProjectActive(false);
		}
	}, [project.projectId, isProjectActive, isOpening, archiveState.configurations.projectId]);
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	});
	
	const isOpen = (isOpening || isProjectActive) && !isManuallyClosed;
	
	const handleClick = e => {
		if (myRef.current && !myRef.current.contains(e.target)) {
			setIsRevisionTooltipVisible(false);
		}
	};
	
	const handleUpdate = async() => {
		setIsUpdating(true);
		await updateProject(project.projectId);
		setIsUpdating(false);
		await reload(!!project.folderId);
	};
	
	const togglePanel = async () => {
		if (isOpen) {
			setIsManuallyClosed(true);
			return;
		}
		if (!isProjectActive) {
			setIsOpening(true);
			await loadProjectById(project.projectId);
		} else {
			setIsManuallyClosed(false);
		}
	};
	
	const pdfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'PDF';
		if (project.downloadForecast.PDF.filter(element => element === 'Standard').length > 0) {
			typeArray.push('Standard');
		}
		if (project.downloadForecast.PDF.filter(element => element === 'Customer').length > 0) {
			typeArray.push('Customer');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);
		
		return fileArray;
	};
	
	const fsfFiles = () => {
		let fileArray = [];
		let formatArray = [];
		let typeArray = [];
		formatArray['format'] = 'FSF';
		if (project.downloadForecast.FSF.filter(element => element === 'Document').length > 0) {
			typeArray.push('Document');
		}
		if (project.downloadForecast.FSF.filter(element => element === 'Image').length > 0) {
			typeArray.push('Image');
		}
		if (project.downloadForecast.FSF.filter(element => element === 'Other').length > 0) {
			typeArray.push('Other');
		}
		formatArray['type'] = typeArray;
		fileArray.push(formatArray);
		return fileArray;
	};
	
	const updateFileFormats = (formats) => {
		let tempDownloadFiles = [...fileFormats];
		formats.forEach((format) => {
			let foundIndex = -1;
			tempDownloadFiles.forEach((type, index) => {
				if (type.format === format.format) {
					foundIndex = index;
				}
			});
			if (foundIndex > -1) {
				tempDownloadFiles.splice(foundIndex, 1);
			} else {
				tempDownloadFiles.push(format);
			}
		});
		setFileFormats(tempDownloadFiles);
	};
	
	const handleSingleDownload = async (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = format;
		formatArray['type'] = types;
		fileFormats.push(formatArray);
		if (fileFormats) {
			setIsDownloading(true);
			const response = await projectDownload(fileFormats, project.projectId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const handleDownload = async () => {
		if (fileFormats) {
			setIsDownloading(true);
			const response = await projectDownload(fileFormats, project.projectId);
			setIsDownloading(false);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
	};
	
	const getFileArray = (format, types) => {
		var fileFormats = [];
		var formatArray = [];
		if (project.downloadForecast[format]) {
			formatArray['format'] = format;
			formatArray['type'] = types;
			fileFormats.push(formatArray);
		}
		return fileFormats;
	};
	
	const handleTooltipClick = (e) => {
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		setIsRevisionTooltipVisible(!isRevisionTooltipVisible);
	};
	
	const showRevisions = (e) => {
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		history.push(Routes.profile.archive.project.revisions, { projectId: project.projectId });
	};
	
	const wasUpdated = () => {
		if (project.configurationDate && project.revisionNumber > 0) {
			let today = Date.now();
			let past2Weeks = today - (14 * 24 * 3600 * 1000);
			let aProjectDate = project.configurationDate.split('.');
			let projectTimestamp = new Date(aProjectDate[2], (parseInt(aProjectDate[1]) - 1), parseInt(aProjectDate[0])).getTime();
			if (past2Weeks < projectTimestamp) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};
	
	return (
		<div className={'project' + (isOpen ? ' open' : '')}>
			<SpinnerLoader
				show={isOpening || isUpdating}
			/>
			<div className="inner d-flex justify-content-between">
				<div className="headline" onClick={() => togglePanel()}>
					<div className="location"><TranslationTag tag="Archive_CreationDate" />: { project.configurationDate }{(project.firstName !== '' || project.lastName !== '') ? (' | ' + t('Archive_ProjectAuthor') + ': ' + project.firstName + ' ' + project.lastName) : ''}</div>
					{ project.projectId &&
						<div className="project-title">
							<div className="t2"><TranslationTag tag="Archive_ProjectNumber" />: { project.projectId + ((project.revisionNumber > 0 || revision) ? (' | V' + project.revisionNumber) : '') }</div>
							{ (project.revisionNumber > 0 && !revision) &&
								<div className={'tooltip-container' + (isRevisionTooltipVisible ? ' open' : '')} ref={myRef}>
									<div className="info-icon" onClick={(e) => handleTooltipClick(e)}>
										<Icon
											icon={isRevisionTooltipVisible ? 'close' : 'info'}
											width={isRevisionTooltipVisible ? '8' : '4'}
											height={isRevisionTooltipVisible ? '8' : '12'}
											color="#FFFFFF"
										/>
									</div>
									<div className="line"></div>
									<div className="info-content" onClick={(e) => showRevisions(e)}>
										<div><TranslationTag tag="Archive_ProjectUpdateAt" />: { project.configurationDate }</div>
										<div>
											<Link
												text="Archive_ProjectViewOlderVersions"
												onClick={(e) => showRevisions(e)}
											/>
										</div>
									</div>
								</div>
							}
						</div>
					}
				</div>
				<div className="actions d-flex align-items-center">
					{ (project.available && project.updates === 0) ?
						!revision &&
							<>
								{ wasUpdated() &&
									<div className="project-updated-info">
										<Alert
											text="Archive_ProjectWasUpdated"
										/>
									</div>
								}
								<DotMenu>
									<DotMenuItem
										icon="pdf-download"
										text="Archive_CreatePdf"
										width="20"
										height="20"
										onClick={() => history.push(Routes.profile.archive.project.pdf, { project: project, configurations: configurations })}
									/>
									<DotMenuItem
										icon="arrow"
										text="Archive_ChangeFolder"
										width="20"
										height="14"
										onClick={() => history.push(Routes.profile.archive.project.changeFolder, { projectId: project.projectId, folderId: project.folderId })}
									/>
									{ /*
										<DotMenuItem
											icon="duplicate"
											text="Archive_Duplicate"
											width="20"
											height="20"
											onClick={() => history.push(Routes.profile.archive.project.duplicate, { projectId: project.projectId })}
										/>
									*/ }
									{ (user.listPriceAvailable || user.discountAvailable) &&
										<DotMenuItem
											icon="pricesettings"
											text="Archive_PriceSettings"
											width="21"
											height="21"
											onClick={() => history.push(Routes.profile.archive.project.price, { project: project })}
										/>
									}
									<DotMenuItem
										icon="share"
										text="Archive_Share"
										width="20"
										height="20"
										onClick={() => history.push(Routes.profile.archive.project.share, { projectId: project.projectId })}
									/>
									{ project.folderId &&
										<DotMenuItem
											icon="remove-from-folder"
											text="Archive_ProjectRemoveFromFolder"
											width="20"
											height="20"
											onClick={() => history.push(Routes.profile.archive.project.remove, { projectId: project.projectId })}
										/>
									}
									{ /*
									<DotMenuItem
										icon="language"
										text="Archive_ChangeLanguage"
										width="20"
										height="20"
										onClick={() => history.push(Routes.profile.archive.project.changeLanguage, { projectId: project.projectId })}
									/>
									*/}
								</DotMenu>
							</>
						:
						!revision &&
							<UpdateBadge
								project={project}
								onUpdate={() => handleUpdate()}
							/>
					}
					<div className="arrow" onClick={() => togglePanel()}>
						<Icon
							icon="arrow-down"
							width="17"
							height="9"
						/>
					</div>
				</div>
			</div>
			{isOpen &&
				<>
					<div className="configurations">
						{ !isOpening && configurations &&
							configurations.map((configuration, key) => (
								<Configuration
									key={key}
									configuration={configuration}
									revision={revision}
									available={project.available}
								/>
							))
						}
					</div>
					{ (project.available && project.updates === 0 && !revision) &&
						<div className="project-details">
							{ (project.downloadForecast && !isOpening && Object.keys(project.downloadForecast).length > 0) &&
								<div className="downloads">
									<div className="headline t2"><TranslationTag tag="Archive_ProjectDownloadHeadline" /></div>
									<Downloads
										onClick={() => handleDownload()}
										multiDownload={true}
										formats={fileFormats}
									>
										<SpinnerLoader
											show={isDownloading}
										/>
										{ (project.downloadForecast.PDF || project.downloadForecast.CSV) &&
											<DownloadGroup
												headline="Documents_ConfigurationData"
												accordion={true}
												open={false}
												onChange={() => updateFileFormats([...pdfFiles(), ...getFileArray('CSV', [''])])}
											>
												{ project.downloadForecast.PDF.filter(element => element === 'Standard').length > 0 &&
													<DownloadItem
														title={t('Documents_ConfigSummary')}
														format={'(' + FileExtensions.pdf + ')'}
														onClick={() => handleSingleDownload('PDF', ['Standard'])}
													/>
												}
												{ project.downloadForecast.CSV &&
													<DownloadItem
														title={t('Documents_ConfigSummary')}
														format={'(' + FileExtensions.csv + ')'}
														onClick={() => handleSingleDownload('CSV', [''])}
													/>
												}
												{ project.downloadForecast.PDF.filter(element => element === 'Customer').length > 0 &&
													<DownloadItem
														title={t('Documents_ConfigSummaryClient')}
														format={'(' + FileExtensions.pdf + ' | ' + t('Configuration_ClientPdfInfo') + ')'}
														onClick={() => history.push(Routes.configuration.customerPdf.index, { project })}
													/>
												}
											</DownloadGroup>
										}
										<DownloadGroup
											headline="Documents_PhotometricData"
											accordion={true}
											open={false}
											onChange={() => updateFileFormats([...getFileArray('LDT', ['']), ...getFileArray('IES', ['']), ...getFileArray('PLOT', [''])])}
										>
											{ project.downloadForecast.LDT &&
												<DownloadItem
													title={t('Documents_LDT')}
													format={'(' + FileExtensions.ldt + ')'}
													onClick={() => handleSingleDownload('LDT', [''])}
												/>
											}
											{ project.downloadForecast.IES &&
												<DownloadItem
													title={t('Documents_IES')}
													format={'(' + FileExtensions.ies + ')'}
													onClick={() => handleSingleDownload('IES', [''])}
												/>
											}
											{ project.downloadForecast.PLOT &&
												<DownloadItem
													title={t('Documents_PLOT')}
													format={'(' + FileExtensions.plot + ')'}
													onClick={() => handleSingleDownload('PLOT', [''])}
												/>
											}
										</DownloadGroup>
										{ project.downloadForecast.FSF &&
											<DownloadGroup
												headline="Documents_Datasheets"
												accordion={true}
												onChange={() => updateFileFormats([...fsfFiles()])}
											>
												{ project.downloadForecast.FSF.map((item, index) => (
													project.downloadForecast.FSF.filter(element => element === item).length > 0 ? (
														<DownloadItem
															key={index}
															title={t(DownloadTranslations(item))}
															format={'(' + DownloadExtensions(item) + ')'}
															onClick={() => handleSingleDownload('FSF', [item])}
														/>
													)
														:
														null
												))}
												{ project.downloadForecast.IPEA &&
													<DownloadItem
														title={t('Documents_IPEA')}
														format={'(' + FileExtensions.pdf + ')'}
														onClick={() => history.push(Routes.configuration.ipea.index, { project })}
													/>
												}
											</DownloadGroup>
										}
									</Downloads>
								</div>
							}
							{ !isOpening &&
								<div className="buttons-container">
									<div className="headline t2"><TranslationTag tag="Archive_ProjectActionsHeadline" /></div>
									<Button
										text={'Archive_ProjectOrder'}
										onClick={() => history.push(Routes.configuration.order.index, { project })}
										class="btn-primary"
									/>
									<Button
										text={'Archive_ProjectAssistance'}
										onClick={() => history.push(Routes.configuration.assistance.index, { project })}
										class="btn-secondary"
									/>
								</div>
							}
						</div>
					}
				</>
			}
		</div>
	);
}

export default Project;
