import React from 'react';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import './index.scss';

function Overlay(props) {
	const history = useHistory();
	
	return (
		<div className={'overlay-container' + (props.fullWidth ? ' full-width' : '')}>
			<div className="bg"></div>
			<div className="overlay">
				<div className="close-button">
					<Button
						onClick={() => history.push(props.backLink)}
						icon="close"
						width="14"
						height="14"
					/>
				</div>
				<div className="inner">
					<div className="intro">
						{ props.headline &&
							<div className="headline t1"><TranslationTag tag={ props.headline } /></div>
						}
						{ props.subHeadline &&
							<div className="sub-headline t1"><TranslationTag tag={ props.subHeadline } /> { props.subHeadlineAfter }</div>
						}
						{ props.text &&
							<div className="text t2"><TranslationTag tag={ props.text } /></div>
						}
					</div>
					<div className="outer-content">
						<div className="content">
							{ props.children }
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Overlay.defaultProps = {
	headline: '',
	subHeadline: '',
	subHeadlineAfter: '',
	text: '',
	backLink: Routes.login.index,
	fullWidth: false,
};

export default Overlay;
