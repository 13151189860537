import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Separator from 'components/forms/separator';
import Input from 'components/forms/input';
import RadioButton from 'components/forms/radio-button';
import { Routes, Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'configuration/useConfig';
import { useAuth } from 'hooks/useAuth';
import * as Yup from 'yup';
import './style.scss';

function CustomerPdf() {
	const { t } = useTranslation('translations');
	const { state, download } = useConfiguratorApi();
	const history = useHistory();
	const { config } = useConfig();
	const { user } = useAuth();
	const isArchive = (history.location.state && history.location.state.project);
	const projectId = isArchive ? history.location.state.project.projectId : state.projectId;
	
	const validationSchema = Yup.object({
		surcharge: Yup.number().typeError('Please use numbers only. Define decimals with a dot.').required(t(Errors.Required)),
	});
	const handleSubmit = async (values) => {
		var fileFormats = [];
		var formatArray = [];
		formatArray['format'] = 'PDF';
		formatArray['type'] = ['Customer'];
		fileFormats.push(formatArray);
		if (fileFormats) {
			const response = await download(fileFormats, values.surcharge.replace(',', '.'), values.foreignCurrency === 'yes' ? true : false, projectId);
			if (response) {
				window.location = config.baseApiUrl + '/Download/' + response.data.downloadFileId;
			}
		}
		history.push(isArchive ? Routes.profile.archive.index : Routes.configuration.summary);
	};
	
	if (!projectId) {
		history.push(Routes.configuration.index);
	}
	
	return (
		<div className="customer-pdf">
			<Overlay
				headline="Configuration_ClientPdfHeadline"
				text="Configuration_ClientPdfText"
				backLink={isArchive ? Routes.profile.archive.index : Routes.configuration.summary}
			>
				<Form
					initialValues={{
						surcharge: '',
						foreignCurrency: 'no',
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<Separator
						text="Configuration_ClientPdfInfoText"
					/>
					<Input
						name="surcharge"
						text="Form_Surcharge"
					/>
					{ user.foreignCurrency &&
						<>
							<Separator
								text="Configuration_ClientPdfInfoText2"
							/>
							<RadioButton
								name="foreignCurrency"
								headline="Configuration_ClientPdfJa"
								value="yes"
								id="yes"
							/>
							<RadioButton
								name="foreignCurrency"
								headline="Configuration_ClientPdfNein"
								value="no"
								id="no"
							/>
						</>
					}
					<Button
						class="btn-primary"
						type="submit"
						text="Configuration_ClientPdfGeneratePdf"
					/>
					<Button
						class="btn-secondary"
						text="Configuration_ClientPdfAbort"
						onClick={() => history.push(isArchive ? Routes.profile.archive.index : Routes.configuration.summary)}
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default CustomerPdf;