import Form from 'components/forms/form';
import Number from 'components/forms/number';
import { Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

function TypeInput({ currentQuestion, onSubmit }) {
	const { t } = useTranslation('translations');
	const validationSchema = Yup.object({
		number: Yup.number(t(Errors.Number)).typeError(t(Errors.Number)).min(currentQuestion.data.minValue, t(Errors.MinNumber).replace('{0}', currentQuestion.data.minValue)).required(t(Errors.Required)),
	});
	return (
		<div className={'note-container' + (currentQuestion.data.image ? ' with-image' : '')}>
			<Form
				initialValues={{ number: currentQuestion.answerId ? currentQuestion.answerId : currentQuestion.data.minValue }}
				validationSchema={validationSchema}
				onSubmit={(values) => onSubmit(values)}
			>
				{ currentQuestion.data.image &&
					<div className="image">
						<img src={currentQuestion.data.image} alt={currentQuestion.data.text} />
					</div>
				}
				<Number
					name="number"
					text={currentQuestion.data.text}
					min={currentQuestion.data.minValue}
					default={currentQuestion.answerId ? currentQuestion.answerId : currentQuestion.data.minValue}
					adjustMin={false}
				/>
			</Form>
		</div>
	);
}

TypeInput.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeInput;