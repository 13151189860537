import React, { useEffect, useState, useRef } from 'react';
import './index.scss';

function DotMenu(props) {
	const [visible, setVisible] = useState(false);
	const myRef = useRef();
	const handleClick = e => {
		if (!myRef.current.contains(e.target)) {
			setVisible(false);
		}
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	});
	
	return (
		<div className="dot-menu" ref={myRef}>
			<div className="opener d-flex flex-column pointer" onClick={() => setVisible(!visible)}>
				<span></span>
				<span></span>
				<span></span>
			</div>
			{visible &&
				<div className="list">
					{props.children}
				</div>
			}
		</div>
	);
}

export default DotMenu;
