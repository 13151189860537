import Tabs from 'components/navigation/tabs';
import PersonalData from './personal-data';
import ChangePassword from './change-password';
import Settings from './settings';
import TranslationTag from 'utils/translations';
import { useAuth } from 'hooks/useAuth';
import { Routes } from 'utils/constants';
import { Switch, Route, NavLink } from 'react-router-dom';
import './style.scss';

function MyData() {
	const { user } = useAuth();
	
	return (
		<div className="my-data">
			<div className="intro d-flex justify-content-between align-items-center">
				<div className="headline t1">
					<TranslationTag tag="Profile_PersonalDataHeadline1" /> { user.firstName + ' ' + user.lastName},<br />
					<TranslationTag tag="Profile_PersonalDataHeadline2" />
				</div>
			</div>
			<div className="data-container">
				<Tabs class="v2">
					<div className="buttons t2">
						<NavLink exact to={Routes.profile.data.index}>
							<TranslationTag tag="Profile_PersonalData" />
						</NavLink>
						<NavLink to={Routes.profile.data.settings}>
							<TranslationTag tag="Profile_ConfiguratorSettings" />
						</NavLink>
					</div>
					<div className="content">
						<Switch>
							<Route exact path={Routes.profile.data.settings}>
								<Settings />
							</Route>
							<Route exact path={Routes.profile.data.changePassword}>
								<ChangePassword />
							</Route>
							<Route path="*">
								<PersonalData />
							</Route>
						</Switch>
					</div>
				</Tabs>
			</div>
		</div>
	);
}

export default MyData;