import { Language } from './constants';

export const getLanguageFromLanguageCode = (code) => {
	if (code && code.split) {
		code = code.split('-')[0];
	}
	
	switch (code) {
		case 'de':
			return Language.German;
		case 'it':
			return Language.Italian;
		case 'en':
			return Language.English;
		case 'fr':
			return Language.French;
		default:
			return null;
	}
};

export const getLanguageCodeFromLanguageEnum = (value) => {
	value = parseInt(value, 10);

	switch (value) {
		case Language.German:
			return 'de';
		case Language.Italian:
			return 'it';
		case Language.English:
			return 'en';
		case Language.French:
			return 'fr';
		default:
			return null;
	}
};

export const getLanguageShortNameFromLanguageEnum = (value) => {
	value = parseInt(value, 10);

	switch (value) {
		case 1:
			return 'DEU';
		case 2:
			return 'ITA';
		case 0:
			return 'ENG';
		case 3:
			return 'FRA';
		default:
			return null;
	}
};