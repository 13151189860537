import './index.scss';
import UserImage from 'components/core/user-image';

function TopNavigation(props) {
	return (
		<div className="top-navigation inverse t4">
			<div className="inner d-flex align-items-center justify-content-between">
				<div className="menu d-flex align-items-center text-nowrap">
					{props.children}
					<UserImage />
				</div>
			</div>
		</div>
	);
}

export default TopNavigation;
