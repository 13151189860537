import { useApiClient } from 'hooks/useApiClient';
import { useLocalStorage } from 'hooks/useLocalStorage';
import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useStore } from 'store/useStore';
import { useImmerReducer } from 'use-immer';
import { Routes } from 'utils/constants';

export const useConfiguratorApi = () => {
	const store = useStore();
	return store.configuratorApi;
};

const initialState = {
	starting: false,
	waitingForAnswer: false,
	configId: null,
	currentQuestionId: null,
	currentPage: null,
	lastPage: null,
	nextPage: null,
	lightRayData: null,
	poleImageFile: null,
	drawingData: null,
	allConfigurationsOfUser: null,
	subElementData: {},
	projectId: null,
	questions: [],
	documents: [],
	documentsCount: 0,
	availableDownloads: [],
	saveId: null,
	variantId: null,
	error: null,
	pages: [
		{
			key: 'product',
			url: Routes.configuration.product,
			disabled: true,
			needsUser: false,
		},
		{
			key: 'light',
			url: Routes.configuration.light,
			disabled: true,
			needsUser: false,
		},
		{
			key: 'control',
			url: Routes.configuration.control,
			disabled: true,
			needsUser: false,
		},
		{
			key: 'pole',
			url: Routes.configuration.pole,
			disabled: true,
			needsUser: false,
		},
		{
			key: 'extras',
			url: Routes.configuration.extras,
			disabled: true,
			needsUser: false,
		},
		{
			key: 'project',
			url: Routes.configuration.project,
			disabled: true,
			needsUser: true,
		},
		{
			key: 'summary',
			url: Routes.configuration.summary,
			disabled: true,
			needsUser: false,
		},
	],
};

const ActionType = {
	RESET: 'reset',
	START: 'start',
	INIT: 'init',
	ANSWER: 'answer',
	NEXT: 'next',
	SKIP: 'skip',
	GOTO: 'goto',
	CHANGE_QUESTION: 'changequestion',
	COMPLETE: 'complete',
	GET_DOCUMENTS: 'getdocuments',
	GET_PDF: 'getpdf',
	GET_PDF_CLIENT: 'getpdfclient',
	GET_CSV_PDF: 'getcsvpdf',
	GET_PHOTOMETRIC: 'getphotometric',
	DELETE: 'delete',
	ERROR: 'error',
	CLEAR_ERROR: 'clearerror',
};

function reducer(draft, action) {
	switch (action.type) {
		case ActionType.START: {
			draft.starting = true;
			draft.questions = [];
			break;
		}
		case ActionType.INIT: {
			draft.configId = action.payload.configId;
			draft.variantId = action.payload.variantId;
			const questions = action.payload.questions;
			let lastQuestion;
			questions.forEach(question => {
				if (question.answer !== '') {
					// generate answer fields and modify question object
					const { value, text, colorCode, colorImage } = getAnswerValues(question, question.answer, action.t);
					question = {
						...question,
						answerId: value,
						answerText: text,
						answerColorCode: colorCode,
						answerColorImage: colorImage,
					};
				}
				if (question.page !== 'project') {
					lastQuestion = question;
				}
				// how do we handle questions in tabs?
				draft.questions.push(question);
			});
			const question = questions[questions.length - 1];
			draft.currentQuestionId = question.identifier;
			draft.lastPage = draft.currentPage;
			draft.currentPage = lastQuestion.page;
			if (action.payload.extraData.LightrayData) {
				draft.lightRayData = action.payload.extraData.LightrayData;
			}
			if (action.payload.extraData.AllConfigurationsOfUser) {
				draft.allConfigurationsOfUser = action.payload.extraData.AllConfigurationsOfUser;
			}

			draft.starting = false;
			updatePages(draft);
			break;
		}
		case ActionType.ANSWER: {
			draft.waitingForAnswer = true;
			const question = draft.questions.find((question) => question.identifier === action.payload.questionId);
			question.answerId = action.payload.answerId;
			question.answerText = action.payload.answerText;
			question.answerColorCode = action.payload.answerColorCode;
			question.answerColorImage = action.payload.answerColorImage;
			break;
		}
		case ActionType.NEXT: {
			draft.waitingForAnswer = false;
			if (action.payload.questionsToDelete) {
				draft.questions = draft.questions.filter(question => action.payload.questionsToDelete.indexOf(question.identifier) === -1);
			}
			
			const question = {
				...action.payload.questions[0],
				drawingData: action.payload.extraData.ELDrawingData ? action.payload.extraData.ELDrawingData : null,
				poleImageFile: action.payload.extraData.PoleImageFile ? action.payload.extraData.PoleImageFile : null,
			};
			
			if (action.payload.extraData) {
				if (action.payload.extraData.LightrayData) {
					draft.lightRayData = action.payload.extraData.LightrayData;
				}

				if (action.payload.extraData.AllConfigurationsOfUser) {
					draft.allConfigurationsOfUser = action.payload.extraData.AllConfigurationsOfUser;
				}
				
				const tabElementDataId = 'TabElementData';
				let tabElementPos;
				if (action.payload.extraData.TabElementData) {
					draft.subElementData[tabElementDataId] = {
						subElements: action.payload.extraData.TabElementData,
					};
					const subElement = draft.subElementData[tabElementDataId].subElements.find((element) => element.isCurrentElement);
					if (subElement) {
						tabElementPos = subElement.elementPos;
						question.subElementDataId = tabElementDataId;
						question.subElementPos = tabElementPos;
					}
				}

				if (action.payload.extraData.SubElementData) {
					let subElementDataId = action.payload.extraData.SubElementData.moduleId;
					draft.subElementData[subElementDataId] = action.payload.extraData.SubElementData;
					const subElement = draft.subElementData[subElementDataId].subElements.find((element) => element.isCurrentElement);
					if (subElement) {
						question.subElementDataId = subElementDataId;
						question.subElementPos = subElement.elementPos;
						if (action.payload.extraData.TabElementData) {
							question.subElementDataParentId = tabElementDataId;
							question.subElementDataParentPos = tabElementPos;
						}
					} else {
						question.showSubElementDataId = subElementDataId;
					}
				}

				if (action.payload.extraData.PoleHeight) {
					const lastQuestion = draft.questions[draft.questions.length - 1];
					if (lastQuestion) {
						const { value, text } = getAnswerValues(lastQuestion, action.payload.extraData.PoleHeight, action.t);
						lastQuestion.answerId = value;
						lastQuestion.answerText = text;
					}
				}
			}
			
			if (action.payload.variantId) {
				draft.variantId = action.payload.variantId;
			}
			
			draft.questions.push(question);
			draft.currentQuestionId = question.identifier;

			updatePages(draft, question);
			if (draft.nextPage !== 'project' && canGoToNextPageInternal(draft)) {
				draft.lastPage = draft.currentPage;
				draft.currentPage = draft.nextPage;
				updatePages(draft);
			}
			break;
		}
		case ActionType.SKIP:
			const question = draft.questions.find((question) => question.identifier === action.payload.questionId);
			question.skipped = true;
			return draft;
		case ActionType.GOTO:
			draft.lastPage = draft.currentPage;
			draft.currentPage = action.payload.page;
			updatePages(draft);
			break;
		case ActionType.CHANGE_QUESTION:
			draft.currentQuestionId = action.payload.question;
			break;
		case ActionType.COMPLETE:
			draft.projectId = action.payload.projectId;
			draft.availableDownloads = action.payload.downloadForecast;
			return draft;
		case ActionType.GET_PDF:
			draft.pdfFileId = action.payload.downloadFileId;
			return draft;
		case ActionType.GET_PDF_CLIENT:
			draft.pdfClientFileId = action.payload.downloadFileId;
			return draft;
		case ActionType.GET_CSV_PDF:
			draft.pdfCSVFileId = action.payload.downloadFileId;
			return draft;
		case ActionType.GET_PHOTOMETRIC:
			draft.photometricFileId = action.payload.downloadFileId;
			return draft;
		case ActionType.GET_SAVEID:
			draft.saveId = action.payload;
			return draft;
		case ActionType.DELETE:
			draft.allConfigurationsOfUser = action.payload;
			return draft;
		case ActionType.GET_DOCUMENTS:
			
			draft.documents = [];
			draft.documentsCount = 0;
			if (action.payload.includes('Document')) {
				draft.documents.push({
					type: 'other',
					title: 'Documents_ProductSheet',
					format: 'WORD/PDF',
					key: 'Document',
				});
				draft.documentsCount += 1;
			}
			
			if (action.payload.includes('Image')) {
				draft.documents.push({
					type: 'other',
					title: 'Documents_ProductImages',
					format: 'JPEG',
					key: 'Image',
				});
				draft.documentsCount += 1;
			}
			
			if (action.payload.includes('Other')) {
				draft.documents.push({
					type: 'other',
					title: 'Documents_CAD',
					format: 'DWG',
					key: 'Other',
				});
				draft.documentsCount += 1;
			}
			
			return draft;
		case ActionType.ERROR:
			draft.waitingForAnswer = false;
			draft.error = action.payload;
			return draft;
		case ActionType.CLEAR_ERROR:
			draft.error = null;
			return draft;
		case ActionType.RESET:
			return initialState;
		default:
			throw new Error(`Action '${action.type}' unknown!`);
	}
}

function updatePages(draft, nextQuestion) {
	let nextPage = null;
	let currentPageFound = false;
	draft.pages.forEach((page) => {
		page.disabled = draft.questions.filter((question) => question.page === page.key).length === 0;
		if (!page.disabled && nextPage === null) {
			if (nextQuestion && nextQuestion.page !== draft.currentPage && nextQuestion.page === page.key) {
				nextPage = page.key;
			}
			if (currentPageFound) {
				nextPage = page.key;
			}
		}
		if (page.key === draft.currentPage) {
			currentPageFound = true;
		}
		if (page.key === 'project' && draft.projectId === null) {
			page.disabled = true;
		}
	});

	draft.nextPage = nextPage;
}

function canGoToNextPageInternal(state) {
	const nextPageQuestions = selectQuestionsByPage(state, state.nextPage, true);
	if (nextPageQuestions.length === 0) {
		return false;
	}
	const nextPageQuestion = nextPageQuestions[nextPageQuestions.length - 1];
	let currentQuestionIndex = -1;
	let nextPageQuestionIndex = -1;

	for (let index = 0; index < state.questions.length; index++) {
		const question = state.questions[index];
		if (question.identifier === state.currentQuestionId) {
			currentQuestionIndex = index;
		}

		if (question.identifier === nextPageQuestion.identifier) {
			nextPageQuestionIndex = index;
		}

		if (currentQuestionIndex > -1 && nextPageQuestionIndex > -1) {
			break;
		}
	}
	return currentQuestionIndex <= nextPageQuestionIndex;
}

export const useConfiguratorApiState = (auth) => {
	const configKey = 'configurator:configId';
	const history = useHistory();
	const location = useLocation();
	const apiClient = useApiClient(auth);
	const { t } = useTranslation('translations');
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const [storedConfigId, setStoredConfigId] = useLocalStorage(configKey); // move this to the reducer as well?

	useEffect(() => {
		const destinationPath = Routes.configuration[state.currentPage];
		const ignoredPaths = [
			Routes.configuration.favorites,
			Routes.configuration.defaults,
			Routes.configuration.defaultsSelectConfiguration,
			Routes.configuration.downloads,
			Routes.configuration.customerPdf.index,
			Routes.configuration.ipea.index,
			Routes.configuration.assistance.index,
			Routes.configuration.assistance.success,
			Routes.configuration.order.index,
			Routes.configuration.order.success,
			Routes.configuration.share.index,
			Routes.configuration.share.success,
			Routes.configuration.login,
			Routes.configuration.restoreConfiguration,
			Routes.configuration.restoreConfigurations,
			Routes.configuration.registrationOverlay,
		];
		
		if (state.lastPage !== state.currentPage &&
			location.pathname !== destinationPath &&
			(ignoredPaths.indexOf(location.pathname) === -1 && location.pathname.indexOf(Routes.configuration.index) >= 0)
		) {
			history.push(destinationPath);
		}

		if (auth.user && auth.user.isLoggedIn) {
			if (auth.user.enforcePasswordUpdate && location.pathname !== Routes.profile.data.changePassword) {
				history.push(Routes.profile.data.changePassword);
			}
		}
	}, [history, state.currentPage, state.lastPage, location.pathname, auth.user]);
	
	useEffect(() => {
		if (state.configId) {
			getDocuments(state.configId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.currentQuestionId, state.configId]);

	const start = useCallback(async (entrySetting = null) => {
		dispatch({ type: ActionType.RESET });
		dispatch({ type: ActionType.START });
		if (entrySetting === null) {
			entrySetting = auth.user ? auth.user.entrySetting : 0;
		}

		const response = await apiClient.get('/answer/' + entrySetting, null, false);
		setStoredConfigId(response.data.configId);
		dispatch({ type: ActionType.INIT, payload: response.data, t });
	}, [dispatch, apiClient, setStoredConfigId, auth.user, t]);

	const answer = useCallback(async (questionId, answerId, answerText, answerColorCode = '', answerColorImage = '') => {
		if (state.currentQuestion === null) {
			return;
		}
		
		if (!questionId) {
			return;
		}
		
		const payload = {
			questionId,
			answerId,
			answerText,
			answerColorCode,
			answerColorImage,
		};
		
		dispatch({ type: ActionType.CLEAR_ERROR });
		dispatch({ type: ActionType.ANSWER, payload });
		
		const data = qs.stringify({
			questionId,
			answerId: typeof answerId === 'object' ? JSON.stringify(answerId) : answerId,
		});
		
		try {
			const response = await apiClient.post(`/answer/${storedConfigId}`, data);
			console.log('answer response', response);

			dispatch({ type: ActionType.NEXT, payload: response.data, t });
		} catch (error) {
			console.log('answer error', error.response);
			dispatch({ type: ActionType.ERROR, payload: error });
		}
	}, [apiClient, dispatch, state.currentQuestion, storedConfigId, t]);

	const reset = useCallback(() => {
		dispatch({ type: ActionType.RESET });
		setStoredConfigId(null);
	}, [dispatch, setStoredConfigId]);
	
	const complete = useCallback(async () => {
		let currentConfigIDs = storedConfigId;
		if (state.allConfigurationsOfUser) {
			currentConfigIDs = state.allConfigurationsOfUser.map((config) => config.id).join('.');
		}
		
		const data = qs.stringify({
			'configsStr': currentConfigIDs,
		});
		
		try {
			const response = await apiClient.post('/superkey', data);
			console.log('complete response', response);
			
			dispatch({ type: ActionType.COMPLETE, payload: response.data });
		} catch (error) {
			console.log(error.response);
			dispatch({ type: ActionType.ERROR, payload: error });
		}
	}, [apiClient, dispatch, state.allConfigurationsOfUser, storedConfigId]);
	
	const deleteConfiguration = useCallback(async (configId) => {
		if (!configId) {
			return;
		}
		
		try {
			const response = await apiClient.delete('/answer/' + configId);
			dispatch({ type: ActionType.DELETE, payload: response.data.AllConfigurationsOfUser });
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch]);

	const getDocuments = useCallback(async (projectId) => {
		if (!projectId) {
			return;
		}
		
		try {
			const response = await apiClient.get('/Fileserver/FilesOnTheFly/' + storedConfigId, null, false);
			console.log('getDocuments response', response);
			dispatch({ type: ActionType.GET_DOCUMENTS, payload: response.data });
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, dispatch, storedConfigId]);
	
	const downloadFilesOnTheFly = useCallback(async (fileFormats) => {
		if (!storedConfigId || !fileFormats) {
			return;
		}
		
		const data = qs.stringify({
			'type': fileFormats,
		});
		
		try {
			return await apiClient.post('/Fileserver/FilesOnTheFly/' + storedConfigId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, storedConfigId]);
	
	const download = useCallback(async (fileFormats, customerPdfMarkUp = '', foreignCurrency = false, projectId = null) => {
		const downloadProjectId = state.projectId ?? projectId;
		if (!downloadProjectId || !fileFormats) {
			return;
		}
		
		const data = qs.stringify({
			'fileFormats': fileFormats,
			'customerPdfMarkUp': customerPdfMarkUp,
			'foreignCurrency': foreignCurrency,
		});
		
		try {
			return await apiClient.post('/Project/' + downloadProjectId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient, state.projectId]);
	
	const projectDownload = useCallback(async (fileFormats, projectId, customerPdfMarkUp = '', foreignCurrency = false) => {
		if (!projectId || !fileFormats) {
			return;
		}
		
		const data = qs.stringify({
			'fileFormats': fileFormats,
			'customerPdfMarkUp': customerPdfMarkUp,
			'foreignCurrency': foreignCurrency,
		});
		
		try {
			return await apiClient.post('/Project/' + projectId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const configurationDownload = useCallback(async (fileFormats, configId, customerPdfMarkUp = '', foreignCurrency = false) => {
		if (!configId || !fileFormats) {
			return;
		}
		
		const data = qs.stringify({
			'fileFormats': fileFormats,
			'customerPdfMarkUp': customerPdfMarkUp,
			'foreignCurrency': foreignCurrency,
		});
		
		try {
			return await apiClient.post('/configuration/' + configId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const reportError = useCallback(async (properties) => {
		const data = qs.stringify(properties);
		
		try {
			const response = await apiClient.post('/report', data);
			return response;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const clearError = useCallback(() => {
		dispatch({ type: ActionType.CLEAR_ERROR });
	}, [dispatch]);
	
	const sendAssistance = useCallback(async (configurationProperties, projectId) => {
		if (!projectId) {
			return;
		}
		
		const data = qs.stringify(configurationProperties);
		
		try {
			return await apiClient.put('/Project/Assistance/' + projectId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const share = useCallback(async (pcpSaveId, recipients) => {
		if (!pcpSaveId) {
			return;
		}
		
		const data = qs.stringify({
			'recipients': recipients,
		});
		
		try {
			return await apiClient.post('/pcp/share/' + pcpSaveId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const sendOrder = useCallback(async (orderProps, projectId) => {
		if (!projectId) {
			return;
		}
		
		const data = qs.stringify(orderProps);
		
		try {
			return await apiClient.post('/Project/Order/' + projectId, data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const getDefaults = useCallback(async (variantId) => {
		try {
			const response = await apiClient.get('/pcp/default', null, false);
			if (variantId) {
				return response.data[variantId] || [];
			} else {
				return response.data;
			}
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const createDefault = useCallback(async (defaultProps) => {
		const data = qs.stringify(defaultProps);
		try {
			return await apiClient.post('/pcp/default', data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const updateDefaults = useCallback(async (defaults) => {
		if (!defaults) {
			return;
		}
		
		const options = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		
		try {
			return await apiClient.put('/pcp/default', defaults, options);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const deleteDefault = useCallback(async (pcpSaveId) => {
		if (!pcpSaveId) {
			return;
		}
		
		try {
			return await apiClient.delete('/pcp/default/' + pcpSaveId);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const getFavorites = useCallback(async (variantId) => {
		try {
			const response = await apiClient.get('/pcp/favorite', null, false);
			if (variantId) {
				return response.data[variantId];
			} else {
				return response.data;
			}
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const createFavorite = useCallback(async (favoriteProps) => {
		const data = qs.stringify(favoriteProps);
		try {
			return await apiClient.post('/pcp/favorite', data);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const deleteFavorite = useCallback(async (pcpSaveId) => {
		if (!pcpSaveId) {
			return;
		}
		
		try {
			return await apiClient.delete('/pcp/favorite/' + pcpSaveId);
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);
	
	const getOrderInfo = useCallback(async (projectId) => {
		if (!projectId) {
			return;
		}
		
		try {
			const response = await apiClient.get('/Project/Order/' + projectId, null, false);
			return response.data;
		} catch (error) {
			console.log(error.response);
		}
	}, [apiClient]);

	const loadSharedConfig = useCallback(async (sharedAuthToken = null) => {
		if (!sharedAuthToken) {
			return;
		}

		dispatch({ type: ActionType.RESET });
		dispatch({ type: ActionType.START });
		
		const response = await apiClient.get(`pcp/share/${sharedAuthToken}`);
		setStoredConfigId(response.data.configurationId);
		dispatch({ type: ActionType.INIT, payload: response.data.response, t });
	}, [apiClient, dispatch, setStoredConfigId, t]);
	
	const loadConfig = useCallback(async (pcpSaveId = null) => {
		if (!pcpSaveId) {
			return;
		}
		
		dispatch({ type: ActionType.RESET });
		dispatch({ type: ActionType.START });
		
		const response = await apiClient.get(`pcp/${pcpSaveId}`);
		setStoredConfigId(response.data.configurationId);
		dispatch({ type: ActionType.INIT, payload: response.data.response, t });
	}, [apiClient, dispatch, setStoredConfigId, t]);
	
	// #region paging
	const canGoTo = useCallback((page) => selectQuestionsByPage(state, page, true).length > 0, [state]);

	const canGoToNextPage = useCallback(() => {
		return canGoToNextPageInternal(state);
	}, [state]);

	const goTo = useCallback((page) => dispatch({ type: ActionType.GOTO, payload: { page }}), [dispatch]);
	
	const changeCurrentQuestion = useCallback((question) => dispatch({ type: ActionType.CHANGE_QUESTION, payload: { question }}), [dispatch]);

	const goToNextPage = useCallback(async () => {
		const questions = selectQuestionsByPage(state, state.nextPage, true);
		if (questions.length === 1) {
			let question = questions[0];
			changeCurrentQuestion(question.identifier);
		}
		goTo(state.nextPage);
	}, [changeCurrentQuestion, goTo, state]);
	// #endregion

	useEffect(() => {
		const currentQuestion = selectCurrentQuestion(state);
		if (currentQuestion && currentQuestion.skippable && currentQuestion.data.default !== undefined && !currentQuestion.skipped) {
			dispatch({ type: ActionType.SKIP, payload: { questionId: currentQuestion.identifier }});
			const { value, text, colorCode, colorImage } = getAnswerValues(currentQuestion, currentQuestion.data.default, t);
			answer(currentQuestion.identifier, value, text, colorCode, colorImage);
		}
	}, [state, dispatch, answer, t]);

	return {
		state,
		start,
		answer,
		reset,
		complete,
		deleteConfiguration,
		getDocuments,
		downloadFilesOnTheFly,
		reportError,
		sendAssistance,
		share,
		loadSharedConfig,
		loadConfig,
		sendOrder,
		getOrderInfo,
		getDefaults,
		createDefault,
		deleteDefault,
		updateDefaults,
		getFavorites,
		createFavorite,
		deleteFavorite,
		download,
		projectDownload,
		configurationDownload,
		canGoTo,
		canGoToNextPage,
		goTo,
		goToNextPage,
		changeCurrentQuestion,
		clearError,
	};
};

// todo: extend this with other question types if needed
const getAnswerValues = (question, answer, t) => {
	const result = {
		value: answer,
		text: answer,
		colorCode: '',
		colorImage: '',
	};

	// correct?
	if (answer === null) {
		return result;
	}

	const answerObject = question.data.answers?.find((a) => a.id + '' === result.value + '');
	// const answerRow = question.data.tableRows?.find((row) => row[0] + '' === result.value + '');
	switch (question.type) {
		case 'Radio':
		case 'RadioSmall':
		case 'ImageRadio':
			if (answerObject) {
				result.text = answerObject.text;
			}
			break;

		case 'ImageRadioDefault':
			if (answerObject) {
				result.text = answerObject.name;
			}
			break;
	
		case 'Range':
			if (question.data.unit) {
				result.text += ' ' + question.data.unit;
			}
			break;
	
		case 'RadioDeviceColor':
			if (answerObject) {
				result.text = answerObject.name;
				result.colorCode = answerObject.hexCodeColor ?? '';
				result.colorImage = answerObject.colorGraphic ?? '';
			}
			break;
	
		case 'Table':
			result.text += ' ' + question.data.unit;
			break;

		case 'StandAlone':
			result.value = JSON.parse(result.value);
			result.text = result.value.Remaining + '% | ' + result.value.Start + ' - ' + result.value.End + ' ' + t('Configuration_Time');
			break;

		default:
			break;
	}
	
	return result;
};

const findOrCreateQuestionElement = (list, questionList, identifier, options) => {
	const { page, active, subElementData } = options;

	let questionElement = list.find((element) => element.identifier === identifier);
	if (!questionElement) {
		questionElement = {
			...subElementData,
			identifier,
			type: 'subElements',
			page,
			active,
			flattenSubElements: subElementData.familyName === 'CN' || subElementData.familyName === 'ECLIPSE',
			flattenedQuestions: [],
			subElements: subElementData.subElements.map((subElement) => ({
				...subElement,
				type: 'subElement',
				active: false,
				questions: [],
				flattenedQuestions: [],
			})),
		};
		questionList.push(questionElement);
	}

	return questionElement;
};

export const makeSelectCurrentQuestion = () => {
	return createSelector(
		[
			(state) => state.questions,
			(state) => state.currentQuestionId,
		],
		(questions, currentQuestionId) => {
			if (!questions || !currentQuestionId) {
				return null;
			}

			return questions.find((question) => question.identifier === currentQuestionId);
		},
	);
};

export const selectCurrentQuestion = makeSelectCurrentQuestion();

export const makeSelectQuestionsByPage = () => {
	return createSelector(
		[
			(state) => state.questions,
			(_, page) => page,
			(_, __, flatten = false) => flatten,
			(state) => state.subElementData,
			(state) => state.currentQuestionId,
		],
		(questions, page, flatten, subElementDataContainer, currentQuestionId) => {
			if (!questions || !page) {
				return [];
			}

			return questions.reduce((list, question) => {
				if (question.page !== page) {
					return list;
				}

				const subElementData = subElementDataContainer[question.subElementDataId];
				if (!subElementData || flatten) {
					list.push(question);
					return list;
				}
				
				let questionList = list;
				let questionElement, parentQuestionElement, parentQuestionSubElement;
				if (question.subElementDataParentId) {
					const parentElementData = subElementDataContainer[question.subElementDataParentId];
					parentQuestionElement = findOrCreateQuestionElement(list, list, question.subElementDataParentId, {
						page: question.page,
						active: question.identifier === currentQuestionId,
						subElementData: parentElementData,
					});
				
					parentQuestionSubElement = parentQuestionElement.subElements.find((subElement) => subElement.elementPos === question.subElementDataParentPos);
					questionList = parentQuestionSubElement.questions;
				}
				questionElement = findOrCreateQuestionElement(questionList, questionList, question.subElementDataId, {
					page: question.page,
					active: question.identifier === currentQuestionId,
					subElementData,
				});

				const subElement = questionElement.subElements.find((subElement) => subElement.elementPos === question.subElementPos);
				subElement.questions.push(question);
				if (questionElement.flattenSubElements) {
					questionElement.flattenedQuestions.push(question);
				}
				subElement.active = !!subElement.questions.find((q) => q.identifier === currentQuestionId || q.active);
				questionElement.active = !!questionElement.subElements.find((sub) => sub.active);
				
				if (parentQuestionSubElement) {
					parentQuestionSubElement.active = !!parentQuestionSubElement.questions.find((q) => q.active);
					if (questionElement.flattenSubElements) {
						parentQuestionSubElement.flattenedQuestions.push(question);
					}
				}
				if (parentQuestionElement) {
					parentQuestionElement.active = !!parentQuestionElement.subElements.find((sub) => sub.active);
				}

				return list;
			}, []);
		},
	);
};

export const selectQuestionsByPage = makeSelectQuestionsByPage();

export const makeSelectDocumentsByCategory = () => {
	return createSelector(
		[
			(state) => state.documents,
			(_, category) => category,
		],
		(documents, category) => {
			if (!documents || !category) {
				return [];
			}

			return documents.filter((document) => document.type === category);
		},
	);
};

export const selectDocumentsByCategory = makeSelectDocumentsByCategory();

export const makeSelectQuestionsGroupedByPage = () => {
	return createSelector(
		[
			(state) => state.questions,
			(state) => state.pages,
		],
		(questions, pages) => {
			if (!questions) {
				return [];
			}
			
			return pages.map((page) => ({
				page: page.key,
				questions: questions.filter((question) => question.page === page.key && question.answerId),
			})).filter((group) => group.questions.length > 0);
		},
	);
};

export const selectQuestionsGroupedByPage = makeSelectQuestionsGroupedByPage();
