import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import './index.scss';

function Search(props) {
	const [focused, setFocused] = useState(false);
	return (
		<Field name={props.name}>
			{({ field, meta }) => (
				<div className={'field search type-text' + (focused || field.value !== '' ? ' has-value' : '') + ( meta.error ? ' error' : '') }>
					<label htmlFor={props.name}><TranslationTag tag={props.text} /></label>
					<input
						type="text"
						value={props.value}
						{...field}
						onFocus={(e) => setFocused(true)}
						onBlur={(e) => {
							setFocused(false);
							field.onBlur(e);
						}}
					/>
					<button type="submit" className="icon-container">
						<Icon
							icon="search"
							width="22"
							height="22"
						/>
					</button>
					{ meta.error &&
						<div>
							<div className="error-text"><ErrorMessage name={props.name} /></div>
						</div>
					}
				</div>
			)}
		</Field>
	);
}

Search.defaultProps = {
	name: '',
	text: '',
	value: '',
};

export default Search;
