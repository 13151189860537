import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Input from 'components/forms/input';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes,Errors } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useState } from 'react';
import * as Yup from 'yup';
import './style.scss';

function FolderDuplicate() {
	const { duplicateArchiveFolder } = useArchiveApi();
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	const { t } = useTranslation('translations');
	const validationSchema = Yup.object({
		folderName: Yup.string().required(t(Errors.Required)),
	});
	const initialValues = {
		folderName: (history.location.state && history.location.state.folder) ? (history.location.state.folder.folderName + ' - ' +  t('Archive_DuplicateFolderCopy')): '',
	};
	
	const handleSubmit = async (values) => {
		setIsLoading(true);
		await duplicateArchiveFolder({
			...history.location.state.folder,
			folderName: values.folderName,
		}, history.location.state.folderId);
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	if (!history.location.state || !history.location.state.folder) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="folder-duplicate">
			<Overlay
				headline="Archive_DuplicateFolderHeadline"
				text="Archive_DuplicateFolderText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<Input
						name="folderName"
						text="Form_FolderName"
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_DuplicateFolderSubmit"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default FolderDuplicate;