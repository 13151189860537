import Link from 'components/core/link';
import Tabs from 'components/navigation/tabs';
import Product from 'components/core/product';
import React, { useState, useEffect } from 'react';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useTranslation } from 'react-i18next';

function TypeDefaults ({ currentQuestion }) {
	const [tab, setTab] = useState('default');
	const { answer, getFavorites, getDefaults, loadConfig, state } = useConfiguratorApi();
	const [loadingFavorites, setLoadingFavorites] = useState(false);
	const [favorites, setFavorites] = useState(null);
	const [loadingDefaults, setLoadingDefaults] = useState(false);
	const [defaults, setDefaults] = useState(null);
	const { t } = useTranslation('translations');
	
	useEffect(() => {
		const load = async () => {
			setFavorites(await getFavorites(state.variantId));
			setLoadingFavorites(false);
		};
		if (favorites === null && loadingFavorites === false) {
			setLoadingFavorites(true);
			load();
		}
	}, [state.variantId, favorites, getFavorites, loadingFavorites, setFavorites, setLoadingFavorites]);
	
	useEffect(() => {
		const load = async () => {
			let response = await getDefaults(state.variantId);
			setDefaults(response);
			setLoadingDefaults(false);
			if (response.length <= 0) {
				setTab('favorites');
			}
		};
		if (defaults === null && loadingDefaults === false) {
			setLoadingDefaults(true);
			load();
		}
	}, [state.variantId, defaults, getDefaults, loadingDefaults, setDefaults, setLoadingDefaults]);
	
	return (
		<div className="defaults-container">
			<Tabs class="v2">
				<div className="buttons t2">
					{ (defaults && defaults.length > 0) &&
						<Link
							class={tab === 'default' ? 'active': ''}
							text={'Configuration_DefaultConfiguration'}
							onClick={() => setTab('default')}
						/>
					}
					{ (favorites && favorites.length > 0) &&
						<Link
							class={(tab === 'favorites' || (defaults && defaults.length <= 0)) ? 'active': ''}
							text={'Configuration_Favorites'}
							onClick={() => setTab('favorites')}
						/>
					}
				</div>
				<div className="content">
					{ tab === 'default' ?
						defaults &&
							defaults.map((defaultConfig, key) => (
								<Product
									key={key}
									name={defaultConfig.family}
									image={defaultConfig.image}
									summary={defaultConfig.summary}
									onClick={() => loadConfig(defaultConfig.id)}
									tooltipText={defaultConfig.tooltip}
								/>
							))
						: (
							favorites &&
							favorites.map((favorite, key) => (
								<Product
									key={key}
									name={favorite.family}
									image={favorite.image}
									summary={favorite.summary}
									onClick={() => loadConfig(favorite.id)}
									tooltipText={favorite.tooltip}
								/>
							))
						)}
					{ currentQuestion.data.answers &&
						currentQuestion.data.answers.map((answerObject, key) => (
							<Product
								key={key}
								name={t('Configuration_Custom')}
								image={answerObject.image}
								summary={answerObject.text}
								onClick={() => answer(currentQuestion.identifier, answerObject.id, answerObject.name)}
								tooltipText={answerObject.tooltip}
							/>
						))
					}
				</div>
			</Tabs>
		</div>
	);
}

TypeDefaults.defaultProps = {
	currentQuestion: '',
};

export default TypeDefaults;