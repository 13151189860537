import Button from 'components/core/button';
import Tags from 'components/core/tags';
import React, { useState } from 'react';

function TypeTags({ currentQuestion, onSubmit }) {
	const [tags, setTags] = useState(currentQuestion.answerId);
	return (
		<div className="tags-outercontainer">
			<Tags
				onChange={(values) => setTags(values)}
				defaultTags={currentQuestion.answerId}
				suggestions={currentQuestion.data.tags}
			/>
			<div className="submit-button">
				<Button
					text="Configuration_SaveAndContinue"
					class="btn-primary"
					onClick={() => onSubmit(tags)}
				/>
			</div>
		</div>
	);
}

TypeTags.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeTags;