export const Errors = {
	EMail: 'Alert_Email',
	Required: 'Alert_Required',
	Privacy: 'Alert_PrivacyRequired',
	PasswordMatch: 'Alert_PasswordMatch',
	PasswordLength: 'AlertCheckPasswordSecurity4',
	LowerCaseCharacter: 'AlertCheckPasswordSecurity2',
	UpperCaseCharacter: 'AlertCheckPasswordSecurity3',
	NumberCharacter: 'AlertCheckPasswordSecurity5',
	SpecialCharacter: 'AlertCheckPasswordSecurity6',
	MinNumber: 'Alert_MinNumber',
	MaxNumber: 'Alert_MaxNumber',
	Number: 'Alert_Number',
	Steps: 'Alert_Steps',
};

const configurationBasePath = '/configuration';
const loginBasePath = '/login';
const profileBasePath = '/profile';
const registrationBasePath = '/registration';

export const Routes = {
	configuration: {
		index: configurationBasePath,
		product: configurationBasePath + '/product',
		light: configurationBasePath + '/light',
		control: configurationBasePath + '/control',
		pole: configurationBasePath + '/pole',
		extras: configurationBasePath + '/extras',
		project: configurationBasePath + '/project',
		summary: configurationBasePath + '/summary',
		favorites: configurationBasePath + '/favorites',
		defaults: configurationBasePath + '/defaults',
		restoreConfigurations: configurationBasePath + '/edit/configurations',
		restoreConfiguration: configurationBasePath + '/edit',
		defaultsSelectConfiguration: configurationBasePath + '/defaults/configuration',
		downloads: configurationBasePath + '/downloads',
		login: configurationBasePath + '/login',
		registrationOverlay: configurationBasePath + '/registration/overlay',
		customerPdf: {
			index: configurationBasePath + '/customer-pdf',
		},
		ipea: {
			index: configurationBasePath + '/ipea',
		},
		assistance: {
			index: configurationBasePath + '/assistance',
			success: configurationBasePath + '/assistance/success',
		},
		order: {
			index: configurationBasePath + '/order',
			success: configurationBasePath + '/order/success',
		},
		share: {
			index: configurationBasePath + '/share',
			success: configurationBasePath + '/share/success',
		},
	},
	login: {
		index: loginBasePath,
		guest: loginBasePath + '/guest',
		passwordReset: {
			index: loginBasePath + '/password-reset',
			success: loginBasePath + '/password-reset/success',
		},
	},
	passwordRecovery: '/passwordrecovery',
	profile: {
		index: profileBasePath,
		archive: {
			index: profileBasePath + '/archive',
			projects: profileBasePath + '/archive/projects',
			favorites: profileBasePath + '/archive/favorites',
			drafts: profileBasePath + '/archive/drafts',
			groups: profileBasePath + '/archive/groups',
			search: profileBasePath + '/archive/search',
			folder: {
				delete: profileBasePath + '/archive/folder/delete',
				duplicate: profileBasePath + '/archive/folder/duplicate',
				edit: profileBasePath + '/archive/folder/edit',
				create: profileBasePath + '/archive/folder/create',
			},
			project: {
				duplicate: profileBasePath + '/archive/project/duplicate',
				changeFolder: profileBasePath + '/archive/project/change-folder',
				changeLanguage: profileBasePath + '/archive/project/change-language',
				share: profileBasePath + '/archive/project/share',
				revisions: profileBasePath + '/archive/project/revisions',
				remove: profileBasePath + '/archive/project/remove',
				price: profileBasePath + '/archive/project/price',
				pdf: profileBasePath + '/archive/project/pdf',
			},
		},
		data: {
			index: profileBasePath + '/data',
			changePassword: profileBasePath + '/data/change-password',
			settings: profileBasePath + '/data/settings',
		},
	},
	registration: {
		index: registrationBasePath,
		success: registrationBasePath + '/success',
		doubleOptIn: '/confirmuser/:signupAuthToken',
	},
	styleGuide: '/style-guide',
	help: '/help',
	share: '/share/:sharedAuthToken',
	reportErrorSuccess: '/report-error',
};

export const FileExtensions = {
	pdf: 'PDF',
	dwg: 'DWG',
	jpeg: 'JPEG',
	csv: 'CSV',
	ies: 'IES',
	word: 'WORD',
	ldt: 'LDT',
	plot: 'PLOT',
};

export const DownloadExtensions = (format) => {
	switch (format) {
		case 'Document':
			return 'WORD/PDF';
		case 'Image':
			return 'JPEG';
		default:
			return 'DWG';
	}
};

export const DownloadTranslations = (format) => {
	switch (format) {
		case 'Document':
			return 'Documents_ProductSheet';
		case 'Image':
			return 'Documents_ProductImages';
		default:
			return 'Documents_CAD';
	}
};

export const PageTranslationKeys = {
	product: 'Configuration_Product',
	light: 'Configuration_Light',
	control: 'Configuration_Control',
	pole: 'Configuration_Pole',
	extras: 'Configuration_AdditionalInfos',
	project: 'Configuration_ProjectDetails',
	summary: 'Configuration_Summary',
};

export const ScreenSizes = {
	small: 524,
	medium: 824,
	large: 1124,
};

export const SubElementViewWidths = (family) => {
	switch (family.familyName) {
		case 'CN':
		case 'ECLIPSE':
			return 725;
		case 'F-System':
			if (family.variant === 'F-System M') {
				return 725;
			} else {
				return 436;
			}
		case 'SM':
		case 'AM':
		case 'DA':
		case 'CO':
			return 554;
		default:
			return 436;
	}
};

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function Genders (t, genderId = '') {
	var allGenders = [
		{ value: 0, label: t('Form_Undisclosed') },
		{ value: 1, label: t('Form_Mrs') },
		{ value: 2, label: t('Form_Mr') },
		{ value: 3, label: t('Form_Other') },
	];
	
	if (genderId) {
		return allGenders.find(obj => obj.value === genderId);
	} else {
		return allGenders;
	}
};

export function Times () {
	let times = [];
	for (let i = 0; i <= 23; i++) {
		let number = i;
		if (i < 10) {
			number = '0' + number;
		}
		times.push({
			value: number + ':00', label: number + ':00',
		});
		if (i < 24) {
			times.push({
				value: number + ':30', label: number + ':30',
			});
		}
	}
	return times;
}

export const Language = {
	English: 3,
	German: 1,
	Italian: 2,
	French: 4,
};

export const ClientData = {
	EMail: 'support.configurator@ewo.com',
	Phone: '+39 0471 623087',
};

export function StaticUrls (key, config = '', lang = 'en') {
	switch (key) {
		case 'plannerMode':
			return config.baseApiUrl + '/plannermode/';
		case 'privacy1':
			switch (lang) {
				case 'de':
					return 'https://www.ewo.com/de/unternehmen/impressum/mitteilung-zur-datenschutzverordnung';
				case 'it':
					return 'https://www.ewo.com/it/azienda/impressum/comunicazioni-relative-al-regolamento-generale-sulla-protezione-dei-dati';
				case 'fr':
					return 'https://www.ewo.com/fr/entreprise/impressum/avis-concernant-le-reglement-general-sur-la-protection-des-donnees';
				default:
					return 'https://www.ewo.com/company/imprint/notice-pursuant-to-the-gdpr-2016-679';
			}
		case 'privacy2':
			switch (lang) {
				case 'de':
					return 'https://www.ewo.com/de/unternehmen/impressum/datenschutzerklaerung-zur-verarbeitung-personenbezogener-daten-fuer-bewerber-im-sinne-von-art-13-verordnung-eu-2016-679-datenschutz-grundverordnung';
				case 'it':
					return 'https://www.ewo.com/it/azienda/impressum/informativa-sul-trattamento-dei-dati-personali-ai-sensi-dellart-13-del-regolamento-ue-679-2016';
				case 'fr':
					return 'https://www.ewo.com/fr/entreprise/impressum/informations-sur-le-traitement-des-donnees-personnelles-conformement-a-lart-13-du-reglement-ue-679-2016';
				default:
					return 'https://www.ewo.com/company/imprint/privacy-policy-statement-in-accordance-with-article-13-of-regulation-eu-2016-679';
			}
		case 'agb':
			switch (lang) {
				case 'de':
					return 'https://www.ewo.com/de/unternehmen/agb-ewo-gmbh';
				case 'it':
					return 'https://www.ewo.com/it/azienda/condizioni-di-vendita';
				case 'fr':
					return 'https://www.ewo.com/fr/entreprise/conditions-de-vente';
				default:
					return 'https://www.ewo.com/company/terms-and-conditions-ewo-gmbh';
			}
		default:
			return '';
	}
};