import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

let translations = null;
let initialized = false;

const languages = [];
const namespace = 'translations';

export const init = (options = {}) => {
	if (initialized) {
		return;
	}
	initialized = true;
	
	const { baseResourceUrl } = options;
	i18next
		.use(BackendAdapter)
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			backend: {
				backend: Backend,
				backendOption: {
					allowMultiLoading: true,
					loadPath: baseResourceUrl + '/resource',
					withCredentials: true,
					crossDomain: true,
					parse: (data) => {
						translations = JSON.parse(data);
						return translations;
					},
				},
			},
			load: 'languageOnly',
			// lng: 'de',
			fallbackLng: 'de',
			// preload: ['en', 'de', 'it', 'fr'],
			// supportedLngs: ['en', 'de', 'it', 'fr'],
			debug: false,
			ns: [namespace],
			defaultNS: namespace,
			keySeparator: false,
			nsSeparator: false,
			interpolation: {
				escapeValue: false,
			},
			// react: {
			// 	useSuspense: false,
			// },
		}).then(() => {
			if (!translations) {
				return;
			}
	
			for (const language in translations) {
				if (!Object.hasOwnProperty.call(translations, language)) {
					continue;
				}
	
				if (!translations[language][namespace]) {
					continue;
				}
	
				languages.push(language);
	
				if (i18next.hasResourceBundle(language, namespace)) {
					continue;
				}
	
				i18next.addResourceBundle(language, namespace, translations[language][namespace]);
			}
	
			// i18next.languages = languages;
		});
};

export {
	namespace,
	languages,
};

export default i18next;