import TranslationTag from 'utils/translations';
import './index.scss';

function Separator(props) {
	return (
		<div className={`t2 separator${props.class ? ' ' + props.class : ''}`}><TranslationTag tag={props.text} /></div>
	);
}

Separator.defaultProps = {
	class: '',
	text: '',
};

export default Separator;
