import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from 'pages/login';
import PasswordReset from 'pages/login/password-reset';
import PasswordResetSuccess from 'pages/login/password-reset/success';
import PasswordRecovery from 'pages/login/password-recovery';
import Registration from 'pages/registration';
import RegistrationSuccess from 'pages/registration/success';
import Configuration from 'pages/configuration';
import Profile from 'pages/profile';
import Favorites from 'pages/configuration/favorites';
import StyleGuide from 'pages/style-guide';
import { Routes } from 'utils/constants';
import { useAuth } from 'hooks/useAuth';
import PrivateRoute from 'components/system/private-route';
import ReportTool from 'components/custom/report-tool';
import Assistance from 'pages/configuration/assistance';
import Order from 'pages/configuration/order';
import OrderSuccess from 'pages/configuration/order/success';
import ConfigurationShare from 'pages/configuration/share';
import ConfigurationShareSuccess from 'pages/configuration/share/success';
import AssistanceSuccess from 'pages/configuration/assistance/success';
import Share from 'pages/share';
import MobileView from 'components/custom/mobile-view';
import GlobalLoader from 'components/system/global-loader';
import CustomerPdf from 'pages/configuration/customer-pdf';
import Ipea from 'pages/configuration/ipea';
import DoubleOptIn from 'pages/registration/doubleOptIn';
import LoginOnPage from 'pages/login/overlay';
import ReportToolSuccess from 'components/custom/report-tool/success';
import RegistrationOverlay from 'pages/registration/overlay';
import ProductDownloads from 'pages/configuration/downloads';
import DefaultsConfig from 'pages/configuration/defaults/selectConfig';
import Defaults from 'pages/configuration/defaults';
import GlobalOverlay from 'components/system/global-overlay';
import EditConfigurations from 'pages/configuration/edit';
import EditConfiguration from 'pages/profile/archive/editConfiguration';

export default function Layout() {
	const auth = useAuth();
	const [isLocalLoading, setLocalLoading] = useState(true);

	useEffect(() => {
		if (auth.isStatusUnknown()) {
			setLocalLoading(true);
			auth.tryAutoLogin();
		} else {
			setLocalLoading(false);
		}

		return () => {
			setLocalLoading(false);
		};
	}, [auth]);

	if (isLocalLoading) {
		return (
			<GlobalLoader show={true} />
		);
	}

	return (
		<>
			<div className="page-content">
				<GlobalOverlay />
				<ReportTool />
				<MobileView />
				<Switch>
					<Route path={Routes.styleGuide}>
						<StyleGuide />
					</Route>
					<Route exact path={Routes.registration.success}>
						<RegistrationSuccess />
					</Route>
					<Route exact path={Routes.registration.doubleOptIn}>
						<DoubleOptIn />
					</Route>
					<Route exact path={Routes.registration.index}>
						<Registration />
					</Route>
					<Route exact path={Routes.configuration.favorites}>
						<Favorites />
					</Route>
					<Route exact path={Routes.configuration.defaults}>
						<Defaults />
					</Route>
					<Route exact path={Routes.configuration.defaultsSelectConfiguration}>
						<DefaultsConfig />
					</Route>
					<Route exact path={Routes.configuration.restoreConfigurations}>
						<EditConfigurations />
					</Route>
					<Route exact path={Routes.configuration.restoreConfiguration}>
						<EditConfiguration />
					</Route>
					<Route exact path={Routes.configuration.customerPdf.index}>
						<CustomerPdf />
					</Route>
					<Route exact path={Routes.configuration.ipea.index}>
						<Ipea />
					</Route>
					<Route exact path={Routes.configuration.assistance.index}>
						<Assistance />
					</Route>
					<Route exact path={Routes.configuration.assistance.success}>
						<AssistanceSuccess />
					</Route>
					<Route exact path={Routes.configuration.order.index}>
						<Order />
					</Route>
					<Route exact path={Routes.configuration.order.success}>
						<OrderSuccess />
					</Route>
					<Route exact path={Routes.configuration.share.index}>
						<ConfigurationShare />
					</Route>
					<Route exact path={Routes.configuration.share.success}>
						<ConfigurationShareSuccess />
					</Route>
					<Route exact path={Routes.configuration.registrationOverlay}>
						<RegistrationOverlay />
					</Route>
					<Route exact path={Routes.configuration.downloads}>
						<ProductDownloads />
					</Route>
					<Route exact path={Routes.configuration.login}>
						<LoginOnPage />
					</Route>
					<Route exact path={Routes.reportErrorSuccess}>
						<ReportToolSuccess />
					</Route>
					<Route path={Routes.configuration.index}>
						<Configuration />
					</Route>
					<Route exact path={Routes.share}>
						<Share />
					</Route>
					<PrivateRoute path={Routes.profile.index}>
						<Profile />
					</PrivateRoute>
					<Route path={Routes.login.index}>
						<Switch>
							<Route exact path={Routes.login.passwordReset.success}>
								<PasswordResetSuccess />
							</Route>
							<Route exact path={Routes.login.passwordReset.index}>
								<PasswordReset />
							</Route>
							<Route path="*">
								<Login />
							</Route>
						</Switch>
					</Route>
					<Route path={Routes.passwordRecovery}>
						<PasswordRecovery />
					</Route>
					<Route path="*">
						<Redirect to={Routes.login.index} />
					</Route>
				</Switch>
			</div>
		</>
	);
}