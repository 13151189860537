import './index.scss';

function RadioGroup (props) {
	return (
		<div className="radio-group">
			{ props.children }
		</div>
	);
}

export default RadioGroup;
