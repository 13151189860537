import BackgroundImage from 'images/login.jpg';
import Logo from 'images/logo.svg';
import TranslationTag from 'utils/translations';
import './index.scss';

function MobileView() {
	return (
		<div className="mobile-view">
			<div className="bg">
				<img src={BackgroundImage} alt="" />
			</div>
			<div className="content">
				<img src={Logo} alt="" />
				<div className="t2 headline"><TranslationTag tag="System_MobileHeadline" /></div>
				<div className="text"><TranslationTag tag="System_MobileText" /></div>
			</div>
		</div>
	);
}

export default MobileView;
