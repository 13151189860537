import Tabs from 'components/navigation/tabs';
import Note from 'components/core/note';
import ArchiveSearch from 'components/archive/search';
import Folders from './folders';
import WithoutFolder from './without-folder';
import SearchProject from './search-project';
import ArchiveFavorites from './favorites';
import TranslationTag from 'utils/translations';
import { Routes, StaticUrls } from 'utils/constants';
import { useConfig } from 'configuration/useConfig';
import { useTranslation } from 'react-i18next';
import { Switch, Route, NavLink } from 'react-router-dom';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import './style.scss';

function Archive() {
	const { searchProjects } = useArchiveApi();
	const { t } = useTranslation('translations');
	const { config } = useConfig();
	const history = useHistory();
	const [isSearching, setIsSearching] = useState(false);
	
	const onSearch = async (searchTerm) => {
		history.push(Routes.profile.archive.search);
		setIsSearching(true);
		await searchProjects(searchTerm, {});
		setIsSearching(false);
	};
	
	return (
		<div className="archive">
			<div className="intro d-flex justify-content-between align-items-center">
				<div className="headline t1">
					<TranslationTag tag="Archive_Headline" />
				</div>
				<div className="photometric-info">
					<Note
						headline="Archive_PhotometricHeadline"
						htmlText={'<a href="' + StaticUrls('plannerMode', config) + '" target="_blank">' + t('Archive_PhotometricText') + '</a>'}
					/>
				</div>
			</div>
			<div className="data-container">
				<ArchiveSearch onSearch={(searchTerm) => onSearch(searchTerm)} />
				<Tabs class="v2">
					<div className="buttons t2">
						<NavLink exact to={Routes.profile.archive.index}>
							<TranslationTag tag="Archive_ConfigurationsWithProject" />
						</NavLink>
						<NavLink to={Routes.profile.archive.projects}>
							<TranslationTag tag="Archive_ConfigurationsWithoutProject" />
						</NavLink>
						<NavLink to={Routes.profile.archive.favorites}>
							<TranslationTag tag="Archive_Favorites" />
						</NavLink>
						{ /* Feature
							<NavLink to={Routes.profile.archive.drafts}>
								<TranslationTag tag="Archive_Drafts" />
							</NavLink>
							<NavLink to={Routes.profile.archive.groups}>
								<TranslationTag tag="Archive_GroupConfigurations" />
							</NavLink>
						*/ }
					</div>
					<div className="content">
						<Switch>
							<Route exact path={Routes.profile.archive.index}>
								<Folders />
							</Route>
							<Route exact path={Routes.profile.archive.projects}>
								<WithoutFolder />
							</Route>
							<Route exact path={Routes.profile.archive.favorites}>
								<ArchiveFavorites />
							</Route>
							<Route exact path={Routes.profile.archive.search}>
								<SearchProject isSearching={isSearching} />
							</Route>
						</Switch>
					</div>
				</Tabs>
			</div>
		</div>
	);
}

export default Archive;