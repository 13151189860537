import TranslationTag from 'utils/translations';
import './index.scss';

function Alert(props) {
	return (
		<div className="alert-container d-inline-block">
			<div className="inner d-flex align-items-center">
				<div className="icon t2 d-flex align-items-center justify-content-center"><span>!</span></div>
				<div className="text t4 text-uppercase"><TranslationTag tag={props.text} /></div>
			</div>
			{ props.count &&
				<div className="count"><span>{props.count}</span></div>
			}
		</div>
	);
}

Alert.defaultProps = {
	text: '',
	count: '',
};

export default Alert;
