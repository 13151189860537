import Overlay from 'components/core/overlay';
import Button from 'components/core/button';
import { useHistory } from 'react-router-dom';
import './index.scss';

function ReportToolSuccess() {
	const history = useHistory();
	
	return (
		<div className="registration-success">
			<Overlay
				headline="ReportTool_SuccessHeadline"
				text="ReportTool_SuccessText"
			>
				<Button
					class="btn-primary"
					text={'ReportTool_SuccessBack'}
					onClick={() => history.goBack()}
				/>
			</Overlay>
		</div>
	);
}

export default ReportToolSuccess;