import React, { useState, useEffect } from 'react';
import { useTranslation  } from 'react-i18next';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './index.scss';

function SliderWrapper (props) {
	const [value, setValue] = useState(0);
	const { t } = useTranslation('translations');
	
	const handleChange = changeEvent => {
		let value = parseFloat(changeEvent.target.value);
		setValue(value);
	};
	
	const handleAfterChange = changeEvent => {
		let value = parseFloat(changeEvent.target.value);
		props.onChange(value);
	};
	
	const handleDecrease = () => {
		let newValue = value - props.step;
		if (newValue < props.min) {
			newValue = props.min;
		}
		setValue(parseFloat(newValue.toFixed(2)));
		props.onChange(parseFloat(newValue.toFixed(2)));
	};
	
	const handleIncrease = () => {
		let newValue = value + props.step;
		if (newValue > props.max) {
			newValue = props.max;
		}
		setValue(parseFloat(newValue.toFixed(2)));
		props.onChange(parseFloat(newValue.toFixed(2)));
	};
	
	useEffect(() => {
		if (props.defaultValue > props.min) {
			setValue(props.defaultValue);
		} else {
			setValue(props.min);
		}
	}, [props.defaultValue, props.min]);
	
	useEffect(() => {
		setValue(props.defaultValue);
	}, [props.defaultValue]);
	
	let unitClass = props.unit;
	if (unitClass === '%') {
		unitClass = 'procent';
	}
	
	return (
		<div className={`range-slider d-flex unit-${unitClass ? unitClass : ''}${props.class ? ' ' + props.class : ''}`}>
			<div className="less" onClick={handleDecrease}></div>
			<div className="slider-wrapper">
				<div className="min">{props.usLogic ? t('Configuration_RangeBelow') : ((props.conversionFactor ? ((props.conversionFactor * props.min).toFixed(2)).toLocaleString() : props.min.toLocaleString()) + ' ' + props.unit)}</div>
				{ props.usLogic &&
					<div className="min-us">
						D/N = {parseFloat(props.min + 100).toLocaleString() + ' ' + props.unit}
					</div>
				}
				<RangeSlider
					value={value}
					onChange={handleChange}
					onAfterChange={handleAfterChange}
					min={props.min}
					max={props.max}
					step={props.step}
					tooltip="on"
					size="sm"
					tooltipPlacement="top"
					variant="secondary"
					tooltipLabel={(value) => (props.usLogic ? 'D/N = ' + (parseFloat(value) + 100).toLocaleString() + ' ' + props.unit : ((props.conversionFactor ? ((props.conversionFactor * value).toFixed(2)).toLocaleString() : value.toLocaleString()) + ' ' + props.unit))}
				/>
				<div className="max">{props.usLogic ? t('Configuration_RangeOverage') : ((props.conversionFactor ? ((props.conversionFactor * props.max).toFixed(2)).toLocaleString() : props.max.toLocaleString()) + ' ' + props.unit)}</div>
				{ props.usLogic &&
					<div className="max-us">
						D/N = {parseFloat(props.max + 100).toLocaleString() + ' ' + props.unit}
					</div>
				}
			</div>
			<div className="more" onClick={handleIncrease}></div>
		</div>
	);
}

SliderWrapper.defaultProps = {
	min: 0,
	max: 1000,
	defaultValue: 0,
	unit: 'lm',
	step: 100,
	class: '',
	usLogic: false,
	onChange: () => void(0),
};

export default SliderWrapper;