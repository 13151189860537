import { useEffect, useMemo } from 'react';
import Families from 'pages/configuration/families';
import Detail from 'pages/configuration/detail';
import { makeSelectCurrentQuestion, useConfiguratorApi } from 'hooks/useConfiguratorApi';
import React, { useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { LoadingProvider } from 'hooks/useLoading';
import './style.scss';

function Configuration() {
	const { state, start } = useConfiguratorApi();
	const selectCurrentQuestion = useMemo(() => makeSelectCurrentQuestion(), []);
	const currentQuestion = selectCurrentQuestion(state);
	const { user } = useAuth();
	const [familyView, setFamilyView] = useState(user && user.entrySetting === 2 ? false : true);
	
	useEffect(() => {
		if (!state.configId && !state.starting) {
			start();
		}
	});
	
	if (!currentQuestion) {
		return null;
	}
	
	return (
		<div className="configuration">
			{ (currentQuestion.identifier === 'ProductFamily' || currentQuestion.identifier === 'ProductCategory') && familyView && state.questions.length <= 1 ?
				<Families
					question={currentQuestion}
					headline={currentQuestion.data.headline}
					families={currentQuestion.data.answers}
					onSkip={() => setFamilyView(false)}
				/>
				:
				<LoadingProvider>
					<Detail
						currentQuestion={currentQuestion}
					/>
				</LoadingProvider>
			}
		</div>
	);
}

export default Configuration;