import React, { createContext, useContext } from 'react';
import { useAuthState } from 'hooks/useAuth';
import { useConfiguratorApiState } from 'hooks/useConfiguratorApi';
import { useCountryApiState } from 'hooks/useCountryApi';
import { useArchiveApiState } from 'hooks/useArchiveApi';

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
	const value = useStoreProviderValue();
	return (
	  <StoreContext.Provider value={value}>
			{children}
	  </StoreContext.Provider>
	);
};

// useStore will be used in React components to fetch and mutate state
export const useStore = () => {
	const ctx = useContext(StoreContext);
	if (ctx === undefined) {
	  throw new Error('useStore must be used within a StoreProvider');
	}
	return ctx;
};

const useStoreProviderValue = () => {
	const auth = useAuthState();
	const configuratorApi = useConfiguratorApiState(auth);
	const countryApi = useCountryApiState(auth);
	const archiveApi = useArchiveApiState(auth);
	
	return {
		auth,
		configuratorApi,
		countryApi,
		archiveApi,
	};
};
