import { SubElementViewWidths } from 'utils/constants';
import React, { useEffect, useState } from 'react';
import { useConfig } from 'configuration/useConfig';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';

function DrawingDataView({ data : drawingData, subElementData, poleImage }) {
	const [isDrawingLoaded, setIsDrawingLoaded] = useState(false);
	const { config } = useConfig();
	useEffect(() => {
		if (!poleImage) {
			const script = document.createElement('script');
			script.src = config.baseApiUrl + '/Scripts/el.js';
			script.async = false;
			script.onload = function () {
				window.drawElGraphic('#drawing-data', drawingData, subElementData);
				setIsDrawingLoaded(true);
			};
			document.body.appendChild(script);
			
			const style = document.createElement('link');
			style.type = 'text/css';
			style.rel = 'stylesheet';
			style.href = config.baseApiUrl + '/Content/el.css';
			style.async = false;
			document.body.appendChild(style);
			return () => {
				document.body.removeChild(script);
				document.body.removeChild(style);
			};
		}
	}, [drawingData, config.baseApiUrl, poleImage, subElementData]);
	
	useEffect(() => {
		if (isDrawingLoaded && drawingData && !poleImage) {
			window.drawElGraphic('#drawing-data', drawingData, subElementData);
		}
	}, [drawingData, isDrawingLoaded, poleImage, subElementData]);
	
	return (
		<div className={'drawing-data-view'} style={{ width: (SubElementViewWidths('') - 30) }}>
			<SimpleBar style={{ maxHeight: 'calc(100vh - 150px)' }} autoHide={false}>
				{ poleImage ? (
					<div className="pole-image">
						<div className="inner">
							<img src={poleImage} alt="" />
						</div>
					</div>
				): (
					<div className="drawing-data" id="drawing-data"></div>
				)}
			</SimpleBar>
		</div>
	);
}

export default DrawingDataView;