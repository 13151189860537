import Form from 'components/forms/form';
import RadioGroup from 'components/forms/radio-group';
import RadioButton from 'components/forms/radio-button';
import TranslationTag from 'utils/translations';
import { useAuth } from 'hooks/useAuth';

function Settings() {
	const { user, updateEntrySetting, updateScreenSetting, updateLanguage } = useAuth();
	
	const handleEntrySettingSubmit = async (values) => {
		updateEntrySetting(values.configuratorStart);
	};
	const handleScreenSettingSubmit = async (values) => {
		updateScreenSetting(values.configuratorWidth);
	};
	const handleLanguageSubmit = async (values) => {
		updateLanguage(values.language);
	};
	
	return (
		<div className="settings column-container">
			<div className="column">
				<div className="headline t2">
					<TranslationTag tag="Profile_ConfiguratorStartHeadline" />
				</div>
				<div className="content no-space">
					<Form
						initialValues={{
							configuratorStart: user.entrySetting ? user.entrySetting + '' : '0',
						}}
						onSubmit={(values) => handleEntrySettingSubmit(values)}
					>
						<RadioGroup>
							{ Object.keys(user.entrySettingEnum).map((key, index) => (
								<RadioButton
									name="configuratorStart"
									headline={'EntrySettingEnum' + index}
									text={'Profile_EntrySettingEnum' + index + 'Subheadline'}
									tooltipText={'Profile_EntrySettingEnum' + index + 'InfoText'}
									value={key}
									id={key}
									key={key}
									submitOnChange={true}
								/>
							))}
						</RadioGroup>
					</Form>
				</div>
			</div>
			<div className="column">
				<div className="headline t2">
					<TranslationTag tag="Profile_ConfiguratorWidthHeadline" />
				</div>
				<div className="content no-space">
					<Form
						initialValues={{
							configuratorWidth: user.screenSetting ? user.screenSetting + '' : '0',
						}}
						onSubmit={(values) => handleScreenSettingSubmit(values)}
					>
						<RadioGroup>
							{ Object.keys(user.screenSettingEnum).map((key, index) => (
								<RadioButton
									name="configuratorWidth"
									headline={'ScreenSettingEnum' + index}
									text={'Profile_ScreenSettingEnum' + index + 'Subheadline'}
									tooltipText={'Profile_ScreenSettingEnum' + index + 'InfoText'}
									value={key}
									id={key}
									key={key}
									submitOnChange={true}
								/>
							))}
						</RadioGroup>
					</Form>
				</div>
				<div className="headline t2">
					<TranslationTag tag="Profile_ConfiguratorLanguageHeadline" />
				</div>
				<div className="content no-space">
					<Form
						initialValues={{
							language: user.defaultLanguage ? user.defaultLanguage + '' : '3',
						}}
						onSubmit={(values) => handleLanguageSubmit(values)}
					>
						<RadioGroup>
							{ Object.keys(user.defaultLanguageEnum).map((key, index) => (
								<RadioButton
									name="language"
									headline={'DefaultLanguageEnum' + (index + 1)}
									value={key}
									id={key}
									key={key}
									submitOnChange={true}
								/>
							))}
						</RadioGroup>
					</Form>
				</div>
			</div>
			{ user.foreignCurrency &&
				<div className="column">
					<div className="headline t2">
						<TranslationTag tag="Profile_ConfiguratorCurrencyHeadline" />
					</div>
					<div className="content no-space currency">
						<div className="t2">{ user.additionalCurrency }</div>
					</div>
				</div>
			}
		</div>
	);
}

export default Settings;