import { Trans, useTranslation } from 'react-i18next';

export default function TranslationTag(props) {
	const { t, i18n } = useTranslation('translations');
	
	if (props.tag === '') {
		return '';
	}
	
	if (i18n.exists(props.tag) && t(props.tag) === '') {
		return (
			props.tag
		);
	}
	
	return (
		<Trans>
			<span dangerouslySetInnerHTML={{ __html: t(props.tag) }} />
		</Trans>
	);
}