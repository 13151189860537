import sprite from 'images/icons.svg';

function Icon(props) {
	return (
		<svg style={{ 'color': props.color }} width={props.width} height={props.height} className={`icon icon-${props.icon + (props.class ? ' ' + props.class : '')}`} onClick={props.onClick}>
			<use xlinkHref={`${sprite}#icon-${props.icon}`} />
		</svg>
	);
}

Icon.defaultProps = {
	icon: '',
	class: '',
	width: 20,
	height: 20,
	color: 'currentColor',
	onClick: () => void(0),
};

export default Icon;
