import { useAuth } from 'hooks/useAuth';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from 'utils/constants';
  
function PrivateRoute({ children, ...rest }) {
	const auth = useAuth();
	return (
		<Route
			{...rest}
			render={
				({ location }) => (
					auth.user
						? (
							children
						) : (
							<Redirect
								to={{
									pathname: Routes.login.index,
									state: { from: location },
								}}
							/>
						)
				)
			}
	  />
	);
}
  
export default PrivateRoute;