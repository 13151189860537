import Product from 'components/core/product';
import SpinnerLoader from 'components/system/spinner-loader';
import TranslationTag from 'utils/translations';
import { useEffect, useState } from 'react';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './style.scss';

function ArchiveFavorites() {
	const history = useHistory();
	const { getFavorites, state, deleteFavorite } = useConfiguratorApi();
	const [loadingFavorites, setLoadingFavorites] = useState(false);
	const [favorites, setFavorites] = useState(null);
	const [favoritesArray, setFavoritesArray] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	
	const onDelete = async (configId) => {
		setIsLoading(true);
		await deleteFavorite(configId);
		setFavorites(await getFavorites());
		setIsLoading(false);
	};
	
	useEffect(() => {
		const load = async () => {
			setIsLoading(true);
			setFavorites(await getFavorites());
			setIsLoading(false);
			setLoadingFavorites(false);
		};
		if (favorites === null && loadingFavorites === false) {
			setLoadingFavorites(true);
			load();
		}
		if (favorites) {
			let favoritesArrayTemp = [];
			Object.keys(favorites).forEach(function(key, index) {
				if (favorites[key].length) {
					favorites[key].forEach(function(value) {
						favoritesArrayTemp.push(value);
					});
				}
			});
			setFavoritesArray(favoritesArrayTemp);
		}
	}, [state.variantId, favorites, getFavorites, loadingFavorites, setFavorites, setLoadingFavorites]);
	
	return (
		<div className="archive-favorites">
			<SpinnerLoader
				show={isLoading}
			/>
			<div className="products">
				{ (!isLoading && favoritesArray && favoritesArray.length > 0) &&
					favoritesArray.map((favorite, key) => (
						<Product
							key={key}
							name={favorite.family}
							image={favorite.image}
							summary={favorite.summary}
							configurationId={favorite.id}
							tooltipText={favorite.tooltip}
							favoriteArchiveLayout={true}
							onDelete={(configId) => onDelete(configId)}
							onEdit={(configId) => history.push(Routes.configuration.restoreConfiguration, { saveId: configId })}
						/>
					))
				}
				{ (!isLoading && !loadingFavorites && favoritesArray && !favoritesArray?.length) &&
					<div className="no-results t2">
						<TranslationTag tag="Archive_NoFavorites" />
					</div>
				}
			</div>
		</div>
	);
}

export default ArchiveFavorites;