import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';
import './style.scss';

function ProjectDuplicate() {
	const { duplicateArchiveProject } = useArchiveApi();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	
	const handleSubmit = async (values) => {
		setIsLoading(true);
		await duplicateArchiveProject(history.location.state.projectId);
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-duplicate">
			<Overlay
				headline="Archive_DuplicateProjectHeadline"
				text="Archive_DuplicateProjectText"
				backLink={Routes.profile.archive.index}
			>
				<SpinnerLoader
					show={isLoading}
				/>
				<Form
					initialValues=""
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_DuplicateProjectSubmit"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectDuplicate;