import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Input from 'components/forms/input';
import Separator from 'components/forms/separator';
import Textarea from 'components/forms/textarea';
import Select from 'components/forms/select';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes, Errors, Genders } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { prepareCountriesForSelect } from 'utils/objects';
import { useCountryApi } from 'hooks/useCountryApi';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useState } from 'react';
import * as Yup from 'yup';
import './style.scss';

function FolderEdit() {
	const history = useHistory();
	const { t } = useTranslation('translations');
	const { updateArchiveFolder } = useArchiveApi();
	const { state: countryState } = useCountryApi();
	const [isLoading, setIsLoading] = useState(false);
	const validationSchema = Yup.object({
		folderName: Yup.string().required(t(Errors.Required)),
	});
	
	if (!history.location.state || !history.location.state.folder) {
		history.push(Routes.profile.archive.index);
	}
	
	const folder = (history.location.state && history.location.state.folder) ? history.location.state.folder : '';
	
	const initialValues = {
		folderName: folder.folderName ? folder.folderName : '',
		folderNumber: folder.folderNumber ? folder.folderNumber : '',
		street: folder.street ? folder.street : '',
		houseNumber: folder.houseNumber ? folder.houseNumber : '',
		city: folder.city ? folder.city : '',
		zipCode: folder.zipCode ? folder.zipCode : '',
		countryId: folder.countryId ? {
			'value': countryState.countries.filter(country => country.countryId === folder.countryId)[0].countryId,
			'label': countryState.countries.filter(country => country.countryId === folder.countryId)[0].countryName,
		} : '',
		comment: folder.comment ? folder.comment : '',
		customerGender: folder.customerGender ? Genders(t, folder.customerGender ? parseInt(folder.customerGender) : '') : '',
		customerFirstName: folder.customerFirstName ? folder.customerFirstName : '',
		customerSecondName: folder.customerSecondName ? folder.customerSecondName : '',
		customerCompany: folder.customerCompany ? folder.customerCompany : '',
		customerEmail: folder.customerEmail ? folder.customerEmail : '',
		customerPhone: folder.customerPhone ? folder.customerPhone : '',
		customerStreet: folder.customerStreet ? folder.customerStreet : '',
		customerHouseNumber: folder.customerHouseNumber ? folder.customerHouseNumber : '',
		customerCity: folder.customerCity ? folder.customerCity : '',
		customerZipCode: folder.customerZipCode ? folder.customerZipCode : '',
		customerCountryId: folder.customerCountryId ? {
			'value': countryState.countries.filter(country => country.countryId === folder.customerCountryId)[0].countryId,
			'label': countryState.countries.filter(country => country.countryId === folder.customerCountryId)[0].countryName,
		} : '',
	};
	
	const updateFolder = async (values) => {
		setIsLoading(true);
		await updateArchiveFolder(folder.folderId, {
			...values,
			countryId: values.countryId.value ?? 0,
			customerCountryId: values.customerCountryId.value ?? 0,
			customerGender: values.customerGender.value ?? 0,
		});
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	return (
		<div className="folder-edit">
			<Overlay
				headline="Archive_EditFolderHeadline"
				text="Archive_EditFolderText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => updateFolder(values)}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<div className="field-container">
						<Separator
							text="CreateProject_Headline1"
						/>
						<Input
							name="folderName"
							text="Form_ProjectName"
						/>
						<Separator
							text="CreateProject_Headline2"
						/>
						<Input
							name="folderNumber"
							text="Form_ProjectNumber"
						/>
						<Input
							name="street"
							text="Form_Street"
							class="small"
						/>
						<Input
							name="houseNumber"
							text="Form_StreetNr"
						/>
						<Input
							name="city"
							text="Form_Place"
							class="medium"
						/>
						<Input
							name="zipCode"
							text="Form_Cap"
						/>
						<Select
							name="countryId"
							placeholder={t('Form_Country')}
							options={prepareCountriesForSelect(countryState.countries)}
							searchable={true}
							defaultValue={{
								'value': countryState.countries.filter(country => country.countryId === folder.countryId)[0].countryId,
								'label': countryState.countries.filter(country => country.countryId === folder.countryId)[0].countryName,
							}}
						/>
						<Textarea
							name="comment"
							placeholder={t('Form_Comment')}
						/>
						<Separator
							text="CreateProject_Headline3"
						/>
						<Select
							name="customerGender"
							placeholder={t('Form_Salutation')}
							options={Genders(t)}
							defaultValue={Genders(t, folder.customerGender ? folder.customerGender : '')}
						/>
						<Input
							name="customerFirstName"
							text="Form_Firstname"
							class="medium"
						/>
						<Input
							name="customerSecondName"
							text="Form_Lastname"
						/>
						<Input
							name="customerCompany"
							text="Form_Companyname"
						/>
						<Input
							name="customerEmail"
							text="Form_Email"
						/>
						<Input
							name="customerPhone"
							text="Form_Phone"
						/>
						<Input
							name="customerStreet"
							text="Form_Street"
							class="small"
						/>
						<Input
							name="customerHouseNumber"
							text="Form_StreetNr"
						/>
						<Input
							name="customerCity"
							text="Form_Place"
							class="medium"
						/>
						<Input
							name="customerZipCode"
							text="Form_Cap"
						/>
						<Select
							name="customerCountryId"
							placeholder={t('Form_Country')}
							options={prepareCountriesForSelect(countryState.countries)}
							searchable={true}
							defaultValue={{
								'value': countryState.countries.filter(country => country.countryId === folder.customerCountryId)[0].countryId,
								'label': countryState.countries.filter(country => country.countryId === folder.customerCountryId)[0].countryName,
							}}
						/>
					</div>
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_EditFolderSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_EditFolderAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default FolderEdit;