import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Separator from 'components/forms/separator';
import Input from 'components/forms/input';
import Textarea from 'components/forms/textarea';
import Product from 'components/core/product';
import { Routes, Errors } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import * as Yup from 'yup';
import './style.scss';

function Assistance() {
	const { t } = useTranslation('translations');
	const { state, sendAssistance, getOrderInfo } = useConfiguratorApi();
	const history = useHistory();
	const [selectedConfigurations, setSelectedConfigurations] = useState([]);
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	const { user } = useAuth();
	const isArchive = (history.location.state && history.location.state.project);
	const projectId = isArchive ? history.location.state.project.projectId : state.projectId;

	const validationSchema = Yup.object({
		firstName: Yup.string().required(t(Errors.Required)),
		secondName: Yup.string().required(t(Errors.Required)),
		email: Yup.string().required(t(Errors.Required)),
		comment: Yup.string().required(t(Errors.Required)),
	});
	
	const handleSubmit = async (values) => {
		let properties = {
			...values,
			configuration: selectedConfigurations,
		};
		await sendAssistance(properties, projectId);
		if (isArchive) {
			history.push(Routes.configuration.assistance.success, { project: history.location.state.project });
		} else {
			history.push(Routes.configuration.assistance.success);
		}
	};
	
	const changeConfigurations = (configId) => {
		let tempConfigs = [...selectedConfigurations];
		if (tempConfigs.includes(configId)) {
			tempConfigs.splice(tempConfigs.indexOf(configId), 1);
		} else {
			tempConfigs.push(configId);
		}
		setSelectedConfigurations(tempConfigs);
	};
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, projectId]);
	
	if (!projectId) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="assistance">
			<Overlay
				headline="Assistance_Headline"
				text="Assistance_Text"
				backLink={isArchive ? Routes.profile.archive.index : Routes.configuration.summary}
			>
				<Form
					initialValues={{
						firstName: user ? user.firstName : '',
						secondName: user ? user.lastName : '',
						email: user ? user.eMail : '',
						comment: '',
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<Input
						name="firstName"
						text="Form_Firstname"
					/>
					<Input
						name="secondName"
						text="Form_Lastname"
					/>
					<Input
						name="email"
						text="Form_Email"
					/>
					<Separator
						text="Assistance_Headline1"
					/>
					<div className="products">
						{ (infos.configurations && infos.configurations.length > 0) &&
							infos.configurations.map((configuration) => (
								<Product
									key={configuration.configurationId}
									name={configuration.family}
									summary={configuration.summary}
									image={configuration.image}
									configurationId={configuration.configurationId}
									onClick={(configId) => changeConfigurations(configId)}
									active={selectedConfigurations.includes(configuration.configurationId)}
								/>
							))
						}
					</div>
					<Separator
						text="Assistance_Headline2"
					/>
					<Textarea
						name="comment"
						placeholder={t('Assistance_Comment')}
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Assistance_SendAssistance"
					/>
					<Button
						class="btn-secondary"
						text="Assistance_Abort"
						onClick={() => history.push(isArchive ? Routes.profile.archive.index : Routes.configuration.summary)}
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default Assistance;