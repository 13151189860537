import Icon from 'components/core/icon';
import { forwardRef } from 'react';
import TranslationTag from 'utils/translations';
import './index.scss';

const Button = forwardRef((props, ref) => {
	return (
		<div className={'btn-container no-hover'}>
			<button
				className={`btn${props.class ? ' ' + props.class : ''}${props.icon ? ' icon' : ''}`}
				type={props.type}
				ref={ref}
				disabled={props.disabled}
				onClick={props.onClick}
			>
				{props.icon ?
					<Icon
						icon={props.icon}
						width={props.width}
						height={props.height}
					/>
					:
					<TranslationTag tag={props.text} />
				}
			</button>
		</div>
	);
});

Button.defaultProps = {
	class: '',
	text: '',
	type: 'button',
	icon: '',
	width: '',
	height: '',
	onClick: () => void(0),
};

export default Button;
