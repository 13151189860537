import { useCallback } from 'react';
import { useImmerReducer } from 'use-immer';
import { AuthStatus } from 'hooks/useAuth';
import { useEffect } from 'react';
import { createSelector } from 'reselect';
import { useApiClient } from 'hooks/useApiClient';
import { useStore } from 'store/useStore';

export const useCountryApi = () => {
	const store = useStore();
	return store.countryApi;
};

export const LoadingStatus = {
	Unknown: 0,
	Pending: 1,
	Loaded: 2,
	Failure: 3,
};

const initialState = {
	status: LoadingStatus.Unknown,
	countries: [],
	language: null,
};

const ActionType = {
	RESET: 'reset',
	SET: 'set',
	LOADING: 'loading',
	ERROR: 'error',
};

function reducer(draft, action) {
	switch (action.type) {
		case ActionType.LOADING:
			draft.status = LoadingStatus.Pending;
			draft.language = action.payload;
			break;
		case ActionType.SET:
			draft.countries = action.payload;
			draft.status = LoadingStatus.Loaded;
			break;
		case ActionType.ERROR:
			draft.status = LoadingStatus.Failure;
			break;
		case ActionType.RESET:
			return initialState;
		default:
			throw new Error(`Action '${action.type}' unknown!`);
	}
}

export const useCountryApiState = (auth) => {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const apiClient = useApiClient(auth);

	const load = useCallback(async (language = null) => {
		if (language === null) {
			return;
		}

		if (state.language === language) {
			return;
		}

		if (state.status === LoadingStatus.Pending ) {
			return;
		}

		dispatch({ type: ActionType.LOADING, payload: language });

		try {
			const response = await apiClient.get('/Resource/Country');
			dispatch({ type: ActionType.SET, payload: response.data });
		} catch (error) {
			console.error(error.response);
			dispatch({ type: ActionType.ERROR });
		}
	}, [state.language, state.status, dispatch, apiClient]);

	useEffect(() => {
		if ([AuthStatus.Authenticated, AuthStatus.Anonymous].includes(auth.status)) {
			load(auth.language);
		}
	}, [auth.language, auth.status, load]);

	return {
		state,
		load,
	};
};

export const makeSelectCountryById = () => {
	return createSelector(
		[
			(state) => state.countries,
			(_, countryId) => countryId,
		],
		(countries, countryId) => {
			if (!countries) {
				return null;
			}

			return countries.find((country) => country.countryId === countryId);
		},
	);
};
export const selectCountryById = makeSelectCountryById();
