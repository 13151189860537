import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './index.scss';

function ConfigNavigation({ children, disabled }) {
	const location = useLocation();

	useEffect(() => {
		let menuScroll = document.querySelector('.config-navigation .inner');
		if (menuScroll) {
			let activeMenuPoint = menuScroll.querySelector('a.active');
			if (activeMenuPoint) {
				menuScroll.scrollLeft = activeMenuPoint.offsetLeft - (menuScroll.offsetWidth - activeMenuPoint.offsetWidth) / 2;
			}
		}
	}, [location.pathname]);

	return (
		<div className="config-navigation">
			{ disabled &&
				<div className="error-bg"></div>
			}
			<div className="inner d-flex align-items-center t2">
				<div className="scroll">
					{children}
				</div>
			</div>
		</div>
	);
}

export default ConfigNavigation;
