import Note from 'components/core/note';
import Button from 'components/core/button';

function TypeInfoText({ currentQuestion, onSubmit }) {
	return (
		<div className="note-container">
			<Note
				headline={currentQuestion.data.headline}
				text={currentQuestion.data.text}
			/>
			{ (currentQuestion.data && currentQuestion.data.forward) &&
				<div className="submit-button">
					<Button
						text="Configuration_Ok"
						class="btn-secondary"
						onClick={() => onSubmit()}
					/>
				</div>
			}
		</div>
	);
}

TypeInfoText.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeInfoText;