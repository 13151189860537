import { useCallback, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { getLanguageCodeFromLanguageEnum, getLanguageFromLanguageCode } from 'utils/language';
import { useStore } from 'store/useStore';
import { useApiClient } from 'hooks/useApiClient';

export const useAuth = () => {
	const store = useStore();
	return store.auth;
};

export const AuthStatus = {
	Unknown: 0,
	Authenticating: 1,
	Authenticated: 2,
	Anonymous: 3,
};

const initialState = {
	user: null,
	accessToken: null,
	status: AuthStatus.Unknown,
	language: null,
	fallbackLanguage: null,
};

const ActionType = {
	RESET: 'reset',
	LOGIN: 'login',
	LOGOUT: 'logout',
	AUTHENTICATE: 'authenticate',
	UPDATE_ENTRY_SETTING: 'update-entry-setting',
	UPDATE_SCREEN_SETTING: 'update-screen-setting',
	UPDATE_LANGUAGE: 'update-language',
	UPDATE_USER_IMAGE: 'update-user-image',
	UPDATE_PASSWORD: 'update-password',
	SET_LANGUAGE: 'set-language',
	SET_FALLBACK_LANGUAGE: 'set-fallback-language',
};

function reducer(draft, action) {
	switch (action.type) {
		case ActionType.LOGIN:
			draft.user = action.payload.user;
			if (action.payload.accessToken) {
				draft.accessToken = action.payload.accessToken;
			}
			draft.status = AuthStatus.Authenticated;
			break;
		case ActionType.AUTHENTICATE:
			draft.status = AuthStatus.Authenticating;
			break;
		case ActionType.LOGOUT:
			draft.user = null;
			if (action.payload && action.payload.accessToken) {
				draft.accessToken = action.payload.accessToken;
			}
			draft.status = AuthStatus.Anonymous;
			break;
		case ActionType.UPDATE_ENTRY_SETTING:
			draft.user.entrySetting = parseInt(action.payload);
			break;
		case ActionType.UPDATE_SCREEN_SETTING:
			draft.user.screenSetting = parseInt(action.payload);
			break;
		case ActionType.UPDATE_LANGUAGE:
			draft.user.defaultLanguage = parseInt(action.payload);
			break;
		case ActionType.UPDATE_PASSWORD:
			draft.user.enforcePasswordUpdate = false;
			break;
		case ActionType.UPDATE_USER_IMAGE:
			draft.user.userImage = action.payload;
			break;
		case ActionType.SET_LANGUAGE:
			if (draft.language === action.payload) {
				return draft;
			}
			draft.language = action.payload;
			break;
		case ActionType.SET_FALLBACK_LANGUAGE:
			if (draft.fallbackLanguage === action.payload) {
				return draft;
			}
			draft.fallbackLanguage = action.payload;
			break;
		case ActionType.RESET:
			return initialState;
		default:
			throw new Error(`Action '${action.type}' unknown!`);
	}
}

export const useAuthState = () => {
	const [state, dispatch] = useImmerReducer(reducer, initialState);
	const apiClient = useApiClient(state);
	const { i18n } = useTranslation('translations');

	const setLanguage = useCallback(async (targetLanguage) => {
		const oldLanguage = getLanguageFromLanguageCode(i18n.language);
		let currentLanguage = oldLanguage;
		
		if (oldLanguage !== targetLanguage && (targetLanguage !== null || oldLanguage !== state.fallbackLanguage)) {
			i18n.changeLanguage(getLanguageCodeFromLanguageEnum(targetLanguage));
			currentLanguage = getLanguageFromLanguageCode(i18n.language);
		}

		if (currentLanguage !== oldLanguage) {
			await apiClient.get('/user/' + currentLanguage, null, false);
		}

		if (targetLanguage === null) {
			dispatch({ type: ActionType.SET_FALLBACK_LANGUAGE, payload: currentLanguage });
		}

		dispatch({ type: ActionType.SET_LANGUAGE, payload: currentLanguage });
	}, [i18n, state.fallbackLanguage, dispatch, apiClient]);

	const userLanguage = state.user === null ? null : state.user.defaultLanguage;
	useEffect(() => {
		if ([AuthStatus.Authenticated, AuthStatus.Anonymous].includes(state.status)) {
			setLanguage(userLanguage);
		}
	}, [setLanguage, userLanguage, state.status]);
    
	const login = useCallback(async (username, password, rememberMe) => {
		dispatch({ type: ActionType.AUTHENTICATE });

		const data = qs.stringify({
			user: username,
			pass: password,
			rememberMe: rememberMe,
			// projectNumberNavision: ???, Optional. Only for logins via Navision.
		});
		
		let accessToken = null;
		try {
			const response = await apiClient.post('/Login', data);
			const accessTokenResponse = await apiClient.get('/init', null, false);
			accessToken = accessTokenResponse.data.accessToken;
			dispatch({ type: ActionType.LOGIN, payload: { accessToken, user: response.data }});
			return response.data;
		} catch (error) {
			if (error.response && error.response.status === 401) {
				dispatch({ type: ActionType.LOGOUT });
				return null;
			} else {
				// todo: error handling -> error state which can be used outside?
				console.error(error);
			}
		}
	}, [apiClient, dispatch]);

	const logout = useCallback(async () => {
		if (!state.user) {
			return;
		}
		
		let accessToken = null;
		try {
			await apiClient.post('/Logout', '');
			const accessTokenResponse = await apiClient.get('/init', null, false);
			accessToken = accessTokenResponse.data.accessToken;
			dispatch({ type: ActionType.LOGOUT, payload: { accessToken }});
		} catch (error) {
			// todo: error handling -> error state which can be used outside?
			console.error(error);
		}
	}, [apiClient, dispatch, state.user]);

	const tryAutoLogin = useCallback(async () => {
		if (state.status !== AuthStatus.Unknown && state.status !== AuthStatus.Anonymous) {
			return;
		}
		
		const doLogout = async () => {
			try {
				await logout();
			} catch (error) {
				// todo: error handling -> error state which can be used outside?
				console.error(error);
			}
			return false;
		};

		dispatch({ type: ActionType.AUTHENTICATE });

		let accessToken = null;
		try {
			const accessTokenResponse = await apiClient.get('/init', null, false);
			accessToken = accessTokenResponse.data.accessToken;
			const response = await apiClient.get('/user' + (!state.user ? '/' + getLanguageFromLanguageCode(i18n.language) : ''), null, false);
			if (!response.data.isLoggedIn) {
				dispatch({ type: ActionType.LOGOUT, payload: { accessToken }});
				return false;
			}
			dispatch({ type: ActionType.LOGIN, payload: { accessToken, user: response.data }});
			return true;
		} catch (error) {
			dispatch({ type: ActionType.LOGOUT, payload: { accessToken }});
			if (error.response && error.response.status === 401) {
				return false;
			} else {
				// todo: error handling -> error state which can be used outside?
				console.error(error);
			}
			return await doLogout();
		}
	}, [state.status, state.user, dispatch, logout, apiClient, i18n.language]);

	const signUp = useCallback(async (properties) => {
		const data = qs.stringify(properties);
		
		try {
			const response = await apiClient.post('/user/signup', data);
			return response.data;
		} catch (error) {
			console.error(error.response);
		}
	}, [apiClient]);
	
	const confirmSignUp = useCallback(async (signupAuthToken = null) => {
		if (!signupAuthToken) {
			return;
		}
		
		const response = await apiClient.post(`/user/confirm/${signupAuthToken}`, '');
		console.log(response);
	}, [apiClient]);

	const resetPassword = useCallback(async (username) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		
		try {
			const response = await apiClient.post('/password/reset/' + username, null, config);
			return response.data;
		} catch (error) {
			console.error(error.response);
		}
	}, [apiClient]);

	const recoverPassword = useCallback(async (authToken, password) => {
		const data = qs.stringify({
			authToken,
			pass: password,
			language: getLanguageFromLanguageCode(i18n.language),
		});
		
		try {
			const response = await apiClient.post('/password/recover', data);
			return response.data;
		} catch (error) {
			console.error(error.response);
		}
	}, [i18n.language, apiClient]);

	const updateUserSetting = useCallback(async (value, endpoint, actionType) => {
		const data = qs.stringify({
			value,
		});

		try {
			await apiClient.post(endpoint, data);
			dispatch({ type: actionType, payload: value });
		} catch (error) {
			console.error(error.response);
			// dispatch({ type: ActionType.ERROR, payload: error });
		}
	}, [apiClient, dispatch]);

	const updateEntrySetting = useCallback(async (setting) => await updateUserSetting(setting, '/user/update/entrySetting', ActionType.UPDATE_ENTRY_SETTING), [updateUserSetting]);

	const updateScreenSetting = useCallback(async (setting) => await updateUserSetting(setting, '/user/update/screenSetting', ActionType.UPDATE_SCREEN_SETTING), [updateUserSetting]);

	const updateLanguage = useCallback(async (setting) => await updateUserSetting(setting, '/user/update/defaultLanguage', ActionType.UPDATE_LANGUAGE), [updateUserSetting]);

	const updatePassword = useCallback(async (setting) => await updateUserSetting(setting, '/user/update/password', ActionType.UPDATE_PASSWORD), [updateUserSetting]);

	const updateImage = useCallback(async (image) => {
		if (!image) {
			return;
		}
		
		const data = new FormData();
		data.append('value', image);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
			},
		};

		try {
			const response = await apiClient.post('/upload', data, config);
			console.log(response);
			dispatch({ type: ActionType.UPDATE_USER_IMAGE, payload: response.data.fileName });
		} catch (error) {
			console.error(error.response);
			// dispatch({ type: ActionType.ERROR, payload: error });
		}
	}, [apiClient, dispatch]);

	const isStatusUnknown = useCallback(() => state.status === AuthStatus.Unknown || state.status === AuthStatus.Authenticating, [state.status]);

	return {
		user: state.user,
		accessToken: state.accessToken,
		language: state.language,
		status: state.status,
		isStatusUnknown,
		login,
		logout,
		tryAutoLogin,
		signUp,
		confirmSignUp,
		resetPassword,
		recoverPassword,
		updateEntrySetting,
		updateScreenSetting,
		updateLanguage,
		updatePassword,
		updateImage,
		setLanguage,
	};
};
