import Button from 'components/core/button';
import TranslationTag from 'utils/translations';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import './index.scss';

function GlobalOverlay() {
	const [open, setOpen] = useLocalStorage('global-overlay', true, sessionStorage);
	const [hideAlways, setHideAlways] = useLocalStorage('hide-overlay', false);
	const { i18n } = useTranslation('translations');
	
	const close = (always = false) => {
		if (always) {
			setHideAlways(true);
		}
		setOpen(false);
	};
	
	if (hideAlways || (i18n.language !== 'it' && i18n.language !== 'it-IT') || !open) {
		return null;
	}
	
	return (
		<div className="global-overlay">
			<div className="bg"></div>
			<div className="overlay">
				<div className="inner">
					<div className="intro">
						<div className="headline t1"><TranslationTag tag="Overlay_Headline" /></div>
						<div className="text t2">
							<TranslationTag tag="Overlay_Text" />&nbsp;
							<a href="https://ewocorlight-my.sharepoint.com/:f:/g/personal/matteo_dalvit_ewo_com/Egn3cYVAct1KoKFw4xy45wEB8XCZleNm0Rkm76zeW9waPQ?e=ijI4f6" target="_blank" rel="noreferrer"><TranslationTag tag="Overlay_LinkText" /></a>
						</div>
						<div className="text t2"><TranslationTag tag="Overlay_Text2" /></div>
					</div>
					<div className="outer-content">
						<div className="content">
							<Button onClick={() => close()} text="Overlay_ButtonText" class="btn-primary"/>
							<Button onClick={() => close(true)} text="Overlay_CheckboxText" class="btn-secondary"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GlobalOverlay;
