import Button from 'components/core/button';
import Card from 'components/core/card';
import ColorCard from 'components/core/color-card';
import Note from 'components/core/note';
import TranslationTag from 'utils/translations';
import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

function TypeColors({ currentQuestion, onSubmit }) {
	const [additionalView, setAdditionalView] = useState(false);
	const [activeColorName, setActiveColorName] = useState('');
	const [activeColorId, setActiveColorId] = useState('');
	const [activeColorCode, setActiveColorCode] = useState('');
	const [activeColorImage, setActiveColorImage] = useState('');
	var element = document.getElementById('resize-container');
	
	const toggleClass = () => {
		setAdditionalView(!additionalView);
		element.classList.toggle('color-overlay');
		setActiveColorName('');
		setActiveColorId('');
		setActiveColorCode('');
		setActiveColorImage('');
	};
	
	useEffect(() => {
		return function cleanup() {
			element.classList.remove('color-overlay');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	return (
		<div className="color-list">
			{ currentQuestion.data.answers &&
				currentQuestion.data.answers.filter(answer => answer.proposed === true).map((answerObject) => (
					<ColorCard
						active={currentQuestion.answerId === answerObject.id}
						class="item"
						key={answerObject.id}
						colorCode={answerObject.hexCodeColor}
						colorImage={answerObject.colorGraphic}
						name={answerObject.name}
						text={answerObject.text}
						onClick={() => onSubmit(answerObject.id, answerObject.name, answerObject.hexCodeColor, answerObject.colorGraphic)}
					/>
				))
			}
			{ additionalView &&
				<div className="color-overlay">
					<div className="bg"></div>
					<div className="content">
						<div className="intro-container">
							<div className="t2 title">
								<TranslationTag tag="Configuration_ColorOverlayHeadline" />
								<div className="close-button">
									<Button
										onClick={() => toggleClass()}
										icon="close"
										width="14"
										height="14"
									/>
								</div>
							</div>
							<Note
								headline="Configuration_ColorOverlayHintHeadline"
								text="Configuration_ColorOverlayHintText"
							/>
						</div>
						<div className="colors">
							<SimpleBar style={{ maxHeight: 'calc(100vh - 325px)' }} autoHide={false}>
								<div className="inner-colors">
									{ currentQuestion.data.answers &&
										currentQuestion.data.answers.filter(answer => answer.proposed === false).map((answerObject) => (
											<ColorCard
												class="item"
												key={answerObject.id}
												colorCode={answerObject.hexCodeColor}
												colorImage={answerObject.colorGraphic}
												name={answerObject.name}
												text={answerObject.text}
												onClick={() => {
													setActiveColorName(answerObject.name);
													setActiveColorId(answerObject.id);
													setActiveColorCode(answerObject.hexCodeColor);
													setActiveColorImage(answerObject.colorGraphic);
												}}
												active={(activeColorId === answerObject.id || (currentQuestion.answerId === answerObject.id && activeColorId === '')) ? true: false}
											/>
										))
									}
								</div>
							</SimpleBar>
						</div>
						<div className="buttons">
							<div className="inner-buttons">
								{ activeColorName &&
									<Button
										onClick={() => onSubmit(activeColorId, activeColorName, activeColorCode, activeColorImage)}
										text="Configuration_ColorOverlayButton"
										class="btn-primary"
									/>
								}
								<Button
									onClick={() => toggleClass()}
									text="Configuration_ColorOverlayButtonAbort"
									class="btn-secondary"
								/>
							</div>
						</div>
					</div>
				</div>
			}
			<Card
				headline="Configuration_ColorHeadline"
				text="Configuration_ColorText"
				onClick={() => toggleClass()}
			/>
		</div>
	);
}

TypeColors.defaultProps = {
	currentQuestion: '',
	onSubmit: () => void(0),
};

export default TypeColors;