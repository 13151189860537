import TranslationTag from 'utils/translations';
import './index.scss';

function Link(props) {
	return (
		<div className={'link-container no-hover'}>
			<button className={`link${props.class ? ' ' + props.class : ''}`} type={props.type} onClick={props.onClick}>
				{ props.text ?
					<TranslationTag tag={props.text} />
					:
					props.htmlText
				}
			</button>
		</div>
	);
}

Link.defaultProps = {
	class: '',
	text: '',
	htmlText: '',
	type: 'button',
	onClick: () => void(0),
};

export default Link;
