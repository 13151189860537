import { useTranslation } from 'react-i18next';
import BackgroundImage from 'images/login.jpg';
import Logo from 'images/logo.svg';
import Form from 'components/forms/form';
import Input from 'components/forms/input';
import Password from 'components/forms/password';
import Separator from 'components/forms/separator';
import Select from 'components/forms/select';
import Button from 'components/core/button';
import Checkbox from 'components/forms/checkbox';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Errors, Routes, Genders, StaticUrls } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useCountryApi } from 'hooks/useCountryApi';
import { prepareCountriesForSelect } from 'utils/objects';
import './style.scss';

function Registration() {
	const { signUp } = useAuth();
	const { state } = useCountryApi();
	const history = useHistory();
	const { t, i18n } = useTranslation('translations');

	const handleSubmit = async (values) => {
		// hint: passwordCheck and privacy1 not needed, so we take it out of 'values' with destructuring and keep the rest in 'properties'
		// country needs custom treatment because the value is wrapped in object
		const { passwordCheck, privacy1, country, ...properties } = values;
		await signUp({
			...properties,
			country: country.value,
		});
		history.push(Routes.registration.success);
	};
	
	const validationSchema = Yup.object({
		username: Yup.string().email(t(Errors.EMail)).required(t(Errors.Required)),
		password: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter)),
		passwordCheck: Yup
			.string()
			.required(t(Errors.Required))
			.min(8, t(Errors.PasswordLength))
			.max(20, t(Errors.PasswordLength))
			.matches(/^(?=.*[a-z])/, t(Errors.LowerCaseCharacter))
			.matches(/^(?=.*[A-Z])/, t(Errors.UpperCaseCharacter))
			.matches(/^(?=.*[0-9])/, t(Errors.NumberCharacter))
			.matches(/^(?=.*[!@#$%^&*?€()\-_=+{};:,<.>])/, t(Errors.SpecialCharacter))
			.oneOf([Yup.ref('password'), null], t(Errors.PasswordMatch)),
		salutation: Yup.object().nullable().required(t(Errors.Required)),
		firstName: Yup.string().required(t(Errors.Required)),
		secondName: Yup.string().required(t(Errors.Required)),
		job: Yup.string().required(t(Errors.Required)),
		privacy1: Yup.boolean().required(t(Errors.Required)).oneOf([true], t(Errors.Privacy)),
	});
	
	return (
		<Form
			initialValues={{
				username: '',
				password: '',
				passwordCheck: '',
				salutation: '',
				firstName: '',
				secondName: '',
				job: '',
				country: { value: '' },
				newsletter: false,
				privacy1: false,
			}}
			validationSchema={validationSchema}
			onSubmit={(values) => handleSubmit(values)}
		>
			<div className="registration">
				<div className="bg"><img src={BackgroundImage} alt="" /></div>
				<div className="scroll-container">
					<div className="outer-form-container">
						<div className="form-container">
							<div className="inner">
								<div className="intro d-flex justify-content-between">
									<Link to={Routes.login.index}><img src={Logo} alt="" /></Link>
									<div className="back-container">
										<Button
											onClick={() => history.push(Routes.login.index)}
											icon="close"
											width="14"
											height="14"
										/>
									</div>
								</div>
								<div className="content-container">
									<Separator
										text="Registration_Headline1"
									/>
									<Input
										name="username"
										text="Form_Email"
									/>
									<Password
										name="password"
										text="Form_Password"
									/>
									<Password
										name="passwordCheck"
										text="Form_PasswordRepeat"
									/>
									<Separator
										text="Registration_Headline2"
									/>
									<Select
										name="salutation"
										placeholder={t('Form_Salutation')}
										options={Genders(t)}
									/>
									<Input
										name="firstName"
										text="Form_Firstname"
									/>
									<Input
										name="secondName"
										text="Form_Lastname"
									/>
									<Input
										name="job"
										text="Form_Job"
									/>
									<Select
										name="country"
										placeholder={t('Form_Country')}
										options={prepareCountriesForSelect(state.countries)}
										searchable={true}
									/>
								</div>
							</div>
							<div className="submit-container">
								<div className="gradient"></div>
								<div className="inner-submit">
									<Separator
										text="Registration_Headline3"
									/>
									<Checkbox
										name="newsletter"
										id="newsletter"
										text="Form_Newsletter"
									/>
									<Checkbox
										name="privacy1"
										id="privacy1"
										text="Form_Privacy1"
										textLink={StaticUrls('privacy1', '', i18n.language)}
									/>
									<Button
										class="btn-primary"
										text="Registration_Createaccount"
										type="submit"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</Form>
	);
}

export default Registration;