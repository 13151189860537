import TranslationTag from 'utils/translations';

function Headline(props) {
	const CustomTag = `${props.type}`;
	
	return (
		<CustomTag className={props.class}><TranslationTag tag={props.text} /></CustomTag>
	);
}

Headline.defaultProps = {
	class: '',
	text: '',
	type: '',
};

export default Headline;
