import ProjectList from 'components/archive/project-list';
import { useEffect, useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';

const WithoutFolder = () => {
	const { init, loadProjectsWithoutFolder } = useArchiveApi();
	const [archiveApiInitialized, setArchiveApiInitialized] = useState(false);
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [projectsLoaded, setProjectsLoaded] = useState(false);
	
	useEffect(() => {
		const load = async () => {
			await loadProjectsWithoutFolder();
			setProjectsLoaded(true);
			setLoadingProjects(false);
		};
		if (archiveApiInitialized && !projectsLoaded && !loadingProjects) {
			setLoadingProjects(true);
			load();
		}
	}, [archiveApiInitialized, projectsLoaded, loadProjectsWithoutFolder, loadingProjects]);
	
	useEffect(() => {
		if (!archiveApiInitialized) {
			init({
				projects: {
					pageSize: 5,
				},
			});
			setArchiveApiInitialized(true);
		}
	}, [archiveApiInitialized, init]);
	
	return (
		<>
			<ProjectList
				showLoading={loadingProjects}
				projectsLoaded={projectsLoaded}
			/>
		</>
	);
};

export default WithoutFolder;