import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Product from 'components/core/product';
import { Routes } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import './style.scss';

function Favorites() {
	const history = useHistory();
	const { state, getOrderInfo, createFavorite } = useConfiguratorApi();
	const [selectedConfigurations, setSelectedConfigurations] = useState([]);
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	const handleSubmit = async () => {
		if (selectedConfigurations) {
			selectedConfigurations.map(await function(configId) {
				createFavorite({ id: configId, orderId: -1 });
			});
			history.push(Routes.configuration.summary);
		}
	};
	
	const changeConfigurations = (configId) => {
		let tempConfigs = [...selectedConfigurations];
		if (tempConfigs.includes(configId)) {
			tempConfigs.splice(tempConfigs.indexOf(configId), 1);
		} else {
			tempConfigs.push(configId);
		}
		setSelectedConfigurations(tempConfigs);
	};
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	useEffect(() => {
		if (infos !== null && infos?.configurations.length) {
			let tempConfigs = [];
			infos.configurations.forEach(function(config){
				tempConfigs.push(config.pcpSaveId);
			});
			setSelectedConfigurations(tempConfigs);
		}
	}, [infos]);
	
	if (!state.projectId) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="favorites">
			<Overlay
				headline="Favorites_Headline"
				text="Favorites_Text"
				backLink={Routes.configuration.summary}
			>
				<Form
					initialValues=""
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<div className="products">
						{ infos.configurations.length > 0 &&
							infos.configurations.map((configuration) => (
								<Product
									key={configuration.pcpSaveId}
									name={configuration.family}
									image={configuration.image}
									summary={configuration.summary}
									configurationId={configuration.pcpSaveId}
									onClick={(configId) => changeConfigurations(configId)}
									active={selectedConfigurations.includes(configuration.pcpSaveId)}
									favoriteLayout={true}
								/>
							))
						}
					</div>
					<Button
						class="btn-primary"
						type="submit"
						text="Favorites_SaveFavorites"
					/>
					<Button
						class="btn-secondary"
						text="Favorites_Close"
						onClick={() => history.push(Routes.configuration.summary)}
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default Favorites;