import ProjectList from 'components/archive/project-list';
import { useEffect, useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';

const SearchProject = ({ isSearching }) => {
	const { init } = useArchiveApi();
	const [archiveApiInitialized, setArchiveApiInitialized] = useState(false);
	
	useEffect(() => {
		if (!archiveApiInitialized) {
			init({
				projects: {
					pageSize: 5,
				},
			});
			setArchiveApiInitialized(true);
		}
	}, [archiveApiInitialized, init]);
	
	return (
		<>
			<ProjectList
				isSearching={isSearching}
				isSearch={true}
				projectsLoaded={true}
			/>
		</>
	);
};

export default SearchProject;