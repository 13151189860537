import Button from 'components/core/button';
import Tabs from 'components/navigation/tabs';
import Link from 'components/core/link';
import UserImage from 'components/core/user-image';
import Icon from 'components/core/icon';
import TranslationTag from 'utils/translations';
import { useTranslation  } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import { useState } from 'react';
import './style.scss';

function Families({ question, families, onSkip }) {
	const { answer, start } = useConfiguratorApi();
	const history = useHistory();
	const { user } = useAuth();
	const { t } = useTranslation('translations');
	const [guestInfoVisible, setGuestInfoVisible] = useState(true);
	
	const handleAnswer = async (questionId, answerId, text) => {
		if (question.identifier === 'ProductCategory') {
			onSkip();
		}
		answer(questionId, answerId, text);
	};
	
	return (
		<div className="families">
			{ user ? (
				<div className="header inverse">
					<div className="center-content d-flex justify-content-end align-items-center">
						<div className="buttons d-flex align-items-center">
							<UserImage />
						</div>
					</div>
				</div>
			) : (
				guestInfoVisible &&
				<div className="header inverse guest-info">
					<div className="center-content d-flex align-items-center justify-content-between">
						<div className="content-container">
							<div><TranslationTag tag="Configuration_GuestInfo" /></div>
							<Link
								text={'Configuration_LoginNow'}
								onClick={() => history.push(Routes.login.index)}
							/>
						</div>
						<Icon
							icon="close"
							onClick={() => setGuestInfoVisible(false)}
						/>
					</div>
				</div>
			)}
			<div className="center-content">
				<div className="intro d-flex justify-content-between">
					<div className="text t1"><TranslationTag tag="Configuration_FamilyHeadline" /></div>
					<Button
						class="btn-primary"
						text="Configuration_SkipStep"
						onClick={onSkip}
					/>
				</div>
				<Tabs class="v2">
					<div className="buttons t2">
						<Link
							class={question.identifier === 'ProductFamily' ? 'active': ''}
							text={'Profile_ConfiguratorStartOption1Headline'}
							onClick={() => start(0)}
						/>
						<Link
							class={question.identifier === 'ProductCategory' ? 'active': ''}
							text={'Profile_ConfiguratorStartOption2Headline'}
							onClick={() => start(1)}
						/>
					</div>
				</Tabs>
				
				<div className="items d-flex flex-wrap">
					{ families &&
						families.map((answerObject) => (
							<div className="item" key={answerObject.id} onClick={() => handleAnswer(question.identifier, answerObject.id, t(answerObject.text))}>
								<div className="image"><img src={answerObject.image} alt={t(answerObject.text)} /></div>
								<div className="text t2"><TranslationTag tag={answerObject.text} /></div>
							</div>
						))
					}
				</div>
			</div>
		</div>
	);
}

export default Families;