import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Separator from 'components/forms/separator';
import Button from 'components/core/button';
import Slider from 'components/core/slider';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { useAuth } from 'hooks/useAuth';
import './style.scss';

function ProjectPrice() {
	const history = useHistory();
	const { user } = useAuth();
	const { updatePriceArchiveProject } = useArchiveApi();
	const [isLoading, setIsLoading] = useState(false);
	const [isListPrice, setIsListPrice] = useState('');
	const [discount, setDiscount] = useState('');
	
	const handleSubmit = async () => {
		setIsLoading(true);
		await updatePriceArchiveProject(history.location.state.project.projectId, user.listPriceAvailable ? (isListPrice === '1' ? 1 : 0) : '', user.discountAvailable ? discount : '');
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	if (!history.location.state || !history.location.state.project) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-price">
			<Overlay
				headline="Archive_PriceProjectHeadline"
				text="Archive_PriceProjectText"
				backLink={Routes.profile.archive.index}
			>
				<SpinnerLoader
					show={isLoading}
				/>
				<Form
					initialValues=""
					validationSchema=""
					onSubmit={handleSubmit}
				>
					{ user.discountAvailable &&
						<>
							<Separator
								text={(user.economicArea && user.economicArea === 'US') ? 'Archive_ChangePriceHeadlineMargin' : 'Archive_ChangePriceHeadline'}
							/>
							<Slider
								min={user.discountMin ? user.discountMin : 0}
								max={user.discountMax ? user.discountMax : 100}
								defaultValue={discount === '' ? 0 : discount}
								unit="%"
								onChange={(value) => {
									setDiscount(value);
									if (value > 0 && isListPrice === '1') {
										setIsListPrice('2');
									}
								}}
								step={1}
								class="item"
								usLogic={user.economicArea && user.economicArea === 'US'}
							/>
						</>
					}
					{ user.listPriceAvailable &&
						<>
							<Separator
								text="Archive_ChangeListPrice"
							/>
							<div className="list-price-buttons d-flex justify-content-between">
								<Button
									class={'btn-secondary' + (isListPrice === '1' ? ' active' : '')}
									onClick={() => {
										setIsListPrice('1');
										setDiscount(0);
									}}
									text="Archive_ListPriceYes"
								/>
								<Button
									class={'btn-secondary' + (isListPrice === '2' ? ' active' : '')}
									onClick={() => setIsListPrice('2')}
									text="Archive_ListPriceNo"
								/>
							</div>
						</>
					}
					<Button
						class={'btn-primary' + ((isListPrice === '' && user.listPriceAvailable) ? ' disabled' : '')}
						type="submit"
						text="Archive_PriceProjectSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_PriceProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectPrice;