import Folder from 'components/archive/folder';
import Pager from 'components/core/pager';
import Link from 'components/core/link';
import SpinnerLoader from 'components/system/spinner-loader';
import TranslationTag from 'utils/translations';
import { useArchiveApi, ArchiveEntityType, makeSelectFoldersByCurrentPageNumber } from 'hooks/useArchiveApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const Folders = () => {
	const history = useHistory();
	const { t } = useTranslation('translations');
	const { init, loadArchiveFolders, state, goToPage } = useArchiveApi();
	const [archiveApiInitialized, setArchiveApiInitialized] = useState(false);
	const [loadingFolders, setLoadingFolders] = useState(false);
	const [foldersLoaded, setFoldersLoaded] = useState(false);
	const selectFoldersByCurrentPageNumber = useMemo(() => makeSelectFoldersByCurrentPageNumber(), []);
	const folders = selectFoldersByCurrentPageNumber(state);
	const { queryParams, setQueryParams } = useQueryParams();

	const currentPage = useMemo(() => parseInt(queryParams.get('page') || 1, 10), [queryParams]);
	
	useEffect(() => {
		const load = async () => {
			await loadArchiveFolders();
			setFoldersLoaded(true);
			setLoadingFolders(false);
		};
		
		if (archiveApiInitialized && !foldersLoaded && !loadingFolders) {
			setLoadingFolders(true);
			load();
		}
	}, [archiveApiInitialized, foldersLoaded, loadArchiveFolders, loadingFolders]);
	
	useEffect(() => {
		if (!archiveApiInitialized) {
			init({
				folders: {
					pageSize: 10,
					pageNumber: currentPage,
				},
				projects: {
					pageSize: 5,
				},
			});
			setArchiveApiInitialized(true);
		}
	}, [archiveApiInitialized, currentPage, init]);

	useEffect(() => {
		if (currentPage !== state.folders.pageNumber) {
			setQueryParams({
				page: state.folders.pageNumber,
			});
		}
	}, [currentPage, setQueryParams, state.folders.pageNumber]);

	return (
		<>
			<div className="folder-action">
				<Link
					htmlText={'+ ' + t('Archive_CreateFolder')}
					onClick={() => history.push(Routes.profile.archive.folder.create)}
				/>
			</div>
			<div className="folders">
				{ folders && folders.length ?
					folders.map((folder) => (
						<Folder
							key={folder.folderId}
							folder={folder}
						/>
					))
					:
					(folders && !folders.length && foldersLoaded) &&
					<div className="no-results t2">
						<TranslationTag tag="Archive_NoFolders" />
					</div>
				}
				<SpinnerLoader
					show={!foldersLoaded || loadingFolders}
				/>
			</div>
			<Pager
				pageSize={state.folders.pageSize}
				pageNumber={state.folders.pageNumber}
				totalCount={state.folders.totalCount}
				onGoToPage={(page) => goToPage(ArchiveEntityType.Folder, page)}
			/>
		</>
	);
};

export default Folders;