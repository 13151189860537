import UserImage from 'components/core/user-image';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import Archive from './archive';
import FolderCreate from './archive/folder/create';
import FolderDelete from './archive/folder/delete';
import FolderDuplicate from './archive/folder/duplicate';
import FolderEdit from './archive/folder/edit';
import ProjectChangeFolder from './archive/project/changeFolder';
import ProjectChangeLanguage from './archive/project/changeLanguage';
import ProjectDuplicate from './archive/project/duplicate';
import ProjectPDF from './archive/project/pdf';
import ProjectPrice from './archive/project/price';
import ProjectRemove from './archive/project/remove';
import ProjectRevisions from './archive/project/revisions';
import ProjectShare from './archive/project/share';
import MyData from './my-data';
import './style.scss';

function Profile() {
	const history = useHistory();
	
	return (
		<div className="profile-container">
			<div className="header inverse">
				<div className="center-content d-flex justify-content-between align-items-center">
					<div className="nav t2">
						<NavLink to={Routes.profile.archive.index}>
							<TranslationTag tag="Profile_Archive" />
						</NavLink>
						<NavLink to={Routes.profile.data.index}>
							<TranslationTag tag="Profile_YourData" />
						</NavLink>
					</div>
					<div className="buttons d-flex align-items-center">
						<a onClick={() => history.push(Routes.login.index) } href="#back"><TranslationTag tag="Profile_BackToConfigurator" /></a>
						<UserImage />
					</div>
				</div>
			</div>
			<div className="content">
				<div className="center-content">
					<Switch>
						<Route exact path={Routes.profile.index}>
							<Redirect
								to={Routes.profile.data.index}
							/>
						</Route>
						<Route exact path={Routes.profile.archive.folder.delete}>
							<FolderDelete />
						</Route>
						<Route exact path={Routes.profile.archive.folder.duplicate}>
							<FolderDuplicate />
						</Route>
						<Route exact path={Routes.profile.archive.folder.edit}>
							<FolderEdit />
						</Route>
						<Route exact path={Routes.profile.archive.folder.create}>
							<FolderCreate />
						</Route>
						<Route exact path={Routes.profile.archive.project.duplicate}>
							<ProjectDuplicate />
						</Route>
						<Route exact path={Routes.profile.archive.project.changeFolder}>
							<ProjectChangeFolder />
						</Route>
						<Route exact path={Routes.profile.archive.project.changeLanguage}>
							<ProjectChangeLanguage />
						</Route>
						<Route exact path={Routes.profile.archive.project.share}>
							<ProjectShare />
						</Route>
						<Route exact path={Routes.profile.archive.project.price}>
							<ProjectPrice />
						</Route>
						<Route exact path={Routes.profile.archive.project.pdf}>
							<ProjectPDF />
						</Route>
						<Route exact path={Routes.profile.archive.project.revisions}>
							<ProjectRevisions />
						</Route>
						<Route exact path={Routes.profile.archive.project.remove}>
							<ProjectRemove />
						</Route>
						<Route path={Routes.profile.archive.index}>
							<Archive />
						</Route>
						<Route path={Routes.profile.data.index}>
							<MyData />
						</Route>
						<Route path="*">
							<Redirect
								to={Routes.profile.data.index}
							/>
						</Route>
					</Switch>
				</div>
			</div>
		</div>
	);
}

export default Profile;