import Overlay from 'components/core/overlay';
import Project from 'components/archive/project';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useArchiveApi } from 'hooks/useArchiveApi';
import React, { useState, useEffect } from 'react';
import './style.scss';

function ProjectRevisions() {
	const history = useHistory();
	const { loadProjectRevisions } = useArchiveApi();
	const [loadingRevisions, setLoadingRevisions] = useState(false);
	const [revisions, setRevisions] = useState(null);
	
	useEffect(() => {
		const load = async () => {
			setRevisions(await loadProjectRevisions(history.location.state.projectId));
			setLoadingRevisions(false);
		};
		if (revisions === null && loadingRevisions === false) {
			setLoadingRevisions(true);
			load();
		}
	}, [revisions, loadProjectRevisions, loadingRevisions, setRevisions, setLoadingRevisions, history.location.state]);
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-revisions">
			<Overlay
				headline="Archive_ProjectVersionsHeadline"
				text="Archive_ProjectVersionsText"
				backLink={Routes.profile.archive.index}
				fullWidth={true}
			>
				<div className="projects">
					<SpinnerLoader
						show={!revisions}
					/>
					{ revisions &&
						revisions.map((project) => (
							<Project
								key={project.projectId}
								project={project}
								revision={true}
							/>
						))
					}
				</div>
			</Overlay>
		</div>
	);
}

export default ProjectRevisions;