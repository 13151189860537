import Icon from '../icon';
import Button from '../button';
import { Routes } from 'utils/constants';
import TranslationTag from 'utils/translations';
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

function UpdateBadge ({ project, onUpdate }) {
	const [isOpen, setIsOpen] = useState(false);
	const history = useHistory();
	const myRef = useRef();
	
	const handleClick = e => {
		if (!myRef.current.contains(e.target)) {
			setIsOpen(false);
		}
	};
	
	useEffect(() => {
		document.addEventListener('mousedown', handleClick);
		return () => document.removeEventListener('mousedown', handleClick);
	});
	
	return (
		<div className={'update-badge d-flex' + (project.available ? ' available' : ' not-available') + (isOpen ? ' open' : '')} ref={myRef}>
			<div className="text t4" onClick={() => setIsOpen(!isOpen)}>
				{ project.available ?
					<>
						<TranslationTag tag="Archive_UpdateNeeded" />
						<div className="update-count">
							<span>{ project.updates }</span>
						</div>
					</>
					:
					<TranslationTag tag="Archive_UpdateNotPossible" />
				}
			</div>
			<div className="arrow-container" onClick={() => setIsOpen(!isOpen)}>
				<Icon
					icon="arrow-down"
					width="11"
					height="6"
				/>
			</div>
			{ isOpen &&
				<div className="badge-text">
					<div className="inner">
						<div className="title t4">
							{ project.available ?
								<TranslationTag tag="Archive_ProjectUpdateHeadline" />
								:
								<TranslationTag tag="Archive_ProjectUpdateNotPossibleHeadline" />
							}
						</div>
						<div className="cont smallFs">
							{ project.available ?
								<TranslationTag tag="Archive_ProjectUpdateText" />
								:
								<>
									<TranslationTag tag="Archive_ProjectUpdateNotPossibleText" />
									{ (project.abortCondition && project.abortCondition.length) &&
										<div className="logs">
											<ul className="inner-log">
												{ project.abortCondition.map((update, key) => (
													<li className="update" key={key}>
														<span>{update.key} -</span> {update.message}
													</li>
												))}
											</ul>
										</div>
									}
								</>
							}
						</div>
						<Button
							text={project.available ? 'Archive_ProjectUpdateButton' : 'Archive_ProjectUpdateNotPossibleButton'}
							class="btn-secondary small"
							onClick={() => project.available ? onUpdate() : history.push(Routes.configuration.index)}
						/>
					</div>
				</div>
			}
		</div>
	);
}

export default UpdateBadge;
