import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Select from 'components/forms/select';
import SpinnerLoader from 'components/system/spinner-loader';
import { Routes, Errors } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { prepareUsersForSelect } from 'utils/objects';
import { useAuth } from 'hooks/useAuth';
import * as Yup from 'yup';
import './style.scss';

function ProjectShare() {
	const { t } = useTranslation('translations');
	const { loadAllUsers, shareProject } = useArchiveApi();
	const history = useHistory();
	const { user: currentUser } = useAuth();
	const [loadingUsers, setLoadingUsers] = useState(false);
	const [users, setUsers] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const validationSchema = Yup.object({
		user: Yup.object().required(t(Errors.Required)),
	});
	
	const handleSubmit = async (values) => {
		setIsLoading(true);
		await shareProject(history.location.state.projectId, values.user ? values.user.value : '');
		setIsLoading(false);
		history.push(Routes.profile.archive.index);
	};
	
	useEffect(() => {
		const load = async () => {
			let users = await loadAllUsers();
			users = users.filter((user) => user.id !== currentUser.id);
			setUsers(users);
			setLoadingUsers(false);
		};
		if (users === null && loadingUsers === false) {
			setLoadingUsers(true);
			load();
		}
	}, [users, loadAllUsers, loadingUsers, setUsers, setLoadingUsers, currentUser]);
	
	if (!history.location.state || !history.location.state.projectId) {
		history.push(Routes.profile.archive.index);
	}
	
	return (
		<div className="project-change-folder">
			<Overlay
				headline="Archive_ShareProjectHeadline"
				text="Archive_ShareProjectText"
				backLink={Routes.profile.archive.index}
			>
				<Form
					initialValues={{
						user: '',
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					{ users &&
						<Select
							name="user"
							placeholder={t('Archive_ShareProjectUsers')}
							options={prepareUsersForSelect(users)}
							searchable={true}
						/>
					}
					<Button
						class="btn-primary"
						type="submit"
						text="Archive_ShareProjectSubmit"
					/>
					<Button
						class="btn-secondary"
						onClick={() => history.push(Routes.profile.archive.index)}
						text="Archive_ShareProjectAbort"
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default ProjectShare;