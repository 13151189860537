import Search from 'components/forms/search';
import Form from 'components/forms/form';
import { Routes } from 'utils/constants';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './index.scss';

function ArchiveSearch ({ onSearch }) {
	let formikApi = null;
	const location = useLocation();
	const handleSubmit = async (values) => {
		if (values.searchTerm) {
			onSearch(values.searchTerm);
		}
	};
	
	useEffect(() => {
		if (location.pathname !== Routes.profile.archive.search && formikApi) {
			formikApi.setFieldValue('searchTerm', '');
		}
	}, [location.pathname, formikApi]);
	
	return (
		<div className="archive-search">
			<Form
				initialValues={{
					searchTerm: '',
				}}
				validationSchema=""
				onSubmit={handleSubmit}
				registerAPI={(api) => { formikApi = api; }}
			>
				<Search
					name="searchTerm"
					text="Archive_Search"
				/>
			</Form>
		</div>
	);
}

export default ArchiveSearch;
