import TranslationTag from 'utils/translations';
import Icon from 'components/core/icon';
import { Fragment } from 'react';
import ReportToolOverlay from './overlay';
import { useState } from 'react';
import './index.scss';

function ReportTool() {
	const [overlayVisible, setOverlayVisible] = useState(false);
	return (
		<Fragment>
			<ReportToolOverlay
				isOpen={overlayVisible}
				onClose={() => setOverlayVisible(false)}
			/>
			<div className="report-tool" onClick={() => setOverlayVisible(true)}>
				<Icon
					icon="bug"
				/>
				<div className="text">
					<TranslationTag tag="ReportTool_ReportError" />
				</div>
			</div>
		</Fragment>
	);
}

export default ReportTool;
