import Icon from 'components/core/icon';
import DotMenu from 'components/navigation/dot-menu';
import DotMenuItem from 'components/navigation/dot-menu/item';
import TranslationTag from 'utils/translations';
import ProjectList from '../project-list';
import { useEffect, useMemo, useState } from 'react';
import { useArchiveApi } from 'hooks/useArchiveApi';
import { makeSelectCountryById, useCountryApi } from 'hooks/useCountryApi';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/constants';
import './index.scss';

function Folder ({ folder = {}}) {
	const history = useHistory();
	const { folderId = null } = folder;
	const { loadArchiveFolderById, state: archiveState } = useArchiveApi();
	const { state: countryState } = useCountryApi();
	const selectCountryById = useMemo(() => makeSelectCountryById(), []);
	const country = selectCountryById(countryState, folder.countryId);
	const countryCustomer = selectCountryById(countryState, folder.customerCountryId);
	const [isOpening, setIsOpening] = useState(false);
	const [isManuallyClosed, setIsManuallyClosed] = useState(false);
	const [isFolderActive, setIsFolderActive] = useState(false);
	
	useEffect(() => {
		if (!isFolderActive && archiveState.projects.folderId === folderId) {
			setIsFolderActive(true);
			setIsOpening(false);
			setIsManuallyClosed(false);
		}
		if (isFolderActive && archiveState.projects.folderId !== folderId) {
			setIsFolderActive(false);
		}
	}, [folderId, isFolderActive, isOpening, archiveState.projects.folderId]);
	
	const isOpen = (isOpening || isFolderActive) && !isManuallyClosed;
	
	const toggleFolder = async () => {
		if (isOpen) {
			setIsManuallyClosed(true);
			return;
		}
		if (!isFolderActive) {
			setIsOpening(true);
			await loadArchiveFolderById(folderId);
		} else {
			setIsManuallyClosed(false);
		}
	};
	
	return (
		<div className={'archive-folder' + (isOpen ? ' open' : '')}>
			<div className="inner d-flex justify-content-between">
				<div className="headline d-flex flex-column justify-content-center" onClick={() => toggleFolder()}>
					{ folder.folderNumber &&
						<div className="location">
							<TranslationTag tag="Archive_FolderNumber" /> { folder.folderNumber }
						</div>
					}
					<div className="t2">
						{ folder.folderName }
					</div>
				</div>
				<div className="actions d-flex align-items-center">
					<DotMenu>
						<DotMenuItem
							icon="edit"
							text="Archive_Edit"
							width="20"
							height="20"
							onClick={() => history.push(Routes.profile.archive.folder.edit, { folder })}
						/>
						<DotMenuItem
							icon="duplicate"
							text="Archive_Duplicate"
							width="20"
							height="20"
							onClick={() => history.push(Routes.profile.archive.folder.duplicate, { folderId, folder })}
						/>
						<DotMenuItem
							icon="delete"
							text="Archive_Delete"
							width="20"
							height="20"
							onClick={() => history.push(Routes.profile.archive.folder.delete, { folderId })}
						/>
					</DotMenu>
					<div className="arrow" onClick={() => toggleFolder()}>
						<Icon
							icon="arrow-down"
							width="17"
							height="9"
						/>
					</div>
				</div>
		
			</div>
			{isOpen &&
				<div className="content">
					<div className="folder-data d-flex">
						{ (folder.street || folder.zipCode || folder.city || folder.countryId > 0) &&
							<div className="data">
								<div className="headline t2">
									<TranslationTag tag="Archive_Address" />
								</div>
								<div>
									{ folder.street &&
										<div>{ folder.street }</div>
									}
									<div>{ (folder.zipCode ? folder.zipCode : '') + (folder.city ? (' ' + folder.city) : '') + (((folder.city || folder.zipCode) && folder.countryId) ? ' – ' : '') + (folder.countryId ? country.countryCode : '')}</div>
								</div>
							</div>
						}
						{ folder.comment &&
							<div className="data">
								<div className="headline t2">
									<TranslationTag tag="Archive_Comment" />
								</div>
								<div>{ folder.comment }</div>
							</div>
						}
						{ (folder.customerFirstName || folder.customerSecondName || folder.customerCompany) &&
							<div className="data">
								<div className="headline t2">
									<TranslationTag tag="Archive_ClientData" />
								</div>
								{ (folder.customerFirstName || folder.customerSecondName) &&
									<div>{ folder.customerFirstName + ' ' + folder.customerSecondName }</div>
								}
								<div>{ folder.customerCompany }</div>
							</div>
						}
						{ (folder.customerStreet || folder.customerZipCode || folder.customerCity || folder.customerCountryId > 0) &&
							<div className="data">
								<div className="headline t2">
									<TranslationTag tag="Archive_AddressClient" />
								</div>
								<div>
									{ folder.customerStreet &&
										<div>{ folder.customerStreet }</div>
									}
									<div>{ (folder.customerZipCode ? folder.customerZipCode : '') + (folder.customerCity ? (' ' + folder.customerCity) : '') + (((folder.customerCity || folder.customerZipCode) && folder.customerCountryId) ? ' – ' : '') + (folder.customerCountryId ? countryCustomer.countryCode : '' )}</div>
								</div>
							</div>
						}
					</div>
					<ProjectList folderId={folder.folderId} showLoading={isOpening} folderView={true} />
				</div>
			}
		</div>
	);
}

export default Folder;
