import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
	const { search } = useLocation();
	const history = useHistory();
  
	const queryParams = useMemo(() => new URLSearchParams(search), [search]);

	const setQueryParams = useCallback((params) => {
		if (!(params instanceof URLSearchParams)) {
			params = new URLSearchParams(params);
		}
		history.push({
			search: '?' + params.toString(),
		});
	}, [history]);

	return {
		queryParams,
		setQueryParams,
	};
};