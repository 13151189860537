import TranslationTag from 'utils/translations';
import Link from '../link';
import './index.scss';

function Note(props) {
	return (
		<div className="note">
			<div className="inner">
				<div className="headline t2"><TranslationTag tag={props.headline} /></div>
				<div className="text">
					{ props.htmlText ?
						<span dangerouslySetInnerHTML={{ __html: props.htmlText }} />
						:
						<TranslationTag tag={props.text} />
					}
					{ props.linkText &&
						<Link
							text={props.linkText}
							onClick={props.onClickLinkText}
						/>
					}
				</div>
			</div>
		</div>
	);
}

Note.defaultProps = {
	headline: '',
	text: '',
	htmlText: '',
	linkText: '',
	onClickLinkText: () => void(0),
};

export default Note;
