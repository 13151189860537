import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import Product from 'components/core/product';
import Link from 'components/core/link';
import SpinnerLoader from 'components/system/spinner-loader';
import TranslationTag from 'utils/translations';
import React, { useState, useEffect } from 'react';
import { Routes } from 'utils/constants';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import { truncate } from 'utils/strings';
import { useAuth } from 'hooks/useAuth';
import './style.scss';

function Defaults() {
	const history = useHistory();
	const { user } = useAuth();
	const { t } = useTranslation('translations');
	const { state, getDefaults, deleteDefault, createDefault, updateDefaults, getOrderInfo } = useConfiguratorApi();
	const [loadingDefaults, setLoadingDefaults] = useState(false);
	const [defaults, setDefaults] = useState(null);
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	const [dragItems, setDragItems] = useState(null);
	const [configToDelete, setConfigToDelete] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentConfiguration, setCurrentConfiguration] = useState(null);
	const [currentConfigurationSlot, setCurrentConfigurationSlot] = useState(null);
	
	const handleSubmit = async () => {
		setIsLoading(true);
		let difference = dragItems
			.filter(x => !defaults.includes(x))
			.concat(defaults.filter(x => !dragItems.includes(x)));
		if (difference.length > 0) {
			await createDefault({ id: difference[0].id, orderId: -1 });
		}
		setDefaults(await getDefaults(currentConfigurationSlot.VariantId));
		let dragItemsOrder = dragItems.map((item, index) => {
			var temp = Object.assign({}, item);
			temp.orderId = index;
			return temp;
		});
		if (dragItemsOrder.length) {
			await updateDefaults(dragItemsOrder);
		}
		setIsLoading(false);
		history.push(Routes.configuration.summary);
	};
	
	const onDelete = async () => {
		setIsLoading(true);
		let result = (await deleteDefault(configToDelete)).data[currentConfigurationSlot.VariantId] ?? [];
		setDefaults(result);
		setDragItems(result);
		setConfigToDelete(null);
		setIsLoading(false);
	};
	
	function onChange(sourceId, sourceIndex, targetIndex, targetId) {
		if (targetId && targetId !== sourceId) {
			if (dragItems.length >= 3) {
				return;
			}
			let dragItemsArray = dragItems.slice();
			dragItemsArray.splice(targetIndex, 0, {
				...currentConfiguration,
				id: currentConfiguration.pcpSaveId,
				orderId: targetIndex,
			});
			setDragItems(dragItemsArray);
			setCurrentConfiguration(null);
		} else {
			const result = swap(dragItems, sourceIndex, targetIndex);
			return setDragItems(result);
		}
	}
	
	useEffect(() => {
		if (!currentConfigurationSlot && history.location.state?.currentConfiguration) {
			setCurrentConfiguration(history.location.state.currentConfiguration);
			setCurrentConfigurationSlot(history.location.state.currentConfiguration);
		}
	}, [currentConfigurationSlot, history.location.state]);
	
	useEffect(() => {
		const load = async () => {
			let response = await getDefaults(currentConfigurationSlot.VariantId);
			setDefaults(response);
			setDragItems(response);
			setLoadingDefaults(false);
		};
		if (defaults === null && loadingDefaults === false && currentConfigurationSlot) {
			setLoadingDefaults(true);
			load();
		}
	}, [currentConfigurationSlot, defaults, getDefaults, createDefault, loadingDefaults, setDefaults, setLoadingDefaults]);
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	if (!state.projectId || !user.allowedAddDefaultConfiguration) {
		history.push(Routes.configuration.index);
	}
	
	if (!history.location.state || !history.location.state.currentConfiguration) {
		history.push(Routes.configuration.defaultsSelectConfiguration);
	}
	return (
		<div className="defaults">
			<Overlay
				headline="Defaults_Headline"
				text="Defaults_Text"
				backLink={Routes.configuration.index}
			>
				<Form
					initialValues=""
					validationSchema=""
					onSubmit={handleSubmit}
				>
					<SpinnerLoader
						show={isLoading}
					/>
					<GridContextProvider onChange={onChange}>
						<div className="current-config">
							<div className="outer-drag-container">
								<GridDropZone
									id={'listCurrent'}
									boxesPerRow={1}
									className="product-drag-container"
									rowHeight={94}
									disableDrop={true}
									style={{ height: '94px' }}
								>
									{ currentConfiguration &&
										<GridItem key={currentConfiguration.id}>
											<div className="drag-container">
												<Product
													key={currentConfiguration.pcpSaveId}
													configurationId={currentConfiguration.pcpSaveId}
													name={t('Configuration_DefaultsCurrentConfiguration')}
													image={currentConfiguration.image}
													summary={currentConfiguration.summary ? truncate(currentConfiguration.summary.replace('<br>', ' | ').replace('<br />', ' | '), 60) : ''}
													hoverTitle={currentConfiguration.summary.replace('<br>', '\n').replace('<br />', '\n')}
													draggable={true}
												/>
											</div>
										</GridItem>
									}
								</GridDropZone>
							</div>
						</div>
						<div className="products">
							<DragContainer
								items={(dragItems) ? dragItems : null}
								onDelete={(configId) => setConfigToDelete(configId)}
							/>
						</div>
					</GridContextProvider>
					<Button
						class="btn-primary"
						type="submit"
						text="Defaults_SaveAndContinue"
					/>
					{ configToDelete &&
						<div className="delete-container">
							<div className="headline t2"><TranslationTag tag="Configuration_DefaultsDeleteHeadline" /></div>
							<div className="text"><TranslationTag tag="Configuration_DefaultsDeleteText" /></div>
							<div className="actions">
								<Link
									text="Configuration_DefaultsDeleteYes"
									onClick={() => onDelete()}
								/>
								<Link
									text="Configuration_DefaultsDeleteNo"
									onClick={() => setConfigToDelete(null)}
								/>
							</div>
						</div>
					}
				</Form>
			</Overlay>
		</div>
	);
}

const Configuration = ({ configuration, onDelete }) => {
	return (
		<Product
			configurationId={configuration.id}
			name={configuration.family}
			image={configuration.image}
			summary={configuration.summary ? truncate(configuration.summary.replace('<br>', ' | ').replace('<br />', ' | '), 60) : ''}
			defaultLayout={true}
			draggable={true}
			onDelete={!configuration.text ? onDelete : null}
			hoverTitle={configuration.summary.replace('<br>', '\n').replace('<br />', '\n')}
		/>
	);
};

const DragContainer = ({ items, onDelete }) => {
	return (
		<div className="outer-drag-container">
			<GridDropZone
				id={'listDefaults'}
				boxesPerRow={1}
				className="product-drag-container"
				rowHeight={108}
				style={{ height: '310px' }}
			>
				{ items &&
					items.map((configuration) => (
						<GridItem key={configuration.id}>
							<div className="drag-container">
								<Configuration
									configuration={configuration}
									onDelete={onDelete}
								/>
							</div>
						</GridItem>
					))
				}
			</GridDropZone>
		</div>
	);
};

export default Defaults;