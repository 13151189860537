
import Button from '../button';
import Icon from '../icon';
import './index.scss';

function Pager({ pageSize = 1, pageNumber = 1, totalCount = 1, shownPageCount = 5, loading, showPrev = true, showNext = true, onGoToPage }) {
	if (pageSize < 1 || pageNumber < 1 || totalCount < 1) {
		return null;
	}
	
	const minPage = 1;
	const maxPage = Math.max(Math.ceil(totalCount / pageSize), minPage);
	shownPageCount = Math.min(shownPageCount, maxPage);
	
	if (maxPage < 2) {
		return null;
	}
	
	const delta = Math.floor(shownPageCount / 2);
	const deltaAfter = shownPageCount - delta - 1;
	let deltaBefore = delta;
	if (maxPage < pageNumber + deltaAfter) {
		deltaBefore += pageNumber + deltaAfter - maxPage;
	}
	const pageStart = Math.max(pageNumber - deltaBefore, minPage);
	const pages = [];
	for (let page = pageStart; page <= shownPageCount; page++) {
		pages.push(page);
	}
	return (
		<div className="pager d-flex justify-content-end align-items-center">
			{ showPrev &&
				<Icon
					height="16"
					width="8"
					icon="prev"
					onClick={() => onGoToPage(pageNumber - 1)}
					class={(loading || pageNumber <= minPage) ? 'disabled' : ''}
				/>
			}
			{ pages.length > 0 && 
				pages.map((page, index) => (
					<Button
						key={index}
						onClick={() => onGoToPage(page)}
						text={page + ''}
						class={'t4 page' + (page === pageNumber ? ' active' : '')}
						disabled={loading || page === pageNumber}
					/>
				))
			}
			{ showNext &&
				<Icon
					height="16"
					width="8"
					icon="next"
					onClick={() => onGoToPage(pageNumber + 1)}
					class={(loading || pageNumber >= maxPage) ? 'disabled' : ''}
				/>
			}
		</div>
	);
}

export default Pager;
