import Button from 'components/core/button';
import Icon from 'components/core/icon';
import Link from 'components/core/link';
import DocumentOverlay from 'components/custom/document-overlay';
import DrawingDataView from 'components/custom/drawing-data';
import HelpOverlay from 'components/custom/help-overlay';
import LightRayData from 'components/custom/light-ray-data';
import SubElementView from 'components/custom/sub-element';
import SummaryOverlay from 'components/custom/summary-overlay';
import ConfigNavigation from 'components/navigation/config-navigation';
import TopNavigation from 'components/navigation/top-navigation';
import Delayed from 'components/system/delayed';
import SpinnerLoader from 'components/system/spinner-loader';
import { useAuth } from 'hooks/useAuth';
import { makeSelectQuestionsByPage, useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useLoading } from 'hooks/useLoading';
import { useLocalStorage } from 'hooks/useLocalStorage';
import Question from 'pages/configuration/question';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { PageTranslationKeys, Routes, ScreenSizes, SubElementViewWidths } from 'utils/constants';
import TranslationTag from 'utils/translations';
import ProjectDetails from '../question/types/project-details';
import Summary from '../question/types/summary';
import './style.scss';

function Detail({ currentQuestion }) {
	const { user } = useAuth();
	const { state, reset, canGoToNextPage, goTo, goToNextPage, complete, clearError } = useConfiguratorApi();
	const selectQuestions = useMemo(() => makeSelectQuestionsByPage(), []);
	const allQuestions = selectQuestions(state, state.currentPage);
	const [isDragging, setIsDragging] = useState(false);
	const [documentsVisible, setDocumentsVisible] = useState(false);
	const [summaryVisible, setSummaryVisible] = useState(false);
	const [helpVisible, setHelpVisible] = useState(false);
	const [customWidth, setCustomWidth] = useState(false);
	const [subElementData, setSubElementData] = useState(null);
	const [drawingData, setDrawingData] = useState(null);
	const [poleImageFile, setPoleImageFile] = useState(null);
	const { isLoading, startLoading, stopLoading } = useLoading();
	const [sidebarWidth, setSidebarWidth] = useState(0);
	const [documentHintVisible, setDocumentHintVisible] = useLocalStorage('documentHints', true);
	const { t } = useTranslation('translations');
	const history = useHistory();
	
	const getActiveSubElementData = useCallback(() => {
		let question = allQuestions.find((q) => q.active || q.identifier === currentQuestion.identifier);
		
		if (!question) {
			return null;
		}
		if (question.type !== 'subElements') {
			if (!question.showSubElementDataId) {
				return null;
			}
			return state.subElementData[question.showSubElementDataId];
		}
		
		let subElement;
		if (question.identifier === 'TabElementData') {
			subElement = question.subElements.find((sub) => sub.active);
			if (!subElement) {
				if (!question.showSubElementDataId) {
					return null;
				}
				return state.subElementData[question.showSubElementDataId];
			}
			
			question = subElement.questions.find((q) => q.type === 'subElements' && q.active);
			if (!question) {
				question = subElement.questions.find((q) => !!q.showSubElementDataId);
				if (!question) {
					return null;
				}
				return state.subElementData[question.showSubElementDataId];
			}
		}
		
		return question.active ? question : null;
	}, [allQuestions, currentQuestion, state.subElementData]);
	
	const getDrawingData = useCallback(() => {
		return currentQuestion.drawingData ? currentQuestion.drawingData : null;
	}, [currentQuestion]);
	
	const getPoleImageFile = useCallback(() => {
		return currentQuestion.poleImageFile ? currentQuestion.poleImageFile : null;
	}, [currentQuestion]);

	useEffect(() => {
		setSubElementData(getActiveSubElementData());
	}, [getActiveSubElementData]);
	
	useEffect(() => {
		setDrawingData(getDrawingData());
	}, [getDrawingData]);
	
	useEffect(() => {
		setPoleImageFile(getPoleImageFile());
	}, [getPoleImageFile]);
	
	const defaultWidth = user ? ScreenSizes[Object.keys(ScreenSizes)[user.screenSetting]] : ScreenSizes.small;
	const subElementViewWidth = SubElementViewWidths(subElementData ? subElementData : '');
	
	const setSidebarSize = useCallback((width) => {
		if (!customWidth || sidebarWidth <= width) {
			if (customWidth) {
				setCustomWidth(false);
			}
			setSidebarWidth(width);
		}
	}, [customWidth, sidebarWidth]);
	
	useEffect(() => {
		if (!subElementData && !drawingData && !poleImageFile) {
			setSidebarSize(defaultWidth);
		} else {
			setSidebarSize(subElementViewWidth + defaultWidth);
		}
	}, [defaultWidth, drawingData, poleImageFile, setSidebarSize, subElementData, subElementViewWidth]);

	useEffect(() => {
		let resizable = document.getElementById('resize-container');
		if (resizable && sidebarWidth > 0) {
			resizable.style.width = sidebarWidth + 'px';
		}
	}, [sidebarWidth]);
	
	const resize = useCallback((e) => {
		preventEventDefault(e);

		if (e.clientX <= 0) {
			return;
		}
		
		let minWidth = (subElementData || drawingData || poleImageFile) ? (defaultWidth + subElementViewWidth) : defaultWidth;
		let resizable = document.getElementById('resize-container');
		let newWidth = parseInt(resizable.offsetWidth) - parseInt(e.clientX - resizable.offsetLeft);
		if (newWidth < minWidth) {
			newWidth = minWidth;
		}
		resizable.style.width = `${newWidth}px`;
	}, [defaultWidth, drawingData, poleImageFile, subElementData, subElementViewWidth]);

	const preventEventDefault = (e) => {
		if(e.stopPropagation) e.stopPropagation();
		if(e.preventDefault) e.preventDefault();
	};

	const onDragEnd = useCallback((e) => {
		preventEventDefault(e);
		
		setCustomWidth(true);
		let resizable = document.getElementById('resize-container');
		setSidebarWidth(parseInt(resizable.offsetWidth));
		setIsDragging(false);
		document.removeEventListener('pointermove', resize);
		document.removeEventListener('pointerup', onDragEnd);
	}, [resize]);
	
	const onDragStart = useCallback((e) => {
		preventEventDefault(e);

		if (isDragging) {
			return;
		}
		document.addEventListener('pointermove', resize);
		document.addEventListener('pointerup', onDragEnd);
		setIsDragging(true);
	}, [isDragging, onDragEnd, resize]);

	if (!currentQuestion) {
		return null;
	}

	const pages = state.pages
		.filter((page) => !page.needsUser || (user && page.needsUser));

	const handleConfigNavigationClick = (e, page) => {
		e.preventDefault();
		goTo(page.key);
	};
	
	const handleLightRay = (e, page) => {
		if (state.lightRayData && state.lightRayData.displayStatus) {
			return <LightRayData state={state} />;
		} else {
			return null;
		}
	};
	
	const goToNextPageWithLoading = async () => {
		startLoading();
		if (state.nextPage === 'project') {
			await complete();
		}
		await goToNextPage();
		stopLoading();
	};
	
	return (
		<div className="detail-view d-flex">
			<div className="object-view">
				{ handleLightRay() }
			</div>
			<DocumentOverlay
				isOpen={documentsVisible}
				onClose={() => setDocumentsVisible(false)}
			/>
			<SummaryOverlay
				isOpen={summaryVisible}
				onClose={() => setSummaryVisible(false)}
			/>
			<HelpOverlay
				isOpen={helpVisible}
				onClose={() => setHelpVisible(false)}
			/>
			<div className="side-bar" id="resize-container">
				{ state.error &&
					<div className="error-bg"></div>
				}
				<div className="drag-bg">
					<Icon
						icon="resize-container"
						color="#FFFFFF"
					/>
				</div>
				<div className="drag-container" onPointerDown={onDragStart}>
					<Icon
						icon="resize-arrows"
						color="#161D1B"
						width="18"
						height="25"
					/>
				</div>
				<TopNavigation>
					<a className={(state.currentPage === 'summary' || state.currentPage === 'project') ? 'disabled' : ''} onClick={() => { setDocumentsVisible(true); setDocumentHintVisible(false); }} href="#documents">
						<span className="title">{t('Configuration_Documents')}</span>
						{ state.documentsCount > 0 &&
							<span className="count"><span>{ state.documentsCount }</span></span>
						}
						{ (documentHintVisible) &&
							<div className="hint-line">
								<div className="dot"></div>
							</div>
						}
					</a>
					<a className={(state.currentPage === 'summary' || state.currentPage === 'project') ? 'disabled' : ''} onClick={() => setSummaryVisible(true)} href="#summary">
						<span className="title">{t('Configuration_ConfigDetails')}</span>
					</a>
					<a onClick={() => setHelpVisible(true)} href="#help">
						<span className="title">{t('Configuration_Help')}</span>
					</a>
					{ (documentHintVisible) &&
						<div className="hint-overlay inverse">
							<div className="overlay">
								<div className="headline t2i">
									<TranslationTag tag="Configuration_DocumentHintHeadline"></TranslationTag>
								</div>
								<div className="text">
									<TranslationTag tag="Configuration_DocumentHintText"></TranslationTag>
								</div>
								<Link
									text="Configuration_DocumentHintLinkText"
									onClick={() => setDocumentHintVisible(false)}
								/>
								<div className="close">
									<Icon
										icon="close-documentHints"
										onClick={() => setDocumentHintVisible(false)}
										width="13"
										height="13"
									/>
								</div>
							</div>
						</div>
					}
				</TopNavigation>
				<ConfigNavigation
					disabled={state.error}
				>
					{
						pages.map((page) => (
							<NavLink
								key={page.key}
								to={page.url}
								title={t(PageTranslationKeys[page.key])}
								className={page.disabled || state.currentPage === 'summary' || state.currentPage === 'project' ? 'disabled' : null}
								onClick={(e) => handleConfigNavigationClick(e, page)}
							>
								{t(PageTranslationKeys[page.key])}
							</NavLink>
						))
					}
				</ConfigNavigation>
				<div className="questions d-flex">
					{ state.error &&
						<div className="error-bg"></div>
					}
					<SpinnerLoader show={isLoading} />
					{state.waitingForAnswer &&
						<Delayed>
							<SpinnerLoader show={true} />
						</Delayed>
					}
					<SimpleBar style={{ maxHeight: 'calc(100vh - 123px)' }} autoHide={false}>
						<div className="inner-questions">
							{ allQuestions && state.currentPage !== 'project' &&
								allQuestions.map((question) => (
									<Question
										key={question.identifier}
										currentQuestion={question}
										active={question.active || question.identifier === currentQuestion.identifier}
									/>
								))
							}
							{ state.currentPage === 'project' &&
								<ProjectDetails
									state={state}
								/>
							}
							{ state.currentPage === 'summary' &&
								<Summary
									state={state}
								/>
							}
							{ (canGoToNextPage() && state.nextPage !== 'project') &&
								<Button
									text="Configuration_SaveAndContinue"
									class="btn-primary next-page"
									onClick={() => goToNextPageWithLoading()}
								/>
							}
							{ state.nextPage === 'project' &&
								<>
									<Button
										text="Configuration_CreateProjectButton"
										class="btn-primary next-page"
										onClick={() => goToNextPageWithLoading()}
									/>
									<Button
										text="Configuration_ConfigureAdditionalProduct"
										class="btn-secondary"
										type="button"
										onClick={() => reset()}
									/>
								</>
							}
						</div>
					</SimpleBar>
					{ state.error &&
						<div className="error-container">
							<div className="headline t3"><TranslationTag tag="Configuration_ErrorHeadline" /></div>
							<div className="text"><TranslationTag tag="Configuration_ErrorText" /></div>
							<Button
								text="Configuration_ErrorButton"
								class="btn-primary"
								type="button"
								onClick={() => {
									clearError();
									history.push(Routes.login.index);
								}}
							/>
						</div>
					}
					{ (subElementData && subElementData.familyName !== 'EL') &&
						<SubElementView
							data={subElementData}
						/>
					}
					{ drawingData &&
						<DrawingDataView
							data={drawingData}
							subElementData={subElementData}
						/>
					}
					{ poleImageFile &&
						<DrawingDataView
							poleImage={poleImageFile}
						/>
					}
				</div>
			</div>
			
		</div>
	);
}

export default Detail;