import './index.scss';

function Tabs(props) {
	return (
		<div className={`tabs${props.class ? ' ' + props.class : ''}`}>
			{ props.children }
		</div>
	);
}

Tabs.defaultProps = {
	class: '1',
};

export default Tabs;
