import Overlay from 'components/core/overlay';
import Form from 'components/forms/form';
import Button from 'components/core/button';
import { Routes, Errors } from 'utils/constants';
import Input from 'components/forms/input';
import { useConfiguratorApi } from 'hooks/useConfiguratorApi';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import * as Yup from 'yup';
import Link from 'components/core/link';
import { useTranslation } from 'react-i18next';
import Separator from 'components/forms/separator';
import Product from 'components/core/product';
import Icon from 'components/core/icon';
import './style.scss';

function Share() {
	let formikApi = null;
	const { t } = useTranslation('translations');
	const { state, share, getOrderInfo } = useConfiguratorApi();
	const history = useHistory();
	const { user } = useAuth();
	const [selectedConfiguration, setSelectedConfiguration] = useState();
	const [loadingInfos, setLoadingInfos] = useState(false);
	const [infos, setOrderInfos] = useState(null);
	const [recipients, setRecipients] = useState([1]);
	const [currentCount, setCurrentCount] = useState(1);
	
	const initialValues = () => {
		let values = {};
		for (let i = 1; i <= 70; i++) {
			values['recipient' + i] = '';
		}
		return values;
	};
	
	const validationSchema = Yup.object({
		recipient1: Yup.string().email(t(Errors.EMail)).required(t(Errors.Required)),
	});
	
	const handleSubmit = async (values) => {
		let tempRecipients = [];
		for (let i = 1; i <= 70; i++) {
			if (values['recipient' + i] !== '') {
				tempRecipients.push(values['recipient' + i]);
			}
		}
		await share(selectedConfiguration ? selectedConfiguration : infos.configurations[0].pcpSaveId, tempRecipients);
		history.push(Routes.configuration.share.success);
	};
	
	const changeConfigurations = (pcpSaveId) => {
		setSelectedConfiguration(pcpSaveId);
	};
	
	const addRecipient = () => {
		let nextCount = currentCount + 1;
		setCurrentCount(nextCount);
		setRecipients([...recipients, nextCount]);
	};
	
	const removeRecipient = (count) => {
		let newRecipients = [...recipients];
		if (newRecipients.indexOf(count) !== -1) {
			newRecipients.splice(newRecipients.indexOf(count), 1);
		}
		formikApi.setFieldValue('recipient' + count, '');
		setRecipients(newRecipients);
	};
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	useEffect(() => {
		const load = async () => {
			setOrderInfos(await getOrderInfo(state.projectId));
			setLoadingInfos(false);
		};
		if (infos === null && loadingInfos === false) {
			setLoadingInfos(true);
			load();
		}
	}, [infos, getOrderInfo, loadingInfos, setOrderInfos, setLoadingInfos, state.projectId]);
	
	if (!state.projectId || !user) {
		history.push(Routes.configuration.index);
	}
	
	if (!infos) {
		return null;
	}
	
	return (
		<div className="share">
			<Overlay
				headline="Share_Headline"
				text="Share_Text"
				backLink={Routes.configuration.summary}
			>
				<Form
					initialValues={initialValues()}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					registerAPI={(api) => { formikApi = api; }}
				>
					<Separator
						text="Share_Headline1"
					/>
					<div className="products">
						{ infos.configurations.length > 0 &&
							infos.configurations.map((configuration) => (
								<Product
									key={configuration.configurationId}
									name={configuration.family}
									summary={configuration.summary}
									image={configuration.image}
									configurationId={configuration.configurationId}
									pcpSaveId={configuration.pcpSaveId}
									onClick={(configId, pcpSaveId) => changeConfigurations(pcpSaveId)}
									active={selectedConfiguration === configuration.pcpSaveId}
								/>
							))
						}
					</div>
					<Separator
						text="Share_Headline2"
					/>
					{ recipients.map((recipient) => (
						<div className="recipient" key={recipient}>
							<Input
								id={'recipient' + recipient}
								key={'recipient' + recipient}
								name={'recipient' + recipient}
								text="Form_EmailRecipient"
							/>
							{ recipient > 1 &&
								<div className="delete" onClick={() => removeRecipient(recipient)}>
									<Icon
										icon="delete"
										width="17"
										height="20"
										color="#161D1B"
									/>
								</div>
							}
						</div>
					))}
					<Link
						text="Share_AddRecipient"
						onClick={() => addRecipient()}
					/>
					<Button
						class="btn-primary"
						type="submit"
						text="Share_ShareConfiguration"
					/>
					<Button
						class="btn-secondary"
						text="Share_Abort"
						onClick={() => history.push(Routes.configuration.summary)}
					/>
				</Form>
			</Overlay>
		</div>
	);
}

export default Share;