import React, { useState, useRef } from 'react';
import Icon from 'components/core/icon';
import { useTranslation } from 'react-i18next';
import './index.scss';

function Tags (props) {
	const [tags, setTags] = useState(props.defaultTags ? props.defaultTags.split(',') : []);
	const [newTag, setNewTag] = useState('');
	const tagRef = useRef();
	const Tag = props => <div className="tag d-flex align-content-center" {...props} />;
	const { t } = useTranslation('translations');
	function handleChange (e) {
		setNewTag(e.target.value);
	}
	
	function handleKeyDown (e) {
		if (e.keyCode === 13 && e.target.value !== '')  {
			let newTagVariable = newTag.trim().replace(',', '||');
			if (tags.indexOf(newTagVariable) === -1) {
				setTags([...tags, newTagVariable]);
				setNewTag('');
			}
			e.target.value = '';
			let newArray = [...tags, newTagVariable];
			props.onChange(newArray.join());
		}
	}
	
	function handleRemoveTag (selectedTag) {
		setTags(tags.filter(tag => tag !== selectedTag));
		let newArray = tags.filter(tag => tag !== selectedTag);
		props.onChange(newArray.join());
	}
	
	function handleAddTag (selectedTag) {
		if (tags.indexOf(selectedTag) === -1) {
			setTags([...tags, selectedTag]);
		}
		tagRef.current.value = '';
		setNewTag('');
		let newArray = [...tags, selectedTag];
		props.onChange(newArray.join());
	}
	
	const suggestions = () => {
		return props.suggestions.filter(element => element.toLowerCase().includes(newTag.toLowerCase())).sort();
	};
	
	return (
		<div className="tags" onClick={() => tagRef.current.focus()}>
			<div className="inner-tags d-flex flex-wrap align-items-start">
				{ tags &&
					tags.map((tag, index) => (
						<Tag key={index}>
							{tag.replace('||', ',')}
							<div onClick={() => handleRemoveTag(tag.replace('||', ','))}>
								<Icon
									icon="close2"
									color="#161d1b"
									width="9"
									height="9"
								/>
							</div>
						</Tag>
					))
				}
				<input type="text" ref={tagRef}
					onChange={handleChange}
					onKeyDown={handleKeyDown} />
				{ (suggestions().length > 0 && newTag) &&
					<div className="suggestions">
						<div className="text">{ t('Configuration_TagSuggestion') }</div>
						<div className="items d-flex flex-wrap align-items-center">
							{ suggestions().slice(0, 10).map((tag, index) => (
								<Tag key={index} onClick={() => handleAddTag(tag.replace('||', ','))}>
									{tag.replace('||', ',')}
									<div>
										<Icon
											icon="close2"
											color="#161d1b"
											width="9"
											height="9"
										/>
									</div>
								</Tag>
							))}
							{ suggestions().length > 10 && (
								<div className="more">...</div>
							)}
						</div>
					</div>
				}
			</div>
		</div>
	);
}

Tags.defaultProps = {
	onChange: () => void(0),
	suggestions: [],
	defaultTags: '',
};

export default Tags;